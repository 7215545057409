import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.inProduction = (fixed) => ({
      variant: 'layout.padded',
      background: fixed ? colors.black : colors.cream,
      alignItems: 'center',
      // boxShadow: 'bottom',
      color: fixed ? colors.yellow : 'text', // sets the color of all nav items and icons
      flexDirection: 'column',
      height: 'auto',
      position: 'sticky',
      width: '100%',
      top: 0,
      zIndex: 30,
      // transition: 'background .3s ease',
    });

    // on customizer mode, we push the header under the customizer toolbar
    this.inCustomizer = (isFixed) => ({
      ...this.inProduction(isFixed),
      top: 'var(--backpack-toolbar-height)',
    });
  })()
);
