import { section } from '@settings/common/section';
import { position } from '@settings/common/position';

export const Schema = () => {
  return {
    label: 'Image',
    key: 'image',
    fields: [
      {
        name: 'alt',
        component: 'text',
        description: 'Brief description of image',
        label: 'Alt',
        defaultValue: 'Image',
      },
      {
        label: 'Desktop Settings',
        name: 'desktop',
        component: 'group',
        fields: [
          {
            name: 'src',
            label: 'Image',
            component: 'image',
          },
          {
            name: 'position',
            component: 'select',
            label: 'Image position',
            options: position.object,
          },
          {
            name: 'ratio',
            label: 'Aspect Ratio',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              {
                value: '1',
                label: '1:1',
              },
              {
                value: '1.25',
                label: '5:4',
              },
              {
                value: '1.5',
                label: '3:2',
              },
              {
                value: '1.75',
                label: '7:4',
              },
              {
                value: '2',
                label: '2:1',
              },
            ],
          },
          {
            name: 'maxHeight',
            component: 'number',
            label: 'Max Pixel Height (optional)',
          },
        ],
        defaultValue: {
          src: '//cdn.shopify.com/s/files/1/1418/5086/files/03122021_CHELSEA_KYLE_WAND_BEAR_3249_R1_1_5.png?v=1645795346',
          position: 'center center',
          ratio: '2',
          minHeight: '',
        },
      },
      {
        label: 'Mobile Settings',
        name: 'mobile',
        component: 'group',
        fields: [
          {
            name: 'src',
            label: 'Image',
            component: 'image',
          },
          {
            name: 'position',
            component: 'select',
            label: 'Image position',
            options: position.object,
          },
          {
            name: 'ratio',
            label: 'Aspect Ratio',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              {
                value: '0.75',
                label: '3:4',
              },
              {
                value: '1',
                label: '1:1',
              },
              {
                value: '1.25',
                label: '5:4',
              },
              {
                value: '1.5',
                label: '3:2',
              },
            ],
          },
          {
            name: 'minHeight',
            component: 'number',
            label: 'Min pixel height (optional)',
          },
        ],
        defaultValue: {
          src: '//cdn.shopify.com/s/files/1/1418/5086/files/03122021_CHELSEA_KYLE_WAND_BEAR_3249_R1_1_5.png?v=1645795346',
          position: 'center center',
          ratio: '1.75',
          maxHeight: '',
        },
      },
      section,
    ],
  };
};
