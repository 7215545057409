import { color } from '@settings/common/color';

export function Schema({ article, blog, collection }) {
  if (article || blog || collection) return null;

  return {
    label: 'Two Column',
    key: 'two-column',
    fields: [
      {
        name: 'columns',
        label: 'Columns',
        description: 'Two columns required',
        component: 'group-list',
        itemProps: {
          label: '{{item.text}}',
        },
        validate(list) {
          if (list.length !== 2) {
            window.__bpCMS.alerts.error('Two columns required.');
          }
        },
        fields: [
          {
            name: 'text',
            label: 'Text',
            component: 'text',
          },
          {
            name: 'subtext',
            label: 'Sub-text',
            component: 'text',
          },
          {
            name: 'color',
            label: 'Text color',
            component: 'select',
            options: color.options,
            defaultValue: color.value.yellow,
          },
          {
            name: 'bgColor',
            label: 'Background color',
            component: 'select',
            options: color.options,
            defaultValue: color.value.green,
          },
          {
            label: 'Image Alt',
            name: 'alt',
            component: 'text',
            description: 'Brief description of image',
            defaultValue: 'Image description',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
            defaultValue: {
              src: 'https://images.unsplash.com/photo-1518050227004-c4cb7104d79a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2970&q=80',
            },
          },
        ],
        defaultValue: [
          {
            text: 'TAP IT',
            subtext:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus tristique non elementum facilisi ante.',
            color: 'green',
            bgColor: 'yellow',
            image: {
              src: 'https://images.unsplash.com/photo-1518050227004-c4cb7104d79a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2970&q=80',
            },
          },
          {
            text: 'ENJOY IT',
            subtext:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus tristique non elementum facilisi ante.',
            color: 'yellow',
            bgColor: 'green',
            image: {
              src: 'https://images.unsplash.com/photo-1518050227004-c4cb7104d79a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2970&q=80',
            },
          },
        ],
      },
      {
        name: 'cta',
        label: 'Call To Action',
        component: 'link',
        defaultValue: {
          text: 'SHOP NOW',
          url: 'https://wanderingbearcoffee.com/collections',
        },
      },
    ],
  };
}
