import { useState, useEffect } from 'react';
import { Flex, Heading, Badge, Text, Spinner } from 'theme-ui';
import { useProductByHandle } from '@backpackjs/storefront';

import { colors } from '@theme/theme.colors';
import { themed } from './Content.theme';
import { Swatches } from './Swatches';
import { Variants } from './Variants/Variants';

import { AddItemToCart } from './AddItemToCart';
import { Image } from './Image';
import { Steps } from './Steps';
import { Reviews } from './Reviews/Reviews';

export const Content = themed(({ theme, content, type }) => {
  const {
    reviews: { title, starRating },
    secondary: { stepOne, stepTwo, boxes, badge, showBadge },
    variants,
    ctaSettings,
    priceStrikethrough,
  } = content;
  if (variants.variants.length < 1) return null;

  const secondary = type === 'secondary';
  const [allSwatches, setAllSwatches] = useState([]);
  const [selectedSwatch, setSelectedSwatch] = useState(variants?.variants[0]);
  const [subscribePrice, setSubscribePrice] = useState(false);
  const [allVariants, setAllVariants] = useState([]);

  const { product } = useProductByHandle({
    handle: selectedSwatch?.product.handle,
  });

  const [selectedVariant, setSelectedVariant] = useState(product?.variants[0]);

  const handleSwatchSelect = (color, swatchTitle) => {
    const swatchSelected = allSwatches.map((variant) =>
      (variant.color === color && variant.title === swatchTitle) ||
      variant.id === swatchTitle
        ? {
            ...variant,
            isSelected: !variant.isSelected,
          }
        : {
            ...variant,
            isSelected: false,
          }
    );
    const currentVariant = allSwatches.filter(
      ({ title, id }) => title === swatchTitle || id === swatchTitle
    )[0];

    setAllSwatches(swatchSelected);
    setSelectedSwatch(currentVariant);
    setSubscribePrice(currentVariant?.subscription);
  };

  useEffect(() => {
    setAllSwatches(
      variants.variants.map((swatches, index) =>
        index === 0
          ? { ...swatches, isSelected: true }
          : { ...swatches, isSelected: false }
      )
    );

    setSelectedSwatch(variants.variants[0]);
    setSubscribePrice(variants.variants[0]?.subscription);
  }, [variants.variants, secondary]);

  useEffect(() => {
    setSelectedVariant(
      product?.variants[
        secondary ? boxes.findIndex((variant) => variant.default) : 0
      ]
    );
  }, [product, secondary]);

  useEffect(() => {
    setAllVariants(
      boxes.map((variant) => {
        return variant.default
          ? { ...variant, isSelected: true }
          : { ...variant, isSelected: false };
      })
    );
  }, [variants]);

  const handleVariantSelect = (currentVariant) => {
    const variantSelected = allVariants.map((variant, index) =>
      index === currentVariant
        ? {
            ...variant,
            isSelected: true,
          }
        : {
            ...variant,
            isSelected: false,
          }
    );

    setAllVariants(variantSelected);

    const productVariant = product.variants.filter(
      (v) => v.position === currentVariant + 1
    )[0];

    setSelectedVariant(productVariant);
  };
  const image = !product?.featuredImage ? (
    <Spinner
      sx={{
        width: '60px',
        m: 'auto',
      }}
    />
  ) : (
    <Image image={product?.featuredImage} />
  );
  return (
    <Flex data-comp={Content.displayName} sx={theme.container}>
      <Flex
        sx={{
          flexDirection: 'column',
          width: ['100%', '50%'],
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {image}
      </Flex>

      <Flex
        sx={{ ...theme.rightBlock, width: ['100%', secondary ? '60%' : '40%'] }}
      >
        {secondary && showBadge && (
          <Badge
            sx={{
              backgroundColor: colors.red,
              color: colors.white,
              border: 'none',
              borderRadius: 0,
              fontFamily: 'gtPressura',
              textTransform: 'uppercase',
              px: 8,
              py: 3,
              transform: [
                'translate(110%, 80%) rotate(2deg)',
                'translate(200%, 150%) rotate(2deg)',
              ],
            }}
          >
            {badge}
          </Badge>
        )}
        <Flex
          sx={{
            my: 8,
            flexDirection: secondary ? 'column' : 'column-reverse',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Heading variant="text.h2" sx={{ mt: 8, textAlign: 'center' }}>
            {product?.title.split('-')[secondary ? 1 : 0]}
          </Heading>
          {!selectedVariant?.product?.tags?.includes('inveterate-product') && (
            <Reviews starRating={starRating} type={secondary} title={title} />
          )}
        </Flex>

        {secondary && <Steps heading={stepOne} superHeading="step 1:" />}
        {!selectedVariant?.product?.tags?.includes('inveterate-product') && (
          <Flex
            sx={{
              width: '100%',
              overflow: 'hidden',
              flexDirection: 'column',
              mb: secondary ? 8 : 0,
            }}
          >
            <Swatches
              swatches={allSwatches.filter(({ limited }) => !limited)}
              title=" Flavors Available"
              handleSelect={handleSwatchSelect}
              type={secondary}
            />
            <Swatches
              swatches={allSwatches.filter(({ limited }) => limited)}
              title="Limited Edition"
              handleSelect={handleSwatchSelect}
              type={secondary}
            />
          </Flex>
        )}
        {!secondary && (
          <Flex
            sx={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              my: [8],
            }}
          >
            {priceStrikethrough && (
              <Text
                variant="text.base"
                sx={{
                  textDecoration: 'line-through',
                  pr: 10,
                  fontSize: [8, 9],
                }}
              >
                ${priceStrikethrough}
              </Text>
            )}
            <Text variant="text.h3">
              $
              {subscribePrice &&
              selectedVariant?.subscriptions?.discount_variant_price
                ? selectedVariant?.subscriptions?.discount_variant_price
                : selectedVariant?.price}
            </Text>
          </Flex>
        )}

        {secondary && <Steps heading={stepTwo} superHeading="step 2:" />}

        {secondary && (
          <Variants
            variants={allVariants}
            productsVariants={product?.variants}
            handleSelect={handleVariantSelect}
            subscribePrice={subscribePrice}
          />
        )}

        <AddItemToCart
          selectedVariant={selectedVariant}
          type={!secondary}
          subscribePrice={subscribePrice}
          ctaSettings={ctaSettings}
        />
      </Flex>
    </Flex>
  );
});

Content.displayName = 'Content';
