import { useCallback } from 'react';
import { Flex, Text, Box } from 'theme-ui';

import { useModal } from '@hooks';
import { generateId } from '@utils';
import { colors } from '@theme/theme.colors';

import { themed } from './Variants.theme';
import { CalculatedPrice } from './CalculatedPrice';
import { Dimensions } from './Dimensions';

export const Variants = themed(
  ({
    theme,
    heading,
    variants,
    handleSelect,
    productsVariants = [],
    subscribePrice,
    selectedPlanDiscount,
    selectedVariant,
    dimensionsLinkText,
    dimensions,
    boxes
  }) => {
    const [{ modal }, { openModal, closeModal }] = useModal();
    const toggleDimensionsModal = useCallback(() => {
      if (modal) {
        closeModal();
        return;
      }

      openModal(<Dimensions dimensions={dimensions} boxes={boxes} />);
    }, [modal]);

    const totalBoxes = selectedVariant?.position;
    const firstBoxPrice = variants[0]?.price;
    return (
      <Flex data-comp={Variants.displayName} sx={theme.container}>
        <Flex
          sx={{ flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }}
        >
          <Flex sx={{ alignItems: 'center' }}>
            <Text sx={{ fontSize: '18px', textTransform: 'uppercase', fontWeight: 700,  mr: 3 }}>{heading}</Text>
            <Text sx={{ fontSize: '13px', fontWeight: 400, fontStyle: 'italic', letterSpacing: '0.39px', color: colors.grayTwo }}>{parseInt(selectedVariant?.title)} {parseInt(selectedVariant?.title) > 1 ? 'Boxes':'Box'} = {16 * parseInt(selectedVariant.title)} Glasses ({96  * parseInt(selectedVariant.title)}oz)</Text>
          </Flex>
          <Text
            sx={{
              color: colors.grayTwo,
              cursor: 'pointer',
              textDecoration: 'underline',
              fontFamily: 'gtPressuraBold',
              fontSize: '14px',
              fontWeight: 700,
              textTransform: 'uppercase'
            }}
            onClick={toggleDimensionsModal}
          >
            {dimensionsLinkText}
          </Text>
        </Flex>
        <Flex sx={theme.wrapper}>
          {variants?.length > 0 &&
            variants
              .slice(0, 3)
              .map(
                (
                  {
                    title,
                    subtitle,
                    totalGlasses,
                    isSelected,
                    price
                  },
                  index
                ) => (
                  <Box
                    key={index}
                    sx={{
                      ...theme.box,
                      bg: isSelected ? colors.white : 'transparent',
                      border: `${isSelected ? '2px' : '1px'} solid ${isSelected ? colors.black : colors.grayTwo}`,
                    }}
                    onClick={() => handleSelect(index)}
                  >
                    <Flex
                      sx={{
                        justifyContent: 'center',
                        width: '100%',
                        flexDirection: 'column',
                        alignItems: ['start', 'center'],
                      }}
                    >
                      <Text sx={theme.title}>{parseInt(title)} {parseInt(title) > 1 ? 'Boxes':'Box'}</Text>
                      <Text sx={{ fontSize: '14px', letterSpacing: '0.42px', lineHeight: '134.5%', mb: '5px' }}>
                        ${Number(subscribePrice ? price - selectedPlanDiscount : price)}/box
                      </Text>
                      {firstBoxPrice > (subscribePrice ? (price - selectedPlanDiscount) : price) &&
                        <Text sx={{ fontSize: '13px', lineHeight: '120%', color: colors.secondRed }}>
                          Save {Math.round((firstBoxPrice - (subscribePrice ? (price - selectedPlanDiscount) : price)) / firstBoxPrice * 100)}%
                        </Text>
                      }
                      {totalGlasses ? (
                        <CalculatedPrice
                          selectedVariant={productsVariants[index]}
                          totalGlasses={totalGlasses}
                          subscribePrice={subscribePrice}
                          discount={selectedPlanDiscount}
                        />
                      ) : (
                        <Text sx={theme.subtitle}>{subtitle}</Text>
                      )}
                    </Flex>
                  </Box>
                )
              )}
        </Flex>
      </Flex>
    );
  }
);

Variants.displayName = 'Variants';
