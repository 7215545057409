import { Container } from 'theme-ui';

import { Section } from '@snippets';
import { withInView } from '@utils';

import { Header } from './Header';

import { Schema } from './FeaturedIcons.schema';
import { themed } from './FeaturedIcons.theme';
import { Content } from './Content';

export const FeaturedIcons = withInView(
  themed(({ theme, cms }) => {
    const { section, text, content } = cms;

    return (
      <Section section={section}>
        <Container data-comp={FeaturedIcons.displayName}>
          <Header header={text} />
          <Content content={content} />
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

FeaturedIcons.displayName = 'FeaturedIcons';
FeaturedIcons.Schema = Schema;
