import { Container } from 'theme-ui';
import { useInView } from 'react-intersection-observer';

import { themed } from './Footer.theme';
import { Legal } from './Legal';

export const Footer = themed(({ theme }) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: '400px',
  });

  return (
    <Container
      data-comp={Footer.displayName}
      as="footer"
      ref={ref}
      sx={theme.wrapper}
    >
      {inView && (
        <Container sx={theme.content}>
          <Legal />
        </Container>
      )}
    </Container>
  );
});

Footer.displayName = 'Footer';
