import { Box, Flex, Heading, Paragraph, Image } from 'theme-ui';

import { StarsIcon, Svg } from '@snippets';

import { themed } from './Content.theme';

export const Content = themed(({ theme, review }) => {
  const { starRating, heading, quote, name, verifiedPurchaser } = review;

  return (
    <Flex
      data-comp={Content.displayName}
      variant="flex.column.start"
      as="blockquote"
      sx={{
        pb: 13,
        textAlign: 'left',
        bg: 'cream',
        p: 10,
        border: '3px solid #1a1919',
      }}
    >
      <Flex variant="flex.row.start">
        {[...new Array(5)].map((_, index) => (
          <StarsIcon
            averageScore={starRating || 4}
            emptyColor="black"
            fullColor="black"
            hoveredIndex={0}
            index={index}
            key={4 + index}
          />
        ))}
      </Flex>

      {heading && (
        <Heading
          as="h4"
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'bold',
            color: 'black',
            fontSize: 8,
            mt: 10,
            mb: 7,
            fontFamily: 'gtPressuraBold',
          }}
        >
          {heading}
        </Heading>
      )}

      {quote && (
        <Paragraph
          sx={{
            color: 'black',
            fontSize: 4,
            mb: 7,
          }}
        >
          {quote}
        </Paragraph>
      )}

      <Box
        sx={{
          mt: 'auto',
        }}
      >
        {name && (
          <Box
            as="figcaption"
            sx={{
              fontSize: 6,
              color: 'black',
              fontWeight: 'bold',
            }}
          >
            {name}
          </Box>
        )}

        {verifiedPurchaser && (
          <Svg
            src="/svgs/verified-purchase-two.svg#verified-purchase-two"
            viewBox="0 0 240 24"
            alt="Verified Purchase"
            sx={{
              mt: 3,
              width: '240px',
            }}
          />
        )}
      </Box>
    </Flex>
  );
});

Content.displayName = 'Content';
