import styles from './CustomizerModeScreen.module.css';

function inIframe() {
  // Checking if webpage is embedded
  if (typeof window !== 'undefined') {
    if (window.location !== window.parent.location) {
      // The page is in an iFrames
      return true;
    }
    // The page is not in an iFrame
    return false;
  }

  return false;
}

export function CustomizerModeScreen({ isPreview }) {
  if (typeof window !== 'undefined' && isPreview && !inIframe()) {
    return (
      <div className={styles.screenContainer}>
        <svg
          width="28"
          height="24"
          viewBox="0 0 28 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.iconSvg}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1965 2.86179C12.7248 3.4544 12.1903 4.37239 11.4038 5.73081L4.59163 17.4974C3.80237 18.8606 3.27034 19.7849 2.99022 20.4915C2.71339 21.1898 2.79567 21.401 2.85269 21.4999C2.9097 21.5988 3.05126 21.7759 3.79431 21.8862C4.5462 21.9978 5.61266 22.0005 7.18791 22.0005H20.8123C22.3876 22.0005 23.454 21.9978 24.2059 21.8862C24.949 21.7759 25.0905 21.5988 25.1475 21.4999C25.2046 21.401 25.2868 21.1898 25.01 20.4915C24.7299 19.7849 24.1979 18.8606 23.4086 17.4974L16.5964 5.73081C15.8099 4.37239 15.2754 3.4544 14.8038 2.86179C14.338 2.27646 14.1144 2.24219 14.0001 2.24219C13.8858 2.24219 13.6623 2.27646 13.1965 2.86179ZM11.6315 1.61638C12.2235 0.872518 12.9613 0.242188 14.0001 0.242188C15.0389 0.242188 15.7767 0.872517 16.3687 1.61638C16.944 2.33923 17.5515 3.38876 18.2853 4.65626L18.3272 4.72873L25.1395 16.4953L25.1814 16.5677C25.918 17.84 26.5278 18.8931 26.8692 19.7545C27.2205 20.6404 27.4003 21.5968 26.8802 22.4989C26.3601 23.401 25.4423 23.7245 24.4996 23.8645C23.5831 24.0006 22.3662 24.0005 20.8962 24.0005H20.8123H7.18791H7.10408C5.634 24.0005 4.41712 24.0006 3.50062 23.8645C2.55792 23.7245 1.64017 23.401 1.12004 22.4989C0.599905 21.5968 0.779772 20.6404 1.13098 19.7545C1.47244 18.8931 2.08217 17.84 2.81878 16.5678L2.81879 16.5678L2.86077 16.4953L9.67298 4.72874L9.71494 4.65626C10.4487 3.38877 11.0563 2.33923 11.6315 1.61638ZM14.0001 8.25046C14.5524 8.25046 15.0001 8.69817 15.0001 9.25046V14.2505C15.0001 14.8027 14.5524 15.2505 14.0001 15.2505C13.4478 15.2505 13.0001 14.8027 13.0001 14.2505V9.25046C13.0001 8.69817 13.4478 8.25046 14.0001 8.25046ZM14.0001 19.2505C14.6905 19.2505 15.2501 18.6908 15.2501 18.0005C15.2501 17.3101 14.6905 16.7505 14.0001 16.7505C13.3098 16.7505 12.7501 17.3101 12.7501 18.0005C12.7501 18.6908 13.3098 19.2505 14.0001 19.2505Z"
            fill="#B91C1C"
          />
        </svg>

        <p className={styles.header}>
          You must be in the Customizer Shell for Preview mode
        </p>

        <p className={styles.copy}>
          Your Storefront is in Preview mode. Storefronts in preview mode must
          be used in the Pack Customizer.
        </p>

        <div className={styles.btnContainer}>
          <a className={styles.btn} href={process.env.PACK_API_URL}>
            Go to Pack
          </a>

          <a className={styles.btnOutlined} href="/api/exitEdit">
            Exit Preview
          </a>
        </div>
      </div>
    );
  }

  return null;
}
