import { Box, Heading, Flex } from 'theme-ui';

import { Markdown, Link, Picture } from '@snippets';
import { convertAlignToFlex } from '@utils';

import { themed } from './Tile.theme';

export const Tile = themed(({ theme, item, tile = {}, ...props }) => {
  const { display, imageRatio, textAlign, ctaStyle } = tile;

  return (
    <Box data-comp={Tile.displayName} {...props}>
      <Link
        href={item.cta?.url}
        sx={item.cta?.url ? theme.imageLink : theme.image}
      >
        <Picture
          alt={item.alt}
          images={[
            {
              src: item.image?.src,
              width: 450,
              ratio: imageRatio === 'square' ? 1 : 0.75,
            },
          ]}
          sx={{ ...(tile?.enableIconDisplay ? theme.iconImage : '') }}
        />
      </Link>

      <Flex
        sx={{
          ...theme.content,
          alignItems: convertAlignToFlex(textAlign),
          textAlign,
          py: display === 'stacked' ? [10, 0] : 0,
        }}
      >
        <Heading as="h3" sx={theme.heading}>
          {item.heading}
        </Heading>

        <Markdown
          text={item.description}
          textSx={{
            ...theme.body,
            textAlign,
          }}
        />

        {item.cta?.text && (
          <Link
            href={item.cta.url}
            variant={`buttons.${ctaStyle || 'underlineLink'}`}
            sx={theme.button}
          >
            {item.cta.text}
          </Link>
        )}
      </Flex>
    </Box>
  );
});

Tile.displayName = 'Tile';
