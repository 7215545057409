import { Svg, Link } from '@snippets';
import { useMenuSidebar } from '../MenuSidebar';

import { themed } from './Menu.theme';

export const Logo = themed(({ theme, isFixed }) => {
  const [{ menuSidebar }] = useMenuSidebar();

  const isSelected = menuSidebar || isFixed;

  return (
    <Link href="/">
      <Svg
        role="presentation"
        viewBox="0 0  174 140"
        src="/svgs/logo.svg#logo"
        alt="Storefront logo"
        sx={theme.logo(isSelected)}
      />
    </Link>
  );
});
