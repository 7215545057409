import { Heading, Flex } from 'theme-ui';

import { Markdown, Link } from '@snippets';
import { themed } from './Content.theme';

export const Content = themed(({ theme, features }) => {
  return (
    <Flex data-comp={Content.displayName} sx={theme.wrapper}>
      <Flex as="ul" sx={theme.list}>
        {features &&
          features.length > 0 &&
          features.map((feature, index) => (
            <Flex as="li" key={index} sx={theme.listElement}>
              <Heading sx={theme.title}>{feature.title}</Heading>
              <Markdown
                text={feature.content}
                sx={{
                  py: 0,
                  m: 0,
                  fontSize: ['1.5rem', '27.5px'],
                  fontWeight: 500,
                }}
                textSx={{
                  fontFamily: 'gtPressura',
                  m: 0,
                }}
              />
            </Flex>
          ))}
      </Flex>
    </Flex>
  );
});

Content.displayName = 'Content';
