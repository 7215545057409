export const fontSizes = [
  /*
    DON'T ADD NEW fontSizes AS IT WILL MESS UP THE SCALE ORDER
    If your value is not here, either choose the closes or just add it inline i.e "40px"
  */
  '11px', // 0
  '12px', // 1
  '14px', // 2
  '15px', // 3
  '16px', // 4 --- base/body ----
  '17px', // 5
  '18px', // 6
  '20px', // 7
  '24px', // 8
  '30px', // 9
  '36px', // 10
  '40px', // 11
  '48px', // 12
  '56px', // 13
  /* DON'T ADD NEW fontSizes AS IT WILL MESS UP THE SCALE ORDER */
];
