import { useMemo, Children, cloneElement } from 'react';
import { Box } from 'theme-ui';

import { themed } from './Footer.theme';

export const Footer = themed(
  ({ theme, setHeight, open, children, inputRef, ...props }) => {
    const childrenWithRefs = useMemo(
      () =>
        Children.map(children, (child) =>
          cloneElement(child, {
            ref: (node) => {
              if (node) {
                const cartTotalsHeight = node?.getBoundingClientRect()?.height;
                setHeight(cartTotalsHeight);
              }
            },
          })
        ),
      [Children.count(children), open]
    );

    return (
      <Box
        data-comp={Footer.displayName}
        {...props}
        ref={inputRef}
        sx={theme.footer}
      >
        {childrenWithRefs}
      </Box>
    );
  }
);

Footer.displayName = 'Footer';
