import { color } from './color';
import { layout } from './layout';
import { margin, padding } from './space';

const fields = [
  {
    name: 'bgColor',
    label: 'Background color',
    component: 'select',
    options: color.options,
    defaultValue: color.value.background,
  },
  {
    name: 'color',
    label: 'Text color',
    component: 'select',
    options: color.options,
    defaultValue: color.value.text,
  },
];

const mobileFields = [
  {
    label: 'Container Width (mobile)',
    component: 'select',
    name: 'container',
    options: layout.options,
  },
  {
    name: 'mt',
    label: 'Margin Top (mobile)',
    component: 'select',
    options: margin.options,
  },
  {
    name: 'py',
    label: 'Vertical Padding (mobile)',
    component: 'select',
    options: padding.options,
  },
];

const mobileDefaultValue = {
  container: layout.value.container,
  mt: 'none',
  py: 'none',
};

const desktopFields = [
  {
    label: 'Container Width (desktop)',
    component: 'select',
    name: 'container',
    options: layout.options,
  },
  {
    name: 'mt',
    label: 'Margin Top (desktop)',
    component: 'select',
    options: margin.options,
  },
  {
    name: 'py',
    label: 'Vertical Padding (desktop)',
    component: 'select',
    options: padding.options,
  },
];

const desktopDefaultValue = {
  container: layout.value.container,
  mt: 'none',
  py: 'none',
};

const mobile = {
  label: 'Mobile settings',
  name: 'mobile',
  component: 'group',
  fields: mobileFields,
  defaultValue: mobileDefaultValue,
};

const desktop = {
  label: 'Desktop settings',
  name: 'desktop',
  component: 'group',
  fields: desktopFields,
  defaultValue: desktopDefaultValue,
};

const mobileWithHeight = {
  label: 'Mobile settings',
  name: 'mobile',
  component: 'group',
  fields: [
    ...mobileFields,
    {
      name: 'minHeight',
      component: 'number',
      label: 'Min Section Pixel Height (mobile)',
    },
    {
      name: 'maxHeight',
      component: 'number',
      label: 'Max Section Pixel Height (mobile)',
    },
  ],
  defaultValue: {
    ...mobileDefaultValue,
    maxHeight: 400,
    minHeight: 300,
  },
};

const desktopWithHeight = {
  label: 'Desktop settings',
  name: 'desktop',
  component: 'group',
  fields: [
    ...desktopFields,
    {
      name: 'minHeight',
      component: 'number',
      label: 'Min Section Pixel Height (desktop)',
    },
    {
      name: 'maxHeight',
      component: 'number',
      label: 'Max Section Pixel Height (desktop)',
    },
  ],
  defaultValue: {
    ...desktopDefaultValue,
    maxHeight: 680,
    minHeight: 300,
  },
};

export const section = {
  label: 'Section Settings',
  name: 'section',
  description:
    'Background color, text color, container width, margin top, vertical padding',
  component: 'group',
  fields: [...fields, mobile, desktop],
};

export const sectionWithHeight = {
  label: 'Section Settings',
  name: 'section',
  description:
    'Background color, text color, container width, margin top, vertical padding, section heights',
  component: 'group',
  fields: [...fields, mobileWithHeight, desktopWithHeight],
};
