import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.bar = ({ inView, menuOpen }) => ({
      position: 'fixed',
      top: 0,
      color: 'white',
      zIndex: 20,
      px: ['16px', 64],
      py: ['12px', 6],
      transition: 'all 0.3s ease-in-out',
      backgroundColor: inView && !menuOpen ? 'transparent' : colors.black,
    });
    this.logoContainer = {
      height: ['30px', '48px'],
      width: '173px',
      display: 'flex',
      position: ['relative', 'relative'],
      top: ['-3px', '-5px'],
      justifyContent: 'start',
      textAlign: 'center',
    };
    this.logo = ({ inView, menuOpen, cms }) => ({
      position: 'relative',
      top: inView ? ['0', '7px'] : [0, 0],
      '& > svg': {
        width: inView && !menuOpen ? ['61px', '173px'] : ['44px', '61px'],
        height: inView && !menuOpen ? ['62px', '140px'] : ['36px', '62px'],
        color: menuOpen || !inView ? colors.yellow : cms.color,
        transition: 'all 0.3s ease-in-out',
      },
    });
    this.menuButtonMobile = ({ inView, menuOpen, cms }) => ({
      display: ['block', 'none'],
      '& span': {
        display: 'block',
        position: 'relative',
        width: '1.4rem',
        height: '.1875rem',
        backgroundColor: menuOpen || !inView ? colors.yellow : cms.color,
        transition: 'all 0.3s ease-in-out',
        mb: '.4rem',
      },
      '& span:nth-child(1)': {
        top: menuOpen ? '.5rem' : '0',
        transform: menuOpen ? 'rotate(45deg)' : '',
      },
      '& span:nth-child(2)': {
        opacity: menuOpen ? 0 : 1,
      },
      '& span:nth-child(3)': {
        top: menuOpen ? '-.5rem' : '0',
        transform: menuOpen ? 'rotate(-45deg)' : '',
      },
    });
    this.menuMobile = ({ menuOpen }) => ({
      display: 'flex',
      opacity: menuOpen ? 1 : 0,
      transform: menuOpen ? 'rotateX(0deg)' : 'rotateX(-90deg)',
      transformOrigin: '0 0',
      transition: 'all .3s ease-in-out',
      flexDirection: 'column',
      gap: '32px',
      py: '32px',
      px: '16px',
      backgroundColor: colors.black,
      borderTop: 'solid 1px white',
      position: 'fixed',
      top: '55px',
      zIndex: 1,
    });
    this.menuItemMobile = {
      fontSize: '22px',
      fontWeight: 'bold',
      color: colors.yellow,
    };
    this.menuDesktop = ({ inView, cms }) => ({
      display: ['none', 'flex'],
      color: inView ? cms.color : colors.yellow,
      gap: '3rem',
      alignItems: 'center',
      pl: 0,
    });
    this.menuItemDesktop = {
      fontSize: '27.5px',
      fontWeight: '600',
      '& > a': {
        position: 'relative',
      },
      '& > a::before': {
        content: '""',
        position: 'absolute',
        bottom: '-.125rem',
        left: 0,
        width: 0,
        height: '.125rem',
        backgroundColor: 'currentColor',
        transition: 'width .3s',
        visibility: 'hidden',
      },
      '& > :hover::before': {
        width: '100%',
        visibility: 'visible',
      },
    };
    this.cta = {
      height: ['30px', '48px'],
      width: ['88px', '120px'],
      minWidth: 'unset',
      paddingLeft: '0px',
      paddingRight: '0px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      fontSize: ['14px', '20px'],
      fontWeight: 'bold',
      transition: 'all 0.3s ease-in-out, border 1ms',
    };
    this.ctaContainer = {
      width: '173px',
      height: ['30px', '48px'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      alignSelf: 'center',
    };
  })()
);
