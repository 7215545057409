import startCase from 'lodash.startcase';
import { fontSizes } from '@theme/theme.fontSizes';

// can be used in any select field as its options
export const sizes = {
  options: [...Object.keys(fontSizes)]
    .map((sizeKey) => ({
      label: startCase(sizeKey),
      value: fontSizes[sizeKey],
    }))
    .sort((a, b) => {
      // if (a.label < b.label) {
      //   return -1;
      // }
      // if (a.label > b.label) {
      //   return 1;
      // }
      return 0;
    }),
  value: fontSizes,
};
