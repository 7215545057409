import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.wrapper = {
      overflow: 'hidden',
      pt: ['3rem', '10rem'],
      position: 'relative',
    };

    this.container = {
      position: 'relative',
      mb: [110, 180],
      background: colors.yellow,
      pt: ['10rem', '5rem'],
      ':before': {
        content: '""',
        position: 'absolute',
        backgroundImage:
          'url("https://cdn.shopify.com/s/files/1/1418/5086/t/248/assets/yellow-top.svg%3Fv=157373538772228224031663268174")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        height: '2.5rem',
        width: '100%',
        top: ['-.625rem', '-.625rem', '-.625rem', '-2.25rem'],
      },
      ':after': {
        content: '""',
        position: 'absolute',
        display: 'block',
        width: '100%',
        height: ['18%', '30%'],
        bottom: ['-18%', '-30%'],
        background:
          'linear-gradient(175deg,#f9de58 0%,#f9de58 50%,rgba(255,255,255,0) 50%,rgba(255,255,255,0) 100%)',
      },
    };

    this.title = {
      variant: 'text.h3',
      mb: 12,
      textAlign: 'center',
    };
    this.accordion = {
      border: 'none',
      textAlign: 'center',
    };
    this.accordionItem = {
      backgroundColor: 'gray.light',
      mb: 10,
      border: 'none',
    };
    this.top = {
      whiteSpace: 'initial',
      textAlign: 'center',
      display: 'inline-block',
      paddingLeft: '1rem',
      textTransform: 'uppercase',
      fontSize: '1.5277778vw',
      fontWheight: '600',
      lineHeight: '109.09%',
    };

    this.content = {
      lineHeight: '2.0833333vw',
      fontSize: '1.5277778vw',
      fontWeight: 500,
    };
    this.accordionHeader = {
      px: 10,
      textAlign: 'center',
      ':hover': {
        backgroundColor: 'transparent',
        border: 'none',
      },
    };
    this.accordionBody = {
      px: 10,
      p: {
        m: 0,
      },
    };
    this.more = {
      display: 'block',
      variant: 'buttons.plain',
      margin: '0 auto',
      textDecoration: 'underline',
    };
  })()
);
