import startCase from 'lodash.startcase';
import { colors as themeColors } from '@theme/theme.colors';

// can be used in any select field as its options
export const color = {
  options: [...Object.keys(themeColors)]
    .map((colorKey) => ({
      label: startCase(colorKey),
      value: themeColors[colorKey],
    }))
    .sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    }),
  value: themeColors,
};
