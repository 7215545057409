import { store } from '@backpackjs/storefront';

const overlay = store.recoil.atom({
  key: 'global/overlay',
  default: null,
});

const modal = store.recoil.atom({
  key: 'global/modal',
  default: null,
});

const sidebar = store.recoil.atom({
  key: 'global/sidebar',
  default: null, // null || 'cart' || 'search'
});

export default {
  overlay,
  modal,
  sidebar,
};
