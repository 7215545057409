import { useCartCount } from '@backpackjs/storefront';

import { Totals } from '@snippets/Cart';
import { useSidebar } from '@hooks';

import { themed } from './Footer.theme';

export const Footer = themed(({ theme, inputRef, ...props }) => {
  const cartCount = useCartCount();
  const [{ isCart }] = useSidebar();

  return isCart && cartCount ? <Totals inSidebar ref={inputRef} /> : null;
});

Footer.displayName = 'Footer';
