import { Paragraph } from 'theme-ui';

import { useSearch, useSearchResults } from '@hooks';
import { themed } from './Empty.theme';

export const Empty = themed(({ theme, ...props }) => {
  const [{ term }, {}] = useSearch();
  const [{ foundProducts, foundCollections }, {}] = useSearchResults();

  const noResultsFound =
    term && !foundCollections?.length && !foundProducts?.length;

  return noResultsFound ? (
    <Paragraph data-comp={Empty.displayName} sx={theme.text}>
      Nothing found, please try a different search
    </Paragraph>
  ) : null; // have results
});

Empty.displayName = 'Empty';
