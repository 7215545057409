import { useState } from 'react';
import { Container, Flex, Box, Heading, Image } from 'theme-ui';
import { withInView } from '@utils';
import { Section, Svg } from '@snippets';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectFade } from 'swiper';
import { Navigation } from './Navigation';

import { Schema } from './ReviewSlider.schema';
import { themed } from './ReviewSlider.theme';
import { Content } from './Content';

export const ReviewSlider = withInView(
  themed(({ theme, cms }) => {
    const { section, headingSettings, curveEnabled, reviews } = cms;

    const { heading, fontFamily, image } = headingSettings;
    const [swiper, setSwiper] = useState();

    return (
      <Section
        section={section}
        sx={{
          position: 'relative',
          ...(curveEnabled
            ? {
                ':before': {
                  content: '""',
                  position: 'absolute',
                  height: '2.75rem',
                  top: '-2.75rem',
                  backgroundImage: `url('/svgs/yellow-border-top.svg')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: '100vw',
                },
              }
            : null),
        }}
      >
        <Container data-comp={ReviewSlider.displayName}>
          <Flex variant="flex.column.center">
            {headingSettings && heading && (
              <Heading
                as="h3"
                sx={{
                  fontFamily: [fontFamily],
                  fontSize: [9, null, 12],
                  lineHeight: 1.5,
                  textAlign: 'center',
                }}
              >
                {heading}
              </Heading>
            )}

            {image?.src && !image?.src.includes('amazon') ? (
              <Image src={image.src} alt="" sx={{ mt: 6, mb: 12 }} />
            ) : (
              <Svg
                src="/svgs/noprecache/amazon.svg#amazon"
                alt="Amazon"
                viewBox="0 0 80 24"
                sx={{ mt: 6, mb: 12, width: '80px' }}
              />
            )}
          </Flex>

          {reviews ? (
            <Box
              sx={{
                maxWidth: '700px',
                px: [15, null, 10],
                position: 'relative',
                mx: 'auto',
              }}
            >
              <Swiper
                {...defaultParams}
                onSwiper={setSwiper}
                modules={[Pagination, EffectFade]}
              >
                {reviews?.map((review, index) => (
                  <SwiperSlide key={index}>
                    <Content review={review} />
                  </SwiperSlide>
                ))}

                <div
                  className="swiper-pagination"
                  sx={{
                    position: 'relative',
                    backgroundColor: 'none',
                    color: 'text',
                    display: 'flex',
                    justifyContent: 'center',
                    width: '75px',
                    span: {
                      backgroundColor: 'text',
                      border: '1px solid black',
                      height: 10,
                      width: 10,
                    },
                  }}
                />
              </Swiper>

              <Navigation swiper={swiper} />
            </Box>
          ) : null}
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

ReviewSlider.displayName = 'ReviewSlider';
ReviewSlider.Schema = Schema;

const defaultParams = {
  allowSlideNext: true,
  allowSlidePrev: true,
  allowTouchMove: true,
  centeredSlides: true,
  fadeEffect: { crossFade: true },
  freeMode: false,
  loop: true,
  navigation: false,
  slidesPerGroup: 1,
  slidesPerView: 1,
  spaceBetween: 0,
  watchOverflow: true,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
};
