import { Container } from 'theme-ui';

import { withInView } from '@utils';
import { Section } from '@snippets';

import { Schema } from './FourTile.schema';
import { Slider, GridDisplay } from './Displays';
import { Header, Footer } from './Content';

export const FourTile = withInView(
  ({ cms }) => {
    const { section, header, tiles, tile } = cms;

    return (
      <Section section={section}>
        <Container data-comp={FourTile.displayName}>
          <Header header={header} section={section} />

          {tiles?.length > 0 ? (
            tile?.display === 'stacked' ? (
              <GridDisplay tiles={tiles} tile={tile} />
            ) : (
              <Slider tiles={tiles} tile={tile} />
            )
          ) : null}

          <Footer header={header} section={section} />
        </Container>
      </Section>
    );
  },
  { triggerOnce: true }
);

FourTile.displayName = 'FourTile';
FourTile.Schema = Schema;
