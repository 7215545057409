import { useCallback, useState } from 'react';
import { Box, Button, Input } from 'theme-ui';

import { Svg } from '@snippets';

import { themed } from './EmailSignup.theme';

export const EmailSignup = themed(({ theme, ...props }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      // e.g klaviyo subscribe code here
    },
    [email]
  );

  return (
    <Box
      data-comp="EmailSignup"
      as="form"
      email={email}
      onSubmit={handleSubmit}
      {...props}
      sx={{
        ...theme.wrapper,
        ...props.sx,
      }}
    >
      <Input
        placeholder="Enter your email address"
        type="email"
        required
        onChange={(e) => setEmail(e.target.value)}
        sx={theme.input}
      />

      <Button sx={theme.button}>
        <Svg
          src="/svgs/arrow-right.svg#arrow-right"
          alt="Submit"
          viewBox="0 0 48 48"
          sx={theme.icon}
        />
      </Button>
    </Box>
  );
});
