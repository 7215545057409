import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.container = {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      mb: [13, 14],
      flex: 1,
      backgroundColor: colors.cream,
      borderBottom: ` 1px solid ${colors.gray.mediumLight}`,
    };
    this.heading = {
      variant: 'text.nav',
      pb: 4,
    };
  })()
);
