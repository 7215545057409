export default {
  label: 'Cart Settings',
  name: 'cart',
  component: 'group',
  fields: [
    {
      label: 'Cart Empty Settings',
      name: 'empty',
      component: 'group',
      fields: [
        {
          label: 'Message',
          name: 'message',
          component: 'markdown',
          defaultValue: 'Your cart is currently empty.',
        },
        {
          label: 'Link',
          name: 'cta',
          component: 'link',
          defaultValue: {
            text: 'SHOP ALL',
            url: '/collections/all',
          },
        },
      ],
    },
  ],
};
