import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y } from 'swiper';

import { ProductItem } from '@snippets';

import { Navigation } from './Navigation';
import { useProductSwiperParams } from './useProductSwiperParams';
import { themed } from './ProductSwiper.theme';

import 'swiper/css/bundle';

export const ProductSwiper = themed(
  ({ theme, products, params = {}, isProductRecs, ...props }) => {
    const defaultParams = useProductSwiperParams({ isProductRecs });
    const [swiper, setSwiper] = useState();

    return (
      <Box
        data-comp={ProductSwiper.displayName}
        role="list"
        {...props}
        sx={{
          ...theme.swiper,
          ...props.sx,
        }}
      >
        <Swiper
          onSwiper={setSwiper}
          modules={[A11y]}
          a11y={{ enabled: true }}
          {...defaultParams}
          {...params}
        >
          {products?.map((item, index) => {
            const product = isProductRecs ? item : item.product;
            if (!product?.handle) return null;
            return (
              <SwiperSlide key={product.handle + index}>
                <ProductItem product={product} />
              </SwiperSlide>
            );
          })}
        </Swiper>

        {products?.length > 0 && <Navigation swiper={swiper} />}
      </Box>
    );
  }
);

ProductSwiper.displayName = 'ProductSwiper';
ProductSwiper.propTypes = {
  products: PropTypes.array,
  params: PropTypes.object,
  isProductRecs: PropTypes.bool,
};
