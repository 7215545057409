import { Flex, Paragraph } from 'theme-ui';
import { useProductItemByHandle } from '@backpackjs/storefront';

import { Link, Picture } from '@snippets';
import { themed } from './Product.theme';

export const Product = themed(({ theme, product, style, cta }) => {
  const { productItem } = useProductItemByHandle({ handle: product?.handle });

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        flex: '0 0 30%',
        alignItems: 'center',
        my: ['1.875rem', null],
      }}
    >
      <Picture
        alt={product.title}
        images={[{ src: productItem?.images[0].src, width: '60px', ratio: 1 }]}
        sx={{
          maxWidth: '300px',
          width: ['206px', '280px'],
        }}
      />
      <Paragraph variant="text.h3" sx={{}}>
        {product.title.split('-')[0]}
      </Paragraph>
      <Link
        href={cta.url}
        variant={`buttons.${style || 'primary'}`}
        sx={{
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '21rem',
          width: '100%',
          fontSize: '1.375rem',
          lineHeight: '1.5rem',
          fontWeight: 600,
          height: ['68px'],
          mt: [10, 10],
        }}
      >
        {cta.text}
      </Link>
    </Flex>
  );
});

Product.displayName = 'Product';
