import { Flex, Text, Button, Heading } from 'theme-ui';
import { themed } from './Variants.theme';
import { Price } from './Price';

export const Variants = themed(
  ({
    theme,
    heading,
    variants,
    handleSelect,
    productsVariants = [],
    subscribePrice,
  }) => {
    return (
      <Flex data-comp={Variants.displayName} sx={theme.container}>
        <Heading variant="text.h3">{heading}</Heading>
        <Flex sx={theme.wrapper}>
          {variants?.length > 0 &&
            variants.map(
              (
                { title, subtitle, totalGlasses, showBadge, badge, isSelected },
                index
              ) => (
                <Button
                  key={index}
                  variant={
                    isSelected ? 'outlineSecondaryInverse' : 'outlineSecondary'
                  }
                  sx={theme.box({ showBadge, badge })}
                  onClick={() => handleSelect(index)}
                >
                  <Flex
                    sx={{
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Text sx={theme.title}>{title}</Text>

                    <Price
                      subscribePrice={subscribePrice}
                      selectedVariant={productsVariants[index]}
                      isSelected={isSelected}
                      totalGlasses={totalGlasses}
                      subtitle={subtitle}
                    />
                  </Flex>
                </Button>
              )
            )}
        </Flex>
      </Flex>
    );
  }
);

Variants.displayName = 'Variants';
