import { ingredients } from './ingredients';

export const swatches = {
  label: 'Swatches',
  name: 'swatches',
  component: 'group-list',
  itemProps: {
    label: '{{item.name}}' || 'New swatch',
  },
  defaultItem: {
    name: 'black',
    color: '#000000',
    product: {
      handle: 'extra-strong-cold-brew-on-tap-96oz-straight-black',
      title: 'Cold Brew On Tap (96 oz) - Straight Black',
    },
  },
  fields: [
    {
      label: 'name',
      name: 'name',
      component: 'text',
    },
    {
      label: 'Color',
      name: 'color',
      component: 'color',
    },
    ingredients,
    {
      label: 'product',
      description: 'Select product',
      name: 'product',
      component: 'productSearch',
    },
  ],
};
