/**
 * Validates if an url is valid or not
 * @param {string} url
 * @returns {boolean} true / false
 */
export const isValidUrl = (url) => {
  try {
    new URL(url);
  } catch (e) {
    console.error(e);
    return false;
  }
  return true;
};

/**
 * Validates if the video url is valid
 * @param {string} videoUrl
 * @param {string} extension video extension default value mp4
 * @returns {boolean} true / false
 */
export const isValidVideo = (videoUrl, extension = '.mp4') => {
  if (!isValidUrl(videoUrl)) return false;
  const url = videoUrl.split('?');
  return !!url[0].endsWith(extension);
};
