import { Flex } from 'theme-ui';
import { StarsIcon } from '@snippets';
import { themed } from './Reviews.theme';

export const Reviews = themed(({ theme, starRating }) => {

  return (
    <Flex
      data-comp={Reviews.displayName}
      sx={theme.container}>
      <Flex variant={'flex.row.center'}>
        {[...new Array(5)].map((_, index) => (
          <StarsIcon
            averageScore={starRating || 4}
            emptyColor={'craft'}
            hoveredIndex={0}
            index={index}
            key={4 + index}
            fullColor={'craft'}
          />
        ))}
      </Flex>
    </Flex>
  );
});

Reviews.displayName = 'Reviews';
