import { Flex, Box, Heading } from 'theme-ui';

import { Swatch } from '@snippets';

import { themed } from './Swatches.theme';

export const Swatches = themed(
  ({ theme, swatches, title, handleSelect, type }) => {
    if (swatches.length === 0) return null;
    return (
      <Flex data-comp={Swatches.displayName} sx={theme.container}>
        {title && (
          <Flex sx={theme.heading}>
            <Heading
              variant="text.base"
              sx={{
                mx: 4,
                textTransform: 'uppercase',
                fontFamily: 'gtPressura',
              }}
            >
              {title}
            </Heading>
          </Flex>
        )}
        <Flex sx={{ flexWrap: type ? 'nowrap' : 'wrap', width: '100%' }}>
          {swatches.map(({ color, title, isSelected, id }) => (
            <Box key={id} sx={{ flex: '33.33%', my: 2 }}>
              <Swatch
                color={color}
                colorName={title || id}
                handleClick={handleSelect}
                size={2}
                isSelected={isSelected}
                showName
              />
            </Box>
          ))}
        </Flex>
      </Flex>
    );
  }
);

Swatches.displayName = 'Swatches';
