import { useState, useEffect } from 'react';
import { Heading, Flex, Spinner, Box, Text } from 'theme-ui';
import dynamic from 'next/dynamic';

import { colors } from '@theme/theme.colors';

import { themed } from './Content.theme';
import { Swatches } from './Swatches';
import { Variants } from './Variants/Variants';
import { Subscribe } from './Subscribe';
import { AddItemToCart } from './AddItemToCart';
import { Reviews } from './Reviews';


const Image = dynamic(() => import('./Image').then((module) => module.Image), {
  ssr: false,
  suspense: false,
});

export const ContentComponent = themed(
  ({
    theme,
    content,
    product,
    selectedSwatch,
    allSwatches,
    handleSwatchSelect,
    handleSwatchSelector,
    handleSubscriptionSelect,
    selectedSubscription,
    toggleIngredientsModal,
    selectedPosition,
    setSelectedPosition
  }) => {
    const {
      subscription,
      reviews: { starRating },
      variants: { variants, flavor, boxes },
    } = content;

    const doc = new DOMParser().parseFromString(
      product?.descriptionHtml,
      'text/html'
    );
    const spans = doc.querySelectorAll('span');
    const arr = Array.from(spans);
    const description = arr
      .map((span) => span.innerText)
      .filter((text) => text.length > 1);

    const [selectedVariant, setSelectedVariant] = useState(
      product?.variants[selectedPosition - 1]
    );
    const [allVariants, setAllVariants] = useState([]);

    const sellingPlans = product?.sellingPlanGroups;
    let subscribeFound;
    if (selectedVariant.title === "2 Box Discount Price") {
      subscribeFound = sellingPlans?.find((element) => element.name === "Subscribe & Save - 2 box");
    } else if (selectedVariant.title === "1 Box") {
      subscribeFound = sellingPlans?.find((element) => element.name === "Subscribe & Save - 1 box");
    } else if (selectedVariant.title === "3 Box Discount Price") {
      subscribeFound = sellingPlans?.find((element) => element.name === "Subscribe & Save - 3 box");
    }
    const sellingPlanID = subscribeFound?.sellingPlans[0]?.id;
    const subscriptionDiscount = subscribeFound?.sellingPlans[0]?.priceAdjustments[0]?.adjustmentValue?.adjustmentAmount.amount

    useEffect(() => {
      setSelectedVariant(
        product?.variants[selectedPosition - 1]
      );
    }, [product]);

    useEffect(() => {
      setAllVariants(
        product?.variants?.map((variant, index) => {
          return (variant && index == selectedPosition - 1)
            ? { ...variant, isSelected: true }
            : { ...variant, isSelected: false };
        })
      );
    }, [variants]);

    const handleVariantSelect = (currentVariant) => {
      const variantSelected = allVariants?.map((variant, index) =>
        index === currentVariant
          ? {
              ...variant,
              isSelected: true,
            }
          : {
              ...variant,
              isSelected: false,
            }
      );
      setAllVariants(variantSelected);

      const variant = product?.variants?.filter(
        (vr) => vr.position === currentVariant + 1
      )[0];

      setSelectedVariant(variant);
      setSelectedPosition(currentVariant + 1);
    };

    const totalBoxes = selectedVariant?.position;
    const image = !selectedVariant?.image ? (
      <Spinner
        sx={{
          width: '60px',
          m: 'auto',
        }}
      />
    ) : (
      <Image image={selectedVariant?.image} imageSx={{ objectFit: 'contain' }} />
    );
    return (
      <Flex data-comp={ContentComponent.displayName} sx={theme.container}>
        <Flex
          sx={{
            flexDirection: 'column',
            width: ['100%', '50%'],
          }}
        >
          {image}
          {flavor?.productIcons?.length > 0 &&
            <Flex sx={{ display: ['none', 'flex'], justifyContent: 'space-around', alignItems: 'center' }}>
              {flavor?.productIcons?.map((productIcon) => (
                <Flex sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: '6px' }}>
                  <Image style={{
                    width: '60px',
                    height: '60px'
                  }} image={productIcon?.image} />
                  <Text sx={{ fontSize: '16px', color: colors.blueGreen, lineHeight: '20px' }}>{productIcon?.iconText}</Text>
                </Flex>
              ))}
            </Flex>
          }
        </Flex>
        <Flex
          sx={{
            flexDirection: 'column',
            width: ['100%', '50%'],
            px: [5, 3],
            overflow: 'hidden',
          }}
        >
          <Reviews starRating={starRating} />
          <Box sx={theme.metadata}>
            <Heading as="h2" sx={theme.title}>
              {product?.title.split(' - ')[0]}
            </Heading>

            <Flex sx={{ alignItems: 'center', columnGap: 3 }}>
              {selectedSubscription ? (
                <Flex sx={{ alignItems: 'center', columnGap: 3 }}>
                  <Text variant="text.h4" sx={{ fontSize: ['22px', '22px'], lineHeight: ['24px', '24px'], color: colors.secondRed }}>
                    {`$${Number((selectedVariant?.price) - subscriptionDiscount).toFixed(2) * totalBoxes} `}
                  </Text>
                  <Text
                    sx={{ textDecoration: 'line-through', fontSize: ['18px', '18px'], lineHeight: ['24px', '24px'], fontWeight: 400, color: colors.grayTwo }}
                  >
                    {`$${Number(
                      selectedVariant?.price
                    ) * totalBoxes
                      } `}
                  </Text>
                </Flex>
              ) : (
                <Flex sx={{ alignItems: 'center', columnGap: 3 }}>
                  <Text
                    variant="text.h4"
                    sx={{ fontSize: ['22px', '22px'], lineHeight: ['24px', '24px'] }}
                  >
                    {`$${Number(
                      selectedVariant?.price
                    ) * totalBoxes
                      } `}
                  </Text>
                </Flex>
              )}
              <Text variant="text.h4" sx={{ fontSize: ['15px', '15px'], lineHeight: ['24px', '24px'], color: colors.grayTwo }}> • {parseInt(selectedVariant?.title)} {parseInt(selectedVariant?.title) > 1 ? 'Boxes':'Box'}</Text>
            </Flex>

            <Box sx={{ margin: 0, py: 11, borderBottom: (t) => `1px solid ${t.colors.black}` }}>
              <Box as="ul" sx={{ listStyle: 'none', p: 0 }}>
                {description.map((item, id) => (
                  <Box key={id} as="li" sx={theme.description.item}>
                    <Box as="span" sx={theme.description}>
                      {item}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <Swatches
            swatches={allSwatches}
            swatchTitle={flavor?.title}
            ingredientsLinkText={flavor?.linkText}
            handleSelect={handleSwatchSelect}
            handleSwatchSelector={handleSwatchSelector}
            toggleIngredientsModal={toggleIngredientsModal}
            selectedSwatch={selectedSwatch}
            flavor={flavor}
          />
          <Variants
            variants={allVariants}
            heading={boxes?.title}
            dimensionsLinkText={boxes?.linkText}
            handleSelect={handleVariantSelect}
            productsVariants={product?.variants}
            subscribePrice={selectedSubscription}
            selectedVariant={selectedVariant}
            selectedPlanDiscount={subscriptionDiscount}
            dimensions={boxes?.dimensions}
            boxes={boxes}
          />

          <Subscribe
            subscriptionDetails={subscription}
            handleSelect={handleSubscriptionSelect}
            isSelected={selectedSubscription}
            sellingPlanId={sellingPlanID}
            selectedVariant={selectedVariant}
            selectedPlanDiscount={subscriptionDiscount}
          />

          <AddItemToCart
            selectedSubscription={selectedSubscription}
            selectedPlanID={sellingPlanID}
            selectedPlanDiscount={subscriptionDiscount}
            selectedVariant={selectedVariant}
            selectedSwatch={selectedSwatch}
            variants={allVariants}
          />
        </Flex>
      </Flex>
    );
  }
);

ContentComponent.displayName = 'ContentComponent';
