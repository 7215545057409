import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      mb: [2, 4],
    };
    this.heading = {
      pb: 4,
      display: 'flex',
      flexDirection: 'row',
      width: '100%',

      '&:before, &:after': {
        content: '""',
        flex: '1 1',
        borderBottom: '1px solid',
        margin: 'auto',
      },
      '&:before': {
        ml: '-50%',
        right: '0.5em',
      },
      '&:after': {
        mr: '-50%',
        left: '0.5em',
      },
    };
  })()
);
