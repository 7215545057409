import { section } from '@settings/common/section';
import { align } from '@settings/common/align';
import { color } from '@settings/common/color';
import { button } from '@settings/common/button';

export function Schema({ article, blog, collection }) {
  if (article || blog || collection) return null;

  return {
    label: 'Products slider',
    key: 'products-slider',
    fields: [
      {
        name: 'groups',
        label: 'Product Groups',
        component: 'group-list',
        itemProps: {
          label: '{{item.name}}',
        },
        defaultItem: {
          name: 'New Group',
          cta: {
            text: 'Shop All Products',
            url: '/collections/all',
          },
          products: [
            {
              product: {
                handle: 'extra-strong-cold-brew-on-tap-96oz-hazelnut',
              },
            },
            {
              product: { handle: 'coarse-ground-organic-coffee-cold-brew' },
            },
            {
              product: {
                handle: 'extra-strong-cold-brew-coffee-32oz-straight-black',
              },
            },
            {
              product: {
                handle:
                  'extra-strong-organic-cold-brew-coffee-11oz-straight-black',
              },
            },
            {
              product: {
                handle: 'extra-strong-cold-brew-on-tap-128oz-straight-black',
              },
            },
          ],
        },
        fields: [
          {
            name: 'name',
            label: 'Group Name',
            component: 'text',
          },
          {
            name: 'products',
            label: 'Products',
            component: 'group-list',
            itemProps: {
              label: '{{item.product.handle}}',
            },
            defaultItem: {
              product: {
                handle: 'extra-strong-cold-brew-on-tap-96oz-hazelnut',
              },
            },
            fields: [
              {
                name: 'product',
                component: 'productSearch',
                label: 'Product',
              },
            ],
            defaultValue: [
              {
                product: {
                  handle: 'extra-strong-cold-brew-on-tap-96oz-hazelnut',
                },
              },
              {
                product: { handle: 'coarse-ground-organic-coffee-cold-brew' },
              },
              {
                product: {
                  handle: 'extra-strong-cold-brew-coffee-32oz-straight-black',
                },
              },
              {
                product: {
                  handle:
                    'extra-strong-organic-cold-brew-coffee-11oz-straight-black',
                },
              },
              {
                product: {
                  handle: 'extra-strong-cold-brew-on-tap-128oz-straight-black',
                },
              },
            ],
          },
          {
            name: 'cta',
            component: 'link',
            label: 'Group Button',
          },
        ],
        defaultValue: [
          {
            name: 'Shop All',
            products: [
              {
                product: {
                  handle: 'extra-strong-cold-brew-on-tap-96oz-hazelnut',
                },
              },
              {
                product: { handle: 'coarse-ground-organic-coffee-cold-brew' },
              },
              {
                product: {
                  handle: 'extra-strong-cold-brew-coffee-32oz-straight-black',
                },
              },
              {
                product: {
                  handle:
                    'extra-strong-organic-cold-brew-coffee-11oz-straight-black',
                },
              },
              {
                product: {
                  handle: 'extra-strong-cold-brew-on-tap-128oz-straight-black',
                },
              },
            ],
            cta: {
              text: 'Shop All Products',
              url: '/collections/all',
            },
          },
          // {
          //   name: 'New Products',
          //   products: [
          //     {
          //       product: { handle: 'the-coolidge-crmo-fixed-gear' },
          //     },
          //     {
          //       product: { handle: 'the-delta' },
          //     },
          //     {
          //       product: { handle: 'fixie-the-mike' },
          //     },
          //     {
          //       product: { handle: 'the-charlie' },
          //     },
          //     {
          //       product: { handle: 'the-tango' },
          //     },
          //   ],
          //   cta: {
          //     text: 'Shop New Products',
          //     url: '/collections/all',
          //   },
          // },
          // {
          //   name: 'Bundles',
          //   products: [
          //     {
          //       product: { handle: 'fixie-the-mike' },
          //     },
          //     {
          //       product: { handle: 'the-charlie' },
          //     },
          //     {
          //       product: { handle: 'the-coolidge-crmo-fixed-gear' },
          //     },
          //     {
          //       product: { handle: 'the-delta' },
          //     },
          //     {
          //       product: { handle: 'the-tango' },
          //     },
          //   ],
          //   cta: {
          //     text: 'Shop All Bundles',
          //     url: '/collections/all',
          //   },
          // },
        ],
      },
      {
        label: 'Content Settings',
        name: 'content',
        description: 'Heading, text align, button style',
        component: 'group',
        fields: [
          {
            name: 'heading',
            label: 'Heading',
            component: 'text',
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'textAlign',
            description: "Doesn't apply on desktop when group list is inline",
            label: 'Heading Alignment',
            options: align.options,
          },
          {
            name: 'ctaStyle',
            label: 'Group Button Style',
            component: 'select',
            options: button.options,
          },
        ],
        defaultValue: {
          heading: 'Shop Best Sellers',
          textAlign: align.value.center,
          ctaStyle: 'primary',
        },
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'content',
            mt: 'none',
            py: 'm',
          },
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
}
