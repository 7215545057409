import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.header = {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      mb: [13, 14],
      flex: 1,
      borderBottom: ` 1px solid ${colors.gray.mediumLight}`,
      width: '80%',
    };
    this.heading = {
      // variant: 'text.nav',
      fontFamily: 'gtPressuraBold',
      pb: 4,
    };
  })()
);
