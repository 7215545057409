import PropTypes from 'prop-types';
import { Box, Flex } from 'theme-ui';

import { DropdownOption } from './Dropdown.Option';
import { DropdownSelected } from './Dropdown.Selected';
import { useDropdown } from './useDropdown';
import { themed } from './Dropdown.theme';

export const Dropdown = themed(
  ({
    theme,
    variant = 'default',
    initial,
    children,
    maxHeight = '400px',
    onSelected = () => {},
    onSelecting = () => {},
    ...props
  }) => {
    const [{ dropdownRef, Component, selecting }] = useDropdown({
      variant,
      initial,
      children,
      onSelected,
      onSelecting,
      ...props,
    });

    return (
      <Flex
        data-comp={Dropdown.displayName}
        ref={dropdownRef}
        {...props}
        sx={{
          ...theme[variant]?.wrapper,
          ...props.sx,
        }}
      >
        {/* Render selected option */}
        {Component.Selected}

        {/* Render drawer with options */}
        <Flex
          data-comp="DropdownDrawer"
          sx={{
            ...theme[variant]?.drawer,
            maxHeight: selecting ? maxHeight : '0px',
          }}
        >
          <Box
            data-comp="DropdownScroll"
            role="listbox"
            sx={{
              ...theme[variant]?.scroll,
            }}
          >
            {/* Render options */}
            {Component.Options}
          </Box>
        </Flex>
      </Flex>
    );
  }
);

Dropdown.displayName = 'Dropdown';
Dropdown.Option = DropdownOption;
Dropdown.Selected = DropdownSelected;

Dropdown.propTypes = {
  initial: PropTypes.any.isRequired,
  children: PropTypes.node,
  maxHeight: PropTypes.string, // pixels amount
  onSelected: PropTypes.func,
  onSelecting: PropTypes.func,
};
