import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.grid = {
      gridTemplateColumns: '70px 1fr',
      columnGap: 10,
      mb: 1,
      px: 3,
      py: 3,
      cursor: 'pointer',
      border: '1px solid transparent',
      '&:hover': {
        border: (t) => `1px solid ${t.colors.lightGray}`,
      },
    };

    this.picture = {
      width: '70px',
      height: '50px',
      alignSelf: 'center',
    };

    this.details = {
      variant: 'flex.column',
      ml: 8,
    };

    this.title = {
      variant: 'text.xs',
      textDecoration: 'uppercase',
      fontWeight: 600,
      pt: 3,
    };

    this.type = {
      variant: 'text.xs',
      color: 'grey',
    };

    this.prices = {
      minHeight: '22px',
    };

    this.price = {
      variant: 'text.xs',
      color: 'black',
    };
  })()
);
