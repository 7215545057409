import { Flex, Box, Text, Paragraph } from 'theme-ui';
import { Markdown, Svg, } from '@snippets';

import { themed } from './Supplementary.theme';

export const Supplementary = themed(({ theme, supplementary, }) => {

  return (
    <Flex data-comp={Supplementary.displayName} sx={theme.container}>

      <Flex sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center', gridGap: 1.5,
        maxWidth: '45rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxHeight: ['80%', '100%'],

      }} >
        <Markdown
          variant='text.h4'
          text={supplementary?.text?.title}

        />
        <Markdown sx={{ textAlign: 'center', mb: 8 }} >
          {supplementary?.text?.subtitle}
        </Markdown>
      </Flex>
      <Box sx={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: ['1fr', '1fr 1fr 1fr'],
        gridGap: '2rem'
      }}>
        {
          supplementary?.items?.map(item => {
            return (
              <Flex key={item.id} sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <Svg
                  src={item.icon}
                  viewBox="0 0 48 48"
                  sx={{ maxWidth: 50, }}
                />
                <Text
                  sx={{
                    marginBottom: ' 1rem',
                    marginTop: '1.125rem',
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                  }}
                >{item.title}</Text>
                <Paragraph
                  sx={{
                    textAlign: 'center',
                  }}
                >{item.description}</Paragraph>
              </Flex>
            )
          })
        }
      </Box>
    </Flex>
  );
});

Supplementary.displayName = 'Supplementary';
