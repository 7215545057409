import Head from 'next/head';

function HeadFonts() {
  return (
    <Head>
      <link
        key="GT-Pressura-Bold.woff2"
        rel="preload"
        as="font"
        crossOrigin="anonymous"
        href="/fonts/GT-Pressura-Bold.woff2"
      />
      <link
        key="GT-Pressura-Light.woff2"
        rel="preload"
        as="font"
        crossOrigin="anonymous"
        href="/fonts/GT-Pressura-Light.woff2"
      />
      <link
        key="GT-Pressura-Regular.woff2"
        rel="preload"
        as="font"
        crossOrigin="anonymous"
        href="/fonts/GT-Pressura-Regular.woff2"
      />
      <link
        key="tablet-gothic-extrabold.woff2"
        rel="preload"
        as="font"
        crossOrigin="anonymous"
        href="/fonts/tablet-gothic-extrabold.woff2"
      />
      <link
        key="tablet-gothic-condensed.woff"
        rel="preload"
        as="font"
        crossOrigin="anonymous"
        href="/fonts/tablet-gothic-condensed.woff"
      />
    </Head>
  );
}

export default HeadFonts;
