import { Flex, Box, Text, Button, Heading } from 'theme-ui';
import { StarsIcon, Svg } from '@snippets';
import { themed } from './Reviews.theme';

export const Reviews = themed(({ theme, type, starRating, title }) => {
  return (
    <Flex
      data-comp={Reviews.displayName}
      variant={type ? 'flex.row.center' : 'flex.column.reverse'}
      sx={theme.container}
    >
      <Flex variant="flex.row.center">
        {[...new Array(5)].map((_, index) => (
          <StarsIcon
            averageScore={starRating || 4}
            emptyColor="craft"
            hoveredIndex={0}
            index={index}
            key={4 + index}
            fullColor="craft"
          />
        ))}
      </Flex>
      <Text
        variant="text.base"
        sx={{ fontFamily: type ? 'body' : 'gtPressura', mx: 8, my: 8 }}
      >
        {title}
      </Text>
    </Flex>
  );
});

Reviews.displayName = 'Reviews';
