import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

const MENU_HEIGHT = ['60px', null, '80px'];

export const themed = create(
  new (function () {
    this.wrapper = {
      alignItems: 'center',
      // bg: 'background',
      columnGap: [4, 8, 12, 14],
      // gridTemplateColumns: ['1fr 1fr 1fr ', '80px 1fr'],
      // justifyContent: 'center',
      position: 'relative',
      height: MENU_HEIGHT,
      width: '100%',
      zIndex: 2,
    };

    // burger + search — mobile
    this.toggle = {
      display: ['flex', null, 'none'],
      width: 100,
    };
    this.logo = (isFixed) => ({
      cursor: 'pointer',
      mx: 'auto',
      // width: MENU_HEIGHT,
      position: 'absolute',
      left: 0,
      top: 0,
      color: isFixed ? colors.yellow : 'text',
      width: ['3.875rem', '10.875rem'],
      transition: 'transform 0.3s ease,color 0.3s ease',
      transform: [
        'translate(50%, 10%)',
        isFixed && 'scale(.4) translate(-50%,-4.8125rem)',
      ],
      willChange: 'transform',
    });
    this.nav = (isFixed) => ({
      variant: 'flex.row.center.center',
      display: ['none', null, 'flex'],
      height: 'inherit',
      flex: 1,
      color: isFixed ? colors.yellow : 'text',
      pl: 0,
    });
    this.item = {
      variant: 'text.h5',
      display: 'block',
      height: 'inherit',
      display: 'flex',
      alignItems: 'center',
      mr: [10, 11, 12],
      opacity: 1,
      position: 'relative',
      cursor: 'pointer',

      // hovered menu item
      hover: (isFixed, isHovered) => ({
        bg: isFixed ? colors.yellow : 'text',
        bottom: '1rem',
        content: '" "',
        display: 'block',
        height: '0.125rem',
        left: 0,
        position: 'absolute',
        right: 0,
        transition: 'transform .125s ease-out',
        transformOrigin: 'bottom left',
        width: '100%',
        willChange: 'transform',
        transform: isHovered ? 'scaleX(1)' : 'scaleX(0)',
      }),
    };
    this.ctas = {
      variant: 'flex.row.end.center',
      columnGap: [6, 8, 10],
      gridTemplateColumns: ['auto 1fr 1fr', 'auto 1fr 1fr 1fr'],
      ml: 'auto',
      width: ['auto'],
    };
    this.cta = {
      icon: {
        width: [24, null, 22],
        cursor: 'pointer',
      },
    };
  })()
);
