import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.dropdown = {};

    this.item = {
      variant: 'flex.row.start.center',
      width: '100%',
      cursor: 'pointer',
      color: 'text',
      height: 'inherit',
      px: [4, 6],
      '&:hover': {
        bg: 'whitesmoke',
      },

      icon: {
        width: [18, 22],
      },

      name: {
        variant: 'text.sm',
        ml: [0, 4],
        display: ['none', 'block'],
      },

      toggle: {
        width: [18, 22],
        ml: 'auto',
        display: ['none', 'flex'],
      },
    };
  })()
);
