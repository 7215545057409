import { create } from '@theme/create';

export const themed = create(
  new (function () {
    (this.shell = {
      bg: 'black',
      left: 0,
      position: 'absolute',
      display: ['block', null, 'none'],
      top: 'var(--header-height)',
      transform: 'translateX(-100%)',
      width: ['100%', '375px'],
      willChange: 'transform',
      zIndex: 1,
    }),
      (this.scroll = {
        py: 25,
        px: [9],
        height: `calc(var(--viewport-height)/2.25 - var(--header-height))`,
        overflowY: 'none',
        webkitOverflowScrolling: 'touch',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      });

    this.sidebar = {};
  })()
);
