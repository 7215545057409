export const useGroupOptionsWithPrices = (product, selectedVariant) => {
  if (!product || !product.options) return null;

  const variants = product.variants.filter(
    (variant) => variant?.product?.handle === selectedVariant?.product?.handle
  );

  const productOptionsWithPrices = product?.options[0]?.values.map((title) => {
    const variantProduct = variants.filter(
      (variant) => variant.title === title
    )[0];

    if (variantProduct?.price) {
      return {
        title: variantProduct.title,
        oneTimePrice: Number(variantProduct.price),
        discount: Math.round(
          100 /
            (Number(variantProduct?.compareAtPrice) /
              (Number(variantProduct?.compareAtPrice) -
                Number(variantProduct?.price) * variantProduct.position))
        ),
        subscriptionPrice: Number(
          variantProduct?.subscriptions?.discount_variant_price
        ),
        amount: variantProduct.position,
        defaultSelected: variantProduct.position === 2,
        customTitle:
          variantProduct.position > 1
            ? `${variantProduct.position} boxes`
            : `${variantProduct.position} box`,
        additional:
          variantProduct.position === 1
            ? '1 GLASS A DAY FOR 2 WEEKS'
            : variantProduct.position === 2
            ? '1 GLASS A DAY FOR A MONTH'
            : '1.5 GLASSES A DAY FOR A MONTH',
      };
    }
  });
  return productOptionsWithPrices;
};
