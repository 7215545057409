import { section } from '@settings/common/section';
import { fonts } from '@settings/common/fonts';

export const Schema = () => {
  return {
    label: 'Testimonials Slider',
    key: 'testimonialsSlider',
    fields: [
      {
        ...section,
        defaultValue: {
          desktop: {
            container: 'contentMedium',
            mt: 'none',
            py: 'xl',
          },
          mobile: {
            container: 'contentMedium',
            mt: 'none',
            py: 'xl',
          },
        },
      },
      {
        name: 'curveEnabled',
        label: 'Curve Enabled',
        component: 'toggle',
        toggleLabels: {
          true: 'Yes',
          false: 'No',
        },
        defaultValue: true,
      },
      {
        label: 'Heading Settings',
        name: 'headingSettings',
        description: 'Heading options',
        component: 'group',
        fields: [
          {
            name: 'heading',
            label: 'Heading',
            component: 'markdown',
          },
          {
            label: 'Font Family',
            name: 'fontFamily',
            component: 'select',
            options: fonts.options,
          },
        ],
        defaultValue: {
          heading: '## As Seen In',
          fontFamily: fonts.value.gtPressura,
        },
      },
      {
        name: 'testimonials',
        label: 'Testimonials',
        component: 'group-list',
        defaultItem: {
          quote: 'THIS IS THE ONLY BRAND I TRUST FOR AT-HOME COFFEE.',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/1418/5086/files/amazon-logo.svg?v=1652819928',
          },
        },
        itemProps: {
          key: '{{item.id}}',
          label: '{{item.name}}',
        },
        validate(list) {
          if (list.length > 5) {
            window.__bpCMS.alerts.error(`Max of 5 tiles only.`);
          }
        },
        fields: [
          {
            label: 'name',
            name: 'name',
            component: 'text',
            defaultValue: 'Food & wine',
          },
          {
            label: 'Quote',
            name: 'quote',
            component: 'textarea',
            defaultValue: 'This is great coffee',
          },
          {
            name: 'image',
            label: 'Logo',
            component: 'image',
            // parse: (image) => image,
          },
        ],
        defaultValue: [
          {
            name: 'food network',
            quote:
              'IF YOU’RE LOOKING FOR COFFEE THAT ACTUALLY TASTES LIKE COFFEE, THIS ORGANIC COLD BREW FROM WANDERING BEAR IS A MUST.',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1418/5086/files/Food_Network.svg?v=1654560431',
            },
          },
          {
            quote: 'THIS IS THE ONLY BRAND I TRUST FOR AT-HOME COFFEE.',
            name: 'Food & wine',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1418/5086/files/Vector.svg?v=1654560611',
            },
          },
        ],
      },
    ],
  };
};
