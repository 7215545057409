import { Box, Flex } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { Accordions, Accordion, Link } from '@snippets';

import { themed } from './MobileMenu.theme';

export const MobileMenu = themed(({ theme, ...props }) => {
  const settings = useSettings();
  const menu = settings?.footer?.menu;

  return (
    <Box data-comp={MobileMenu.displayName} {...props} sx={theme.grid}>
      <Accordions textColor="white" borderColor="green" iconColor="white">
        {menu?.map((menuItem, index) => (
          <Accordion key={index}>
            <Accordion.Header>{menuItem.name}</Accordion.Header>

            <Accordion.Body>
              {menuItem.subMenuItems?.map((subMenuItem, index) => {
                return (
                  <Flex key={index} as="li">
                    <Link
                      href={subMenuItem.cta?.url}
                      sx={{
                        variant: 'text.sm',
                        width: 'fit-content',
                        position: 'relative',
                        ':after': {
                          content: '""',
                          position: 'absolute',
                          top: '100%',
                          left: 0,
                          width: '100%',
                          maxWidth: 0,
                          height: '1px',
                          bg: 'currentColor',
                          transition: 'max-width 0.2s ease-in-out',
                        },
                        ':hover:after': {
                          maxWidth: '100%',
                        },
                      }}
                    >
                      {subMenuItem.cta?.text}
                    </Link>
                  </Flex>
                );
              })}
            </Accordion.Body>
          </Accordion>
        ))}
      </Accordions>
    </Box>
  );
});

MobileMenu.displayName = 'MobileMenu';
