import { Box, Container } from 'theme-ui';

import { Markdown, Section } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './ProductBanner.Schema';

import { themed } from './ProductBanner.theme';
import { Media } from './Media';
import { Banner } from './Banner';
import { Content } from './Content';

export const ProductBanner = withInView(
  themed(({ theme, cms }) => {
    const { media, content, section, heading, banners, featured, hideBanner } =
      cms;

    return (
      <Section section={section}>
        {/* Regular slider for hero media and contained content */}
        <Container data-comp={ProductBanner.displayName} sx={theme.wrapper}>
          <Container sx={theme.container}>
            <Box
              sx={{
                width: '100%',
                margin: 'auto',
                position: 'absolute',
                top: 0,
                left: 0,
                transform: 'translateY(-40px)',

                '@media only screen and (min-width: 44em)': {
                  transform: 'translateY(-50px)',
                  padding: ' 0 2%',
                },
                '@media only screen and (min-width: 64em)': {
                  transform: 'translateY(-55px)',
                  padding: ' 0 9%',
                },
              }}
            >
              <Markdown
                sx={{
                  textAlign: 'center',
                }}
                textSx={{
                  fontSize: ['58px', '68px', '78px', '108px'],
                  lineHeight: ['78px', '80px', '100px', '115px'],
                  fontFamily: 'gothicCondensed',
                  fontWeight: 800,
                  mb: 0,
                }}
              >
                {heading}
              </Markdown>
            </Box>
            <Box
              sx={{
                position: 'relative',
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
                px: [null, null, '5rem', '5rem'],
                mt: ['7rem', '4rem', '10rem'],
              }}
            >
              <Media media={media} />

              <Content features={featured} />
            </Box>
          </Container>
          {!hideBanner && <Banner banners={banners} />}
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

ProductBanner.displayName = 'ProductBanner';
ProductBanner.Schema = Schema;
