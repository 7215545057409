import { Box, Flex, Heading, Paragraph } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { useSearch, useSearchResults } from '@hooks';

import { themed } from './Suggestions.theme';

export const Suggestions = themed(({ theme, ...props }) => {
  const settings = useSettings();
  const [{}, { setRawTerm }] = useSearch();
  const [{ results }, {}] = useSearchResults();

  return settings?.search?.suggested?.length ? (
    <Flex
      data-comp={Suggestions.displayName}
      {...props}
      sx={results ? theme.hidden : theme.visible}
    >
      <Heading sx={theme.heading}>Top Search Terms</Heading>

      <Box data-comp="SearchSuggestionsTerms">
        {settings?.search?.suggested?.map(({ label }, index) => (
          <Paragraph
            key={label + index}
            onClick={() => setRawTerm(label)}
            sx={theme.term}
          >
            {label}
          </Paragraph>
        ))}
      </Box>
    </Flex>
  ) : null;
});

Suggestions.displayName = 'Suggestions';
