export default {
  label: 'Search',
  name: 'search',
  component: 'group',
  itemProps: {
    key: '{{item.handle}}',
    label: '{{item.label}}',
  },
  fields: [
    {
      label: 'Suggested Terms',
      name: 'suggested',
      component: 'group-list',
      itemProps: {
        key: '{{item.handle}}',
        label: '{{item.label}}',
      },
      fields: [
        {
          label: 'Term',
          name: 'label',
          component: 'text',
        },
      ],
    },
    {
      name: 'showProducts',
      label: 'Show Products',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      name: 'showCollections',
      label: 'Show Collections',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
  ],
  defaultValue: {
    suggested: [{ label: 'Black' }, { label: 'Micro' }],
    showProducts: true,
    showCollections: true,
  },
};
