import { swatches } from './swatches';

export const groups = {
  label: 'Groups',
  name: 'groups',
  component: 'group-list',
  itemProps: {
    label: '{{item.handle}}' || 'New Group',
  },
  defaultItem: {
    handle: 'cold-brew-on-tap-96-oz',
  },
  fields: [
    {
      label: 'handle',
      name: 'handle',
      description:
        'common handle for the group ie: cold-brew-on-tap-96-oz or  cold-brew-on-tap-128-oz',
      component: 'text',
    },
    swatches,
  ],
  defaultValue: {
    handle: 'cold-brew-on-tap-96-oz',
    swatches: [
      {
        name: 'Straight Black',
        color: '#290207',
        product: {
          handle: 'extra-strong-cold-brew-on-tap-96oz-straight-black',
          title: 'Cold Brew On Tap (96 oz) - Straight Black',
        },
      },
      {
        name: 'Vanilla',
        color: '#007A99',
        product: {
          handle: 'extra-strong-cold-brew-on-tap-96oz-vanilla',
          title: 'Cold Brew On Tap (96 oz) - vanilla',
        },
      },
      {
        name: 'Caramel',
        color: '#f9de58',
        product: {
          handle: 'cold-brew-on-tap-96-oz-caramel',
          title: 'Cold Brew On Tap (96 oz) - Caramel',
        },
      },
      {
        name: 'Mocha',
        color: '#a93935',
        product: {
          handle: 'cold-brew-on-tap-96oz-mocha',
          title: 'Cold Brew On Tap (96 oz) - Mocha',
        },
      },
      {
        name: 'Hazelnut',
        color: '#E87A3E',
        product: {
          handle: 'extra-strong-cold-brew-on-tap-96oz-hazelnut',
          title: 'Cold Brew On Tap (96 oz) - Hazelnut',
        },
      },
      {
        name: 'Decaf Black',
        color: '#fff',
        product: {
          handle: 'cold-brew-on-tap-96-oz-decaf-black',
          title: 'Cold Brew On Tap (96 oz) - Decaf Black',
        },
      },
      {
        name: 'Dirty Vanilla Chai',
        color: '#8785A2',
        product: {
          handle: 'cold-brew-on-tap-96-oz-dirty-vanilla-chai',
          title: 'Cold Brew On Tap (96 oz) - Dirty Vanilla Chai',
        },
      },
      {
        name: 'Pumpkin Space',
        color: '#BD472A',
        product: {
          handle: 'cold-brew-on-tap-96-oz-pumpkin-spice',
          title: 'Cold Brew On Tap (96 oz) - Pumpkin Spice',
        },
      },
    ],
  },
};
