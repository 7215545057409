import { Flex, Box, Text, Button, Heading } from 'theme-ui';
import { Svg } from '@snippets';
import { themed } from './Steps.theme';

export const Steps = themed(({ theme, heading, superHeading }) => (
  <Flex data-comp={Steps.displayName} sx={theme.container}>
    <Text sx={theme.heading}>{superHeading}</Text>
    <Text sx={theme.superHeading}>{heading}</Text>
  </Flex>
));

Steps.displayName = 'Steps';
