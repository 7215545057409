import { Box } from 'theme-ui';
import PropTypes from 'prop-types';
import { useCartItems } from '@backpackjs/storefront';

import { LineItem } from '../LineItem';
import { themed } from './LineItems.theme';

export const LineItems = themed(
  ({ theme: themes, inSidebar = false, ...props }) => {
    const cartItems = useCartItems({ sortByAddedAt: true });

    const theme = inSidebar ? themes.sidebar : themes.page;

    return (
      <Box
        key={LineItems.displayName}
        data-comp={LineItems.displayName}
        {...props}
        sx={{
          ...props.sx,
          ...theme,
        }}
      >
        {cartItems.map((item, index) => (
          <LineItem
            key={item.id}
            item={item}
            isLast={index === cartItems.length - 1}
            inSidebar={inSidebar}
          />
        ))}
      </Box>
    );
  }
);

LineItems.displayName = 'LineItems';
LineItems.propTypes = {
  inSidebar: PropTypes.bool,
};
