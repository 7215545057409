import { Container, Box, Flex, Paragraph } from 'theme-ui';
import { withInView } from '@utils';
import { Markdown, Picture, Section } from '@snippets';
import { useRouter } from '@backpackjs/storefront';

import { Schema } from './IconsRow.schema';
import { themed } from './IconsRow.theme';

export const IconsRow = withInView(
  themed(({ theme, cms }) => {
    const { section } = cms;
    const haveIcons = cms?.icons?.length > 0;

    if (!haveIcons) return null;

    return (
      <Section id={IconsRow.displayName} section={section}>
        <Box data-comp={IconsRow.displayName}>
          <Container variant="content">
            <Flex variant="flex.column.center.start">
              <Markdown
                data-comp="IconsRowHeading"
                text={cms.heading}
                sx={{
                  fontSize: [9, null, null, 10],
                  lineHeight: [1.33, null, null, 1.28],
                }}
                textAlign="center"
              />

              <Flex
                variant="flex.row.evenly"
                sx={{
                  width: '100%',
                  // mt: 12,
                  flexWrap: ['wrap', 'initial'],
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
              >
                {cms.icons.slice(0, 6).map((icon, index) => {
                  const router = useRouter();
                  const { link, src, width } = icon;

                  return (
                    <Box
                      key={`single-icon-${index}`}
                      onClick={() => {
                        if (link?.url) {
                          router.push(link.url);
                        }
                      }}
                      sx={{
                        minWidth: ['100%', '144px'],
                        maxWidth: ['100%', '144px'],
                        px: 5,
                      }}
                    >
                      <Picture
                        alt={link?.text}
                        images={[{ src, width: '50px', ratio: 1 }]}
                        imageSx={{
                          objectFit: 'inital',
                        }}
                        sx={{
                          maxWidth: '300px',
                          width: width || 'auto',
                          mx: 'auto',
                          maxHeight: ['100px', '140px'],
                        }}
                      />

                      {link?.text ? (
                        <Paragraph
                          variant="label3"
                          sx={{
                            mt: 8,
                            textAlign: 'center',
                          }}
                        >
                          {link?.text}
                        </Paragraph>
                      ) : null}
                    </Box>
                  );
                })}
              </Flex>
            </Flex>
          </Container>
        </Box>
      </Section>
    );
  }),
  { triggerOnce: true }
);

IconsRow.displayName = 'IconsRow';
IconsRow.Schema = Schema;
