import PropTypes from 'prop-types';
import { Input, Paragraph, Flex } from 'theme-ui';
import {
  useCartUpdateItem,
  useCartRemoveItem,
  useCartUpdatedAt,
} from '@backpackjs/storefront';

import { Svg } from '@snippets';

import { themed } from './Quantity.theme';

export const Quantity = themed(
  ({ theme: themes, inSidebar, id, quantity, ...props }) => {
    const { cartUpdateItem, ...updateStatus } = useCartUpdateItem({
      autoReset: 100,
    });
    const { cartRemoveItem, ...removeStatus } = useCartRemoveItem({
      autoReset: 100,
    });
    const cartUpdatedAt = useCartUpdatedAt();

    // apply the sidebar or cart page context
    const theme = inSidebar ? themes.sidebar : themes.page;

    return !id ? (
      'Loading...'
    ) : (
      <Flex data-comp={Quantity.displayName} {...props} sx={theme.quantity}>
        <Flex sx={theme.quantity.item}>
          <Svg
            data-comp="QuantityReduce"
            viewBox="0 0 48 48"
            alt="Reduce line item quantity"
            src="/svgs/minus.svg#minus"
            onClick={async (event) => {
              const prevQuantity = quantity - 1;
              if (prevQuantity) {
                await cartUpdateItem({ lineId: id, quantity: prevQuantity });
              } else {
                await cartRemoveItem({ lineId: id });
              }
            }}
            sx={theme.quantity.item.icon}
          />
        </Flex>

        {/* changing key on update ensures re-renders on changes */}
        <Paragraph
          key={cartUpdatedAt}
          as={Input}
          type="number"
          min="-1"
          max="10"
          name="quantity"
          defaultValue={quantity}
          onBlur={async (event) => {
            const typedQuantity = parseInt(event.target.value);
            if (typedQuantity) {
              await cartUpdateItem({ lineId: id, quantity: typedQuantity });
            } else {
              await cartRemoveItem({ lineId: id });
            }
          }}
          sx={theme.quantity.item.input}
        />

        {/* Add item */}
        <Flex sx={theme.quantity.item}>
          <Svg
            data-comp="QuantityIncrease"
            viewBox="0 0 48 48"
            alt="Increase line item quantity"
            src="/svgs/plus.svg#plus"
            onClick={async () => {
              await cartUpdateItem({ lineId: id, quantity: quantity + 1 });
            }}
            sx={theme.quantity.item.icon}
          />
        </Flex>
      </Flex>
    );
  }
);

Quantity.displayName = 'Quantity';
Quantity.propTypes = {
  id: PropTypes.string.isRequired,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};
