import { useState } from 'react';
import { Container, Box } from 'theme-ui';

import { withInView } from '@utils';
import { Section } from '@snippets';

import { Schema } from './ProductsSlider.schema';
import { themed } from './ProductsSlider.theme';
import { Header } from './Header';
import { Slider } from './Slider';

export const ProductsSlider = withInView(
  themed(({ theme, cms }) => {
    const { groups, content, section } = cms;
    const [activeIndex, setActiveIndex] = useState(0);

    return (
      <Section section={section}>
        <Container data-comp={ProductsSlider.displayName} sx={theme.wrapper}>
          <Box sx={theme.header}>
            <Header
              activeIndex={activeIndex}
              groups={groups}
              content={content}
              setActiveIndex={setActiveIndex}
            />
          </Box>

          <Box sx={theme.slider}>
            <Slider
              groups={groups}
              activeIndex={activeIndex}
              ctaStyle={content?.ctaStyle}
            />
          </Box>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

ProductsSlider.displayName = 'ProductsSlider';
ProductsSlider.Schema = Schema;
