import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.container = {
      display: 'flex',
      alignItems: 'start',
      flexDirection: ['column', 'row'],
      mb: [13, 14],
      flex: 1,
      borderBottom: ` 1px solid ${colors.gray.mediumLight}`,
      columnGap: '50px'
    };
    this.metadata = {
      maxWidth: '100%',
    };

    this.title = {
      variant: 'text.h2',
      fontSize: ['30px', '34px'],
      mb: 6
    };

    this.price = {
      text: 'text.h2',
      minHeight: 40,
    };
    this.description = {
      variant: 'lg',
      m: 0,
      p: 0,
      b: 0,
      fontFamily: 'body',
      item: {
        position: 'relative',
        pl: '1.5rem',
        fontSize: ['15px', '18px'],
        pt: '0.5rem',
        lineHeight: ['24px'],
        '&:before ': {
          content: '"*"',
          fontSize: '2.5rem',
          color: '#bd8053',
          position: 'absolute',
          left: 0,
          top: ['1rem'],
        }
      }
    };

  })()
);
