import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      mb: [13, 14],
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    };

    this.subscribe = {
      variant: 'text.base',
      fontSize: 2,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      textDecoration: 'underline',
      mt: 8,
    };
  })()
);
