import { section } from '@settings/common/section';
import { color } from '@settings/common/color';

export const Schema = () => {
  const colorSettings = {
    label: 'Colors',
    name: 'colors',
    component: 'group',
    fields: [
      {
        label: 'Text color',
        name: 'color',
        component: 'select',
        options: color.options,
      },
      {
        label: 'Background color',
        name: 'background',
        component: 'select',
        options: color.options,
      },
    ],
    defaultValue: {
      color: color.value.darkGreen,
      background: color.value.yellow,
    },
  };

  return {
    label: 'Icons Row',
    key: 'IconsRow',
    component: 'group',
    fields: [
      colorSettings,
      {
        ...section,
        defaultValue: {
          bgColor: color.value.yellow,
          desktop: {
            container: 'full-bleed',
            mt: 'none',
            py: 'none',
          },
        },
      },
      {
        name: 'heading',
        component: 'markdown',
        label: 'Heading',
        // defaultValue: 'Name your stress. *Find your relief.*'
      },
      {
        name: 'icons',
        label: 'Icons',
        component: 'group-list',
        defaultItem: {
          label: '{{item.link.text}}',
          src: 'https://cdn.shopify.com/s/files/1/1418/5086/files/buzzfeed-icon.svg?v=1652761984',
        },
        itemProps: {
          key: '{{item.id}}',
          label: '{{item.link.text}}',
        },
        fields: [
          {
            label: 'Image',
            name: 'src',
            component: 'image',
            description: 'Max width 145 pixels',
          },
          {
            label: 'Width',
            name: 'width',
            component: 'text',
            description: 'e.g. 145px',
          },
        ],
        defaultValue: [
          {
            src: 'https://cdn.shopify.com/s/files/1/1418/5086/files/buzzfeed-icon.svg?v=1652761984',
            width: '145px',
          },
          {
            src: 'https://cdn.shopify.com/s/files/1/1418/5086/files/food-network.svg?v=1652762502',
            width: '56px',
          },
          {
            src: 'https://cdn.shopify.com/s/files/1/1418/5086/files/food-and-wine.svg?v=1652762976',
            width: '145px',
          },
        ],
      },
    ],
  };
};
