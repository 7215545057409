import { Flex, Box, Heading } from 'theme-ui';

import { Swatch } from '@snippets';

import { themed } from './Swatches.theme';

export const Swatches = themed(
  ({ theme, swatches, swatchTitle, handleSelect }) => {
    return (
      <Flex data-comp={Swatches.displayName} sx={theme.container}>
        <Heading variant="text.h3" sx={{ my: [4, 8] }}>
          {swatchTitle}
        </Heading>
        <Flex
          sx={{ flexWrap: 'wrap', width: '100%', justifyContent: 'center' }}
        >
          {swatches.map(({ color, title, isSelected, id }) => (
            <Box key={id} sx={{ mx: 5 }}>
              <Swatch
                color={color}
                colorName={title || id}
                handleClick={handleSelect}
                size={2}
                isSelected={isSelected}
              />
            </Box>
          ))}
        </Flex>
      </Flex>
    );
  }
);

Swatches.displayName = 'Swatches';
