import { Container, Flex, Grid, Heading, Image } from 'theme-ui';
import { withInView } from '@utils';
import { Section } from '@snippets';

import { Schema } from './Reviews.schema';
import { themed } from './Reviews.theme';
import { Content } from './Content';

export const Reviews = withInView(
  themed(({ theme, cms }) => {
    const { section, headingSettings, curveEnabled, reviews } = cms;

    const { heading, image } = headingSettings;

    return (
      <Section
        section={section}
        sx={{
          position: 'relative',
          ...(curveEnabled
            ? {
                ':before': {
                  content: '""',
                  position: 'absolute',
                  height: '2.75rem',
                  top: '-2.75rem',
                  backgroundImage: `url('/svgs/green-border-top.svg')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  width: '100vw',
                },
              }
            : null),
        }}
      >
        <Container data-comp={Reviews.displayName}>
          <Flex variant="flex.column.center">
            {headingSettings && heading && (
              <Heading
                as="h3"
                sx={{
                  fontFamily: 'gtPressuraBold',
                  fontSize: [9, null, 12],
                  lineHeight: 1.5,
                  textAlign: 'center',
                  mb: !image ? 10 : 0,
                }}
              >
                {heading}
              </Heading>
            )}

            {image && (
              <Image
                src={
                  image.src ||
                  'https://cdn.shopify.com/s/files/1/1418/5086/files/amazon-yellow.svg?v=1654566966'
                }
                alt=""
                sx={{ mt: 6, mb: 12 }}
              />
            )}
          </Flex>

          {reviews ? (
            <Grid
              sx={{
                px: [10, 11, 11, 12],
                maxWidth: '90em', // 1440px
                mx: 'auto',
                gridTemplateColumns: ['1fr', null, '1fr 1fr 1fr 1fr'],
                columnGap: 10,
              }}
            >
              {reviews?.map((review, index) => (
                <Content key={`review-${index}`} review={review} />
              ))}
            </Grid>
          ) : null}
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

Reviews.displayName = 'Reviews';
Reviews.Schema = Schema;
