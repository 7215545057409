import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.dropdown = {
      border: `1px solid ${colors.black}`,
      height: 'auto'
    };

    this.item = {
      justifyContent: 'start',
      alignItems: 'center',
      width: '100%',
      cursor: 'pointer',
      color: 'text',
      height: 'inherit',
      px: 8,
      py: 6,
      '&:hover': {
        bg: 'whitesmoke',
      },

      icon: {
        width: [18, 22],
      },

      name: {
        fontSize: '15px',
        textTransform: 'capitalize',
        fontWeight: 700,
        lineHeight: 'normal'
      },
    };
  })()
);
