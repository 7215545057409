import { create } from '@theme/create';

export const themed = create(
  new (function () {
    (this.sidebar = {
      price: {
        variant: 'flex.row.between.center',
        mb: 4,

        title: {
          variant: 'text.sm',
        },

        amount: {
          variant: 'text.sm',
        },
      },
    }),
      // cart empty — rendered in /cart
      (this.page = {
        price: {
          ...this.sidebar.price,

          title: {
            variant: 'text.sm-base',
          },

          amount: {
            variant: 'text.sm-base',
          },
        },
      });
  })()
);
