import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.container = {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      alignItems: 'center',
      flexDirection: ['column', 'row'],
      mb: [13, 14],
      flex: 1,
      backgroundColor: colors.darkCream,
      p: 25,
    };
    this.rightBlock = {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // px: [5, 3],
    };
  })()
);
