import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      pb: 7,
      pt: 8,
      color: 'white',
      bg: 'black',
    };

    this.content = {
      variant: 'layout.content',
      px: [10, 12, 14],
    };
  })()
);
