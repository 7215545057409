import { Fragment } from 'react';
import { Flex } from 'theme-ui';

import { themed } from './Content.theme';

export const Content = themed(({ theme, children, ...props }) => {
  return (
    <Flex data-comp={Content.displayName} {...props} sx={theme.content}>
      <>{children}</>
    </Flex>
  );
});

Content.displayName = 'Content';
