import { space as themeSpace } from '@theme/theme.space';

// can be used in any select field as its options

export const space = {
  options: themeSpace.map((value, index) => ({
    label: `${value}px`,
    value: index,
  })),
  value: Object.keys(themeSpace).reduce((obj, key) => {
    obj[key] = parseInt(key);
    return obj;
  }, {}),
};

export const margin = {
  options: [
    { label: 'None', value: 'none' },
    { label: 'Small', value: 's' },
    { label: 'Medium', value: 'm' },
    { label: 'Large', value: 'l' },
    { label: 'XLarge', value: 'xl' },
  ],
  value: {
    none: [0, 0, 0, 0],
    s: [8, 11, 12, 14], // [16px,24px,32px,40px],
    m: [12, 14, 15, 16], // [32px,40px,48px,56px]
    l: [15, 16, 17, 18], // [48px,56px,64px,72px]
    xl: [17, 18, '80px', '88px'], // [64px,72px,80px,88px]
  },
};

export const padding = {
  options: [
    { label: 'None', value: 'none' },
    { label: 'Small', value: 's' },
    { label: 'Medium', value: 'm' },
    { label: 'Large', value: 'l' },
    { label: 'XLarge', value: 'xl' },
  ],
  value: {
    none: [0, 0, 0, 0],
    s: [8, 11, 12, 14], // [16px,24px,32px,40px],
    m: [12, 14, 15, 16], // [32px,40px,48px,56px]
    l: [15, 16, 17, 18], // [48px,56px,64px,72px]
    xl: [17, 18, '80px', '88px'], // [64px,72px,80px,88px]
  },
};
