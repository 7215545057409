import { section } from '@settings/common/section';
import { align } from '@settings/common/align';
import { color } from '@settings/common/color';
import { position } from '@settings/common/position';
import { button } from '@settings/common/button';

export function Schema({ article, blog, collection }) {
  if (article || blog || collection) return null;

  // const randomImage = images[Math.floor(Math.random() * images.length)];

  return {
    label: 'Hero Main',
    key: 'hero-main',
    fields: [
      {
        label: 'Image Settings',
        name: 'image',
        description: 'Image, position, placement, aspect ratio',
        component: 'group',
        fields: [
          {
            label: 'Alt',
            name: 'alt',
            component: 'text',
            description: 'Brief description of image',
          },
          {
            name: 'image',
            label: 'Image (desktop)',
            component: 'image',
            parse: (image) => image,
          },
          {
            name: 'position',
            component: 'select',
            label: 'Image position (mobile)',
            options: position.object,
          },
          {
            label: 'Placement (desktop)',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'location',
            options: [
              {
                value: 'left',
                label: 'Left',
              },
              {
                value: 'right',
                label: 'Right',
              },
            ],
          },
          {
            label: 'Aspect Ratio (desktop)',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'ratio',
            options: [
              {
                value: '80%',
                label: '5:4',
              },
              {
                value: '100%',
                label: '1:1',
              },
              {
                value: '120%',
                label: '5:6',
              },
            ],
          },
          {
            label: 'Image (mobile)',
            name: 'imageMob',
            component: 'image',
            parse: (image) => image,
          },
          {
            name: 'positionMob',
            component: 'select',
            label: 'Image position (desktop)',
            options: position.object,
          },
          {
            label: 'Placement (mobile)',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'locationMob',
            options: [
              {
                value: 'top',
                label: 'Top',
              },
              {
                value: 'bottom',
                label: 'Bottom',
              },
            ],
          },
          {
            label: 'Aspect Ratio (mobile)',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'ratioMob',
            options: [
              {
                value: '80%',
                label: '5:4',
              },
              {
                value: '100%',
                label: '1:1',
              },
              {
                value: '120%',
                label: '5:6',
              },
            ],
          },
        ],
        defaultValue: {
          location: 'left',
          locationMob: 'top',
          // ratio: '100%',
          // ratioMob: '100%',
          alt: `Half Media image`,
          image: {
            src: '//cdn.shopify.com/s/files/1/1418/5086/files/hero-main_300x_crop_center.jpg?v=1652845957',
          },
          ratio: '80%',
          position: 'center center',
          imageMob: {
            src: '//cdn.shopify.com/s/files/1/1418/5086/files/hero-main-mobile_300x_crop_center.jpg?v=1652845983',
          },
          ratioMob: '100%',
          positionDt: 'center center',
        },
      },
      {
        label: 'Content Settings',
        name: 'content',
        description: 'Heading, superheading, body, link, content alignment',
        component: 'group',
        fields: [
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'Body',
            name: 'body',
            component: 'markdown',
          },
          {
            label: 'Show Arrow',
            name: 'showArrow',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: false,
          },
          {
            name: 'imageLogo',
            label: 'Image',
            component: 'image',
            parse: (image) => image,
          },
          {
            label: 'Link',
            name: 'cta',
            component: 'link',
          },
          {
            name: 'ctaStyle',
            label: 'Link Style',
            component: 'select',
            options: button.options,
          },
          {
            label: 'Content Alignment (desktop)',
            name: 'alignment',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
          },
          {
            label: 'Content Alignment (mobile)',
            name: 'alignmentMob',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
          },
        ],
        defaultValue: {
          heading: '“THIS IS THE ONLY BRAND I TRUST FOR AT‑HOME COLD BREW”',
          body: "Wandering Bear uses more organic coffee to make their cold brew than most others. The result? An extra strong cup of cold brew made so it doesn't get watery with ice. Every cup is bold, full-bodied and chocolaty. Plus, you get 150 mg of caffeine with each serving. Giddy up. * here * here * here",
          cta: {
            text: 'Learn More',
            url: '#',
          },
          ctaStyle: button.value.secondary,
          alignment: align.value.left,
          alignmentMob: align.value.center,
          showArrow: false,
        },
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.darkGreen,
          color: color.value.white,
          desktop: {
            container: 'full-bleed',
            mt: 'none',
            py: 'none',
          },
          mobile: {
            container: 'full-bleed',
            mt: 'none',
            py: 'none',
          },
        },
      },
    ],
  };
}
