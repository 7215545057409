export default {
  label: 'Footer Settings',
  name: 'footer',
  component: 'group',
  fields: [
    {
      label: 'Email Marketing',
      name: 'emailSignup',
      component: 'group',
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'Subtext',
          name: 'subtext',
          component: 'textarea',
        },
      ],
      defaultValue: {
        heading: 'Sign up for our newsletter',
        subtext: '',
      },
    },
    {
      label: 'Footer Menu',
      name: 'menu',
      component: 'group-list',
      itemProps: {
        label: '{{item.name}}',
      },
      defaultItem: {
        name: 'Shop',
        subMenuItems: [
          {
            cta: {
              text: 'Accessories',
              url: '/collections/all',
            },
          },
          {
            cta: {
              text: 'Bundles',
              url: '/collections/bundles',
            },
          },
          {
            cta: {
              text: 'Shop All',
              url: '/collections/all',
            },
          },
        ],
      },
      fields: [
        {
          label: 'Menu Item Name',
          name: 'name',
          component: 'text',
        },
        {
          label: 'Sub Menu',
          name: 'subMenuItems',
          component: 'group-list',
          itemProps: {
            label: '{{item.cta.text}}',
          },
          defaultItem: {
            cta: {
              text: 'Shop All',
              url: '/collections/all',
            },
          },
          fields: [
            {
              label: 'Link',
              name: 'cta',
              component: 'link',
            },
          ],
        },
      ],
      defaultValue: [
        {
          name: 'Shop',
          subMenuItems: [
            {
              cta: {
                text: 'Accessories',
                url: '/collections/all',
              },
            },
            {
              cta: {
                text: 'Bundles',
                url: '/collections/bundles',
              },
            },
            {
              cta: {
                text: 'Shop All',
                url: '/collections/all',
              },
            },
          ],
        },
        {
          name: 'Company',
          subMenuItems: [
            {
              cta: {
                text: 'About Us',
                url: '/pages/about-us',
              },
            },
            {
              cta: {
                text: 'Rewards',
                url: '/pages/rewards',
              },
            },
            {
              cta: {
                text: 'Blog',
                url: '/blogs/blog',
              },
            },
          ],
        },
        {
          name: 'Contact',
          subMenuItems: [
            {
              cta: {
                text: 'Contact Us',
                url: '/pages/contact-us',
              },
            },
            {
              cta: {
                text: 'Return Policy',
                url: '/pages/return-policy',
              },
            },
            {
              cta: {
                text: 'Store Locator',
                url: '/pages/store-locator',
              },
            },
          ],
        },
      ],
    },
    {
      label: 'Legal Links',
      name: 'legalLinks',
      component: 'group-list',
      itemProps: {
        label: '{{item.cta.text}}',
      },
      defaultItem: {
        cta: {
          text: 'Privacy Policy',
          url: '/pages/privacy-policy',
        },
      },
      fields: [
        {
          label: 'Link',
          name: 'cta',
          component: 'link',
        },
      ],
      defaultValue: [
        {
          cta: {
            text: 'Privacy Policy',
            url: '/pages/privacy-policy',
          },
        },
        {
          cta: {
            text: 'Terms & Conditions',
            url: '/pages/terms-conditions',
          },
        },
      ],
    },
    {
      label: 'Site copyright notice',
      name: 'copyrightNotice',
      component: 'text',
      defaultValue: 'All Rights Reserved',
    },
  ],
};
