import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.container = {
      flexDirection: 'column',
      rowGap: '16px',
      mt: [12]
    };
    this.wrapper = {
    };

    this.box = {
      display: 'flex',
      alignItems: 'left-start',
      flexDirection: 'column',
      p: '8px',
      flex: 1,
      position: 'relative',
      width: ['100%', '16rem'],
      color: colors.black,
      cursor: 'pointer',
      // p: ['0.6563rem 1.5rem', '1.5rem'],
    };

    this.title = {
      fontSize: ['15px', '16px'],
      fontWeight: 700,
      textTransform: 'capitalize',
      mb: '5px'
    };
    this.subtitle = {
      variant: 'text.base',
      '@media only screen and (max-width: 479px)': {
        variant: 'text.sm',
      },
    };
  })()
);
