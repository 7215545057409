import { themed } from './Media.theme';
import { Picture } from '../../../snippets';
import { Image } from '../Image';
import { Video } from '../Video';

export const Media = themed(({ theme, media }) => {
  const { mediaType, image, video } = media;
  if (!media) return null;

  return (
    <>
      {mediaType === 'image' ? (
        <Image image={image} />
      ) : (
        <Video video={video} poster={image} />
      )}
      {media?.mediaType === 'image' && !!media?.image?.roundImage && (
        <Picture
          alt={image?.alt}
          images={[
            {
              src: image?.roundImage?.src,
              width: 768,
              ratio: 1,
            },
          ]}
          sx={theme.roundImage}
        />
      )}
    </>
  );
});

Media.displayName = 'Media';
