import { Box, Container, Link } from 'theme-ui';

import { Section } from '@snippets';
import { withInView } from '@utils';

import { Arrow } from './Arrow';
import { Schema } from './TwoColumn.schema';
import { themed } from './TwoColumn.theme';

// The arrows appear in two different places to make it easier to position
// based on whether there's subtext or not
function Column({ theme, column, index }) {
  return (
    <Box sx={{ ...theme.column({ column, index }) }}>
      <div sx={{ ...theme.copy({ index }) }}>
        <div sx={{ ...theme.text }}>
          {index ? <Arrow /> : null}
          {column.text}
        </div>

        <div sx={{ ...theme.subtext }}>{column.subtext}</div>
      </div>

      <div sx={{ ...theme.image({ index }) }}>
        {!index && <Arrow />}
        <img src={column.image?.src} />
      </div>
    </Box>
  );
}

export const TwoColumn = withInView(
  themed(({ theme, cms }) => {
    const { columns } = cms;

    return (
      <Section>
        <Container
          data-comp={TwoColumn.displayName}
          sx={{ ...theme.container({ cms }) }}
        >
          <div sx={{ ...theme.columnContainer({ cms }) }}>
            <Column theme={theme} column={columns[0]} index={0} />
            <Column theme={theme} column={columns[1]} index={1} />
          </div>
          <Link
            href={cms?.cta?.url}
            variant="buttons.outlineSecondary"
            sx={{ ...theme.cta }}
          >
            <span>{cms?.cta?.text}</span>
          </Link>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

TwoColumn.displayName = 'TwoColumn';
TwoColumn.Schema = Schema;
