import { sectionWithHeight } from '@settings/common/section';
import { colors } from '@theme/theme.colors';

export const Schema = () => ({
  label: 'Product Banner',
  key: 'product-banner',
  fields: [
    {
      name: 'heading',
      component: 'markdown',
      label: 'Heading',
      defaultValue: '# Can you point us to the nearest fridge?',
      description:
        'Ensure heading is "h1" by starting with "# " e.g. "# Shop All". Text below will become large and bold to indicate "h1".',
    },
    {
      label: 'Media settings',
      name: 'media',
      description:
        'Select Media Type (image - video), position, placement, aspect ratio',
      component: 'group',
      fields: [
        {
          label: 'Video settings',
          name: 'video',
          description: 'Video, position, placement, aspect ratio',
          component: 'group',
          fields: [
            {
              label: 'Enable Autoplay',
              name: 'autoplay',
              description:
                'Enable autoplay for video, you need to refresh page after change',
              component: 'toggle',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
              defaultValue: true,
            },
            {
              label: 'Title',
              name: 'title',
              component: 'text',
              description: 'Title of the video',
            },
            {
              label: 'Video Source (mobile)',
              name: 'videoSrcMobile',
              component: 'text',
              description:
                'Select between a local video or a link to a video (.mp4) \n if you want to use a local video, upload it and let the url here empty',
            },
            {
              name: 'localVideoMobile',
              label: 'Upload Video (mobile)',
              component: 'image',
              // parse: (video) => video,
            },
            {
              label: 'Placement (mobile)',
              component: 'radio-group',
              direction: 'horizontal',
              variant: 'radio',
              name: 'location',
              options: [
                {
                  value: 'top',
                  label: 'Top',
                },
                {
                  value: 'bottom',
                  label: 'Bottom',
                },
              ],
            },
            {
              name: 'videoSrcDesktop',
              label: 'Video (desktop)',
              component: 'text',
              description:
                'Select between a local video or a link to a video (.mp4) \n if you want to use a local video, upload it and let the url here empty',
            },
            {
              name: 'localVideoDesktop',
              label: 'Upload Video (desktop)',
              component: 'image',
              // parse: (video) => video,
            },
            {
              label: 'Placement (desktop)',
              component: 'radio-group',
              direction: 'horizontal',
              variant: 'radio',
              name: 'locationDt',
              options: [
                {
                  value: 'left',
                  label: 'Left',
                },
                {
                  value: 'right',
                  label: 'Right',
                },
              ],
            },
          ],
          defaultValue: {
            autoplay: true,
            location: 'top',
            locationDt: 'left',
            title: `Half media video`,
            position: 'center center',
            positionDt: 'center center',
            localVideoMobile: {
              src: 'https://cdn.shopify.com/s/files/1/1418/5086/files/32_oz.mp4?v=1596745362',
            },
            localVideoDesktop: {
              src: 'https://cdn.shopify.com/s/files/1/1418/5086/files/32_oz.mp4?v=1596745362',
            },
            videoSrcMobile:
              'https://cdn.shopify.com/s/files/1/1418/5086/files/32_oz.mp4?v=1596745362',
            videoSrcDesktop:
              'https://cdn.shopify.com/s/files/1/1418/5086/files/32_oz.mp4?v=1596745362',
          },
        },
      ],
    },
    {
      name: 'featured',
      label: 'Featured',
      component: 'group-list',
      itemProps: {
        label: '{{item.title}}',
      },
      defaultItem: {
        title: 'STRONG AS MOTHER',
        answer: 'Strong, but smooth',
      },
      fields: [
        {
          label: 'title',
          name: 'title',
          component: 'text',
        },
        {
          label: 'content',
          name: 'content',
          description:
            'You can use markdown e.g. <u>**bold**</u> or # for heading',
          component: 'markdown',
        },
      ],
      defaultValue: [
        {
          title: 'SIMPLE INGREDIENTS',
          content: ' 100% organic beans. ',
        },
      ],
    },
    {
      name: 'hideBanner',
      label: 'Hide banner',
      component: 'toggle',
      toggleLabels: {
        true: 'Yes',
        false: 'No',
      },
      defaultValue: false,
    },
    {
      ...sectionWithHeight,
      defaultValue: {
        bgColor: colors.cream,
      },
    },
  ],
  defaultValue: [],
});
