import { Paragraph, Flex } from 'theme-ui';
import PropTypes from 'prop-types';
import { useCartEmpty } from '@backpackjs/storefront';

import { Link } from '@snippets';
import { useSidebar } from '@hooks';

import { themed } from './Empty.theme';

export const Empty = themed(({ theme: themes, inSidebar, ...props }) => {
  const cartEmpty = useCartEmpty();
  const [{}, { closeSidebar }] = useSidebar();

  const theme = inSidebar ? themes.sidebar : themes.page;

  return (
    <Flex
      data-comp={Empty.displayName}
      {...props}
      sx={{
        ...props.sx,
        ...theme,
      }}
    >
      <Paragraph sx={theme.text}>Your cart is currently empty.</Paragraph>

      <Link
        href="/collections/all"
        onClick={() => {
          if (!cartEmpty || !inSidebar) return;
          closeSidebar();
        }}
      >
        <Paragraph sx={theme.link}>Shop all</Paragraph>
      </Link>
    </Flex>
  );
});

Empty.displayName = 'Empty';
Empty.propTypes = {
  inSidebar: PropTypes.bool,
};
