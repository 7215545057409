import { useEffect, useRef } from 'react';
import { Container, Heading, Spinner, Box, Flex } from 'theme-ui';
import { Head, useProductByHandle } from '@backpackjs/storefront';

import { Section } from '@snippets';
import { withInView, isBrowser } from '@utils';
import { useSelectedVariant } from '@hooks';

import { Schema } from './ProductReviews.schema';
import { themed } from './ProductReviews.theme';

export const ProductReviews = withInView(
  themed(({ theme, cms }) => {
    const { section, heading } = cms;
    const reviewsRef = useRef(null);
    const [selectedVariant] = useSelectedVariant();

    const { product } = useProductByHandle({
      handle: selectedVariant?.product?.handle || 'fallback',
    });

    const productId = product?.legacyResourceId;
    const setProduct = isBrowser && window.okeWidgetApi?.setProduct;
    const okendoReady = typeof setProduct === 'function';

    useEffect(() => {
      if (!okendoReady) return;
      setProduct(reviewsRef.current, `shopify-${productId || ''}`);
    }, [okendoReady, productId]);

    return (
      <Section section={section}>
        <Head>
          <meta
            name="oke:subscriber_id"
            content={process.env.NEXT_PUBLIC_OKENDO_USER_ID}
          />
          <script
            async
            src="https://d3hw6dc1ow8pp2.cloudfront.net/reviews-widget-plus/js/okendo-reviews.js"
          />
        </Head>

        <Container data-comp={ProductReviews.displayName} sx={theme.container}>
          {heading && <Heading sx={theme.heading}>{heading}</Heading>}
          {/* Widget */}
          <div
            ref={reviewsRef}
            data-oke-widget
            data-oke-reviews-product-id={`shopify-${productId || ''}`}
          />

          {!okendoReady && (
            <Box sx={theme.spinnerWrapper}>
              <Spinner sx={theme.spinner} />
            </Box>
          )}
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

ProductReviews.displayName = 'ProductReviews';
ProductReviews.Schema = Schema;
