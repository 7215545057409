// menu
export default {
  label: 'Account Menu Settings',
  name: 'menu',
  component: 'group',
  fields: [
    {
      name: 'items',
      label: 'Menu Items',
      component: 'group-list',
      itemProps: {
        label: '{{item.text}}',
      },
      fields: [
        {
          name: 'text',
          label: 'Text',
          component: 'text',
        },
        {
          name: 'handle',
          label: 'Handle',
          description:
            'The handle of the account sub-route. Must match a templates/account/ component',
          component: 'text',
        },
      ],
    },
    {
      label: 'Sign Out',
      name: 'withSignOut',
      component: 'toggle',
      toggleLabels: {
        true: 'Yes',
        false: 'No',
      },
      defaultValue: true,
    },
  ],
  defaultValue: {
    items: [
      {
        text: 'Order History',
        handle: 'orders',
      },
      {
        text: 'Edit Profile',
        handle: 'edit-profile',
      },
      {
        text: 'Address Book',
        handle: 'address-book',
      },
      {
        text: 'Payment Methods',
        handle: 'payment-methods',
      },
      {
        text: 'Favorites',
        handle: 'favorites',
      },
    ],
    withSignOut: true,
  },
};
