import { Box } from 'theme-ui';

import { Picture, Link } from '@snippets';

import { TileContent } from './TileContent';
import { themed } from './Tile.theme';

export const Tile = themed(({ theme, item, tile, ...props }) => {
  return (
    <Box data-comp={Tile.displayName} {...props}>
      <Box sx={item.cta?.url ? theme.tileLink : theme.tile}>
        <Link href={item.cta?.url} tabIndex="-1">
          <Picture
            alt={item.alt}
            images={[
              {
                src: item.image?.src,
                width: 768,
                ratio: tile?.imageRatio === 'portrait' ? 0.75 : 1,
              },
              {
                src: item.image?.src,
                width: 700,
                ratio: tile?.imageRatioDt === 'portrait' ? 0.75 : 1,
              },
            ]}
          />
        </Link>

        {tile.overlay === 'dark' && (
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              bg: 'dark',
              opacity: 0.2,
              pointerEvents: 'none',
            }}
          />
        )}

        {tile?.textPosition === 'absolute' && (
          <TileContent item={item} tile={tile} />
        )}
      </Box>

      {tile?.textPosition === 'relative' && (
        <TileContent item={item} tile={tile} />
      )}
    </Box>
  );
});

Tile.displayName = 'Tile';
