import { Box } from 'theme-ui';
import { useCartCount } from '@backpackjs/storefront';

import { Svg } from '@snippets';
import { useSidebar } from '@hooks';

import { themed } from '../Menu.theme';

export const CtaCart = themed(({ theme, ...props }) => {
  const cartCount = useCartCount();
  const [{}, { openCartSidebar }] = useSidebar();

  return (
    <Box
      data-comp={CtaCart.displayName}
      {...props}
      onClick={openCartSidebar}
      sx={{
        ...props.sx,
        ...theme.cta,
      }}
    >
      {cartCount ? (
        <Svg
          alt="Open cart drawer"
          viewBox="0 0 48 48"
          src="/svgs/cta/cart.svg#cart"
          sx={theme.cta.icon}
        />
      ) : (
        <Svg
          alt="Close cart drawer"
          viewBox="0 0 48 48"
          src="/svgs/cta/cart-empty.svg#cart-empty"
          sx={theme.cta.icon}
        />
      )}
    </Box>
  );
});

CtaCart.displayName = 'CtaCart';
