import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.nav = {
      position: 'relative',

      collapsed: {
        height: 'unset',
      },

      expanded: {
        height: 'calc(var(--viewport-height) - var(--header-height))',
      },
    };

    this.item = {
      opacity: 0,
      cursor: 'pointer',
      position: 'relative',
      py: 10,
      borderBottom: (t) => `1px solid ${t.colors.gray}`,

      text: {
        variant: 'label1',
      },

      icon: {
        position: 'absolute',
        right: 0,
        top: '50%',
        width: '24px',
        height: '24px',
        transform: 'translateY(-50%)',
      },
    };
  })()
);
