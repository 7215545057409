import { Box, Heading, Flex, Container, Button } from 'theme-ui';

import { convertAlignToFlex } from '@utils';

import { themed } from './Header.theme';

export const Header = themed(
  ({ theme, activeIndex, groups, content, setActiveIndex }) => {
    const textAlign = content?.textAlign || 'center';
    const isGroups = groups?.length > 1;

    return (
      <Container
        data-comp={Header.displayName}
        sx={{
          ...theme.header,
          justifyContent: [
            'flex-start',
            null,
            null,
            isGroups ? 'space-between' : convertAlignToFlex(textAlign),
          ],
          alignItems: [convertAlignToFlex(textAlign), null, null, 'flex-start'],
        }}
      >
        <Heading
          variant="h2"
          sx={{
            ...theme.heading,
            textAlign: [textAlign, null, null, isGroups ? 'left' : textAlign],
          }}
        >
          {content?.heading}
        </Heading>

        {isGroups && (
          <Box sx={theme.groups}>
            <Flex role="tablist" sx={theme.list}>
              {groups.map(({ name }, index, arr) => {
                const isActive = index === activeIndex;
                return (
                  <Box
                    key={index}
                    sx={{
                      ...theme.listItem,
                      mr: index !== arr.length - 1 ? ['28px', null, '44px'] : 0,
                      button: {
                        ':after': {
                          transform: isActive
                            ? 'translateX(0)'
                            : 'translateX(-101%)',
                        },
                      },
                    }}
                  >
                    <Button
                      role="tab"
                      aria-selected={isActive ? 'true' : 'false'}
                      onClick={() => setActiveIndex(index)}
                      sx={theme.button}
                    >
                      {name}
                    </Button>
                  </Box>
                );
              })}
            </Flex>
          </Box>
        )}
      </Container>
    );
  }
);

Header.displayName = 'Header';
