import { create } from '@theme/create';

// Mobile sub menu
export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'box.fill',
      height: '100%',
      position: 'absolute',
      right: '100%',
      top: 0,
      transform: 'translateX(110%)',
      width: '100%',
      zIndex: 1,
    };

    this.selectedItem = {
      position: 'relative',
      py: 10,
      cursor: 'pointer',
      borderBottom: (t) => `1px solid ${t.colors.text}`,
    };
  })()
);
