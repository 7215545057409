import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.sidebar = {
      variant: 'flex.column.center.center',
      pt: 16,

      text: {
        variant: 'text.base',
      },

      link: {
        variant: 'text.link.2',
        mt: 5,
      },
    };

    // cart empty — rendered in /cart
    this.page = {
      ...this.sidebar,
      border: (t) => [`1px solid ${t.colors.gray}`, null, 0],
      mr: [0, null, '130px'],

      text: {
        variant: 'text.base',
      },

      link: {
        variant: 'text.link.2',
        mt: 5,
      },
    };
  })()
);
