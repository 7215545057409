import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {

    this.container = {
      flexDirection: 'row', justifyContent: 'start', alignItems: 'center', mb: 6
    }
    this.heading = {
      variant: 'text.base',
      fontWeight: 700, textTransform: 'uppercase', my: 6
    }
    this.superHeading = {
      variant: 'text.h4'
    }
  })()
);
