import { Flex, Paragraph, Spinner } from 'theme-ui';

import { Locale, Link } from '@snippets';

import { themed } from './Details.theme';

export const Details = themed(({ theme, fullProduct }) => {
  return (
    <Flex data-comp={Details.displayName} sx={theme.wrapper}>
      {/* Title */}
      <Link
        href={`https://wanderingbearcoffee.com/cart/add?id=${fullProduct?.variants[0]?.legacyResourceId}`}
        tabIndex="-1"
        sx={theme.title}
      >
        {fullProduct?.title}
      </Link>

      {/* Price */}
      <Locale.Product product={fullProduct}>
        <Locale.Price>
          {({ minVariantPrice, maxVariantPrice, isVariablePrice }) => {
            return isVariablePrice ? (
              <Paragraph sx={theme.price}>
                From {minVariantPrice.locale} to {maxVariantPrice.locale}
              </Paragraph>
            ) : (
              <Paragraph sx={theme.price}>{minVariantPrice.locale}</Paragraph>
            );
          }}
        </Locale.Price>

        <Locale.Loader>
          <Spinner height="16px" />
        </Locale.Loader>

        <Locale.Error>{({ errors }) => errors.join(',')}</Locale.Error>
      </Locale.Product>
    </Flex>
  );
});

Details.displayName = 'Details';
