import React from 'react';

import { ShopifyImage } from './ShopifyImage';
import { ShopifyVideoExternal } from './ShopifyVideoExternal';
import { ShopifyVideo } from './ShopifyVideo';

export const ShopifyMedia = ({ media, ...props }) => {
  if (!media)
    throw new Error(
      'ShopifyMedia missing required media prop. A shopify media or [media] must be provided.'
    );

  // maps a shopify media item to a react component
  const mapMediaItemToComponent = (item) => {
    const { mediaContentType } = item;

    switch (mediaContentType) {
      case 'IMAGE': {
        const imageProps = {
          ...props,
          ...item.image,
          alt: item.image.altText,
          src: item.image.originalSrc,
          key: item.image.id,
        };
        delete imageProps.altText;
        delete imageProps.originalSrc;
        return React.createElement(ShopifyImage, imageProps);
      }

      case 'VIDEO': {
        const videoProps = {
          ...props,
          alt: item.alt,
          sources: item.sources,
          key: item.sources[0]?.url || item.alt,
        };

        return React.createElement(ShopifyVideo, videoProps);
      }

      case 'EXTERNAL_VIDEO': {
        const externalVideoProps = {
          ...props,
          ...item,
          alt: item.alt,
          key: item.id || item.alt,
        };
        return React.createElement(ShopifyVideoExternal, externalVideoProps);
      }
      default:
        console.warn(
          'Media element is not valid. Supported media content types: IMAGE, VIDEO, EXTERNAL_VIDEO and MODEL_3D ',
          media
        );
        return null;
    }
  };

  // returns a the map component(s)
  return React.useMemo(
    () =>
      Array.isArray(media)
        ? media.map(mapMediaItemToComponent)
        : mapMediaItemToComponent(media),
    [media]
  );
};
