import { Box } from 'theme-ui';

import { Svg } from '@snippets';
import { useModal } from '@hooks';

import { themed } from './Modal.theme';

export const Modal = themed(({ theme, ...props }) => {
  const [{ modal }, { closeModal }] = useModal();

  return (
    <Box
      as="aside"
      data-comp={Modal.displayName}
      {...props}
      sx={{
        ...(modal ? theme.open : theme.closed),
        ...props.sx,
      }}
    >
      {/* X close modal */}
      <Box
        data-comp={`${Modal.displayName}Close`}
        sx={theme.close}
        onClick={closeModal}
      >
        <Svg
          alt="Close modal"
          src="/svgs/close.svg#close"
          viewBox="0 0 48 48"
          sx={theme.icon}
        />
      </Box>

      <Box
        data-comp={`${Modal.displayName}Content`}
        sx={{
          ...(modal ? theme.contentVisible : theme.contentHidden),
        }}
      >
        {modal}
      </Box>
    </Box>
  );
});

Modal.displayName = 'Modal';
