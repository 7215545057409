import { useState, useEffect } from 'react';
import { Flex, Spinner } from 'theme-ui';

import { themed } from './Content.theme';
import { Swatches } from './Swatches';
import { Variants } from './Variants/Variants';
import { Subscribe } from './Subscribe';
import { AddItemToCart } from './AddItemToCart';
import { Image } from './Image';

export const ContentComponent = themed(
  ({
    theme,
    content,
    product,
    selectedSwatch,
    allSwatches,
    handleSwatchSelect,
    handleSubscriptionSelect,
    selectedSubscription,
  }) => {
    const {
      subscription,
      variants: { variants, heading, boxes },
    } = content;

    const [selectedTitle, setSelectedTitle] = useState(
      selectedSwatch?.title || product?.title.split('-')[1]
    );
    const [selectedVariant, setSelectedVariant] = useState(
      product?.variants[0]
    );
    const [allVariants, setAllVariants] = useState([]);

    const sellingPlans = product?.sellingPlanGroups;
    let subscribeFound;
    if (selectedVariant.title === "2 Box Discount Price") {
      subscribeFound = sellingPlans?.find((element) => element.name === "Subscribe & Save - 2 box");
    } else if (selectedVariant.title === "1 Box") {
      subscribeFound = sellingPlans?.find((element) => element.name === "Subscribe & Save - 1 box"); 
    } else if (selectedVariant.title === "3 Box Discount Price") {
        subscribeFound = sellingPlans?.find((element) => element.name === "Subscribe & Save - 3 box");
    }
    const sellingPlanID = subscribeFound?.sellingPlans[0]?.id;
    const subscriptionDiscount = subscribeFound?.sellingPlans[0]?.priceAdjustments[0]?.adjustmentValue?.adjustmentAmount.amount

    useEffect(() => {
      setSelectedTitle(selectedSwatch?.title || product?.title.split('-')[1]);
    }, [selectedSwatch]);

    useEffect(() => {
      setSelectedVariant(
        product?.variants[boxes.findIndex((variant) => variant?.default)]
      );
    }, [product]);

    useEffect(() => {
      setAllVariants(
        boxes.map((variant) => {
          return variant?.default
            ? { ...variant, isSelected: true }
            : { ...variant, isSelected: false };
        })
      );
    }, [variants]);

    const handleVariantSelect = (currentVariant) => {
      const variantSelected = allVariants?.map((variant, index) =>
        index === currentVariant
          ? {
              ...variant,
              isSelected: !variant.isSelected,
            }
          : {
              ...variant,
              isSelected: false,
            }
      );
      setAllVariants(variantSelected);

      const variant = product?.variants?.filter(
        (vr) => vr.position === currentVariant + 1
      )[0];

      setSelectedVariant(variant);
    };

    const image = !product?.featuredImage ? (
      <Spinner
        sx={{
          width: '60px',
          m: 'auto',
        }}
      />
    ) : (
      <Image image={product?.featuredImage} />
    );
    return (
      <Flex data-comp={ContentComponent.displayName} sx={theme.container}>
        <Flex
          sx={{
            flexDirection: 'column',
            width: ['100%', '50%'],
          }}
        >
          {image}
        </Flex>
        <Flex
          sx={{
            flexDirection: 'column',
            width: ['100%', '50%'],
            px: [5, 3],
            overflow: 'hidden',
          }}
        >
          <Swatches
            swatches={allSwatches}
            swatchTitle={selectedTitle}
            handleSelect={handleSwatchSelect}
          />
          <Variants
            variants={allVariants}
            heading={heading}
            handleSelect={handleVariantSelect}
            productsVariants={product?.variants}
            subscribePrice={selectedSubscription}
            selectedPlanDiscount={subscriptionDiscount}
          />

          <Subscribe
            label={subscription?.label}
            subLabel={subscription?.subLabel}
            handleSelect={handleSubscriptionSelect}
            isSelected={selectedSubscription}
            supplementary={subscription?.additional}
            sellingPlanId={sellingPlanID}
          />

          <AddItemToCart
            selectedSubscription={selectedSubscription}
            selectedPlanID={sellingPlanID}
            selectedPlanDiscount={subscriptionDiscount}
            selectedVariant={selectedVariant}
          />
        </Flex>
      </Flex>
    );
  }
);

ContentComponent.displayName = 'ContentComponent';
