export const bestSellers = {
  label: 'Best Sellers (mobile only)',
  name: 'bestSellers',
  component: 'group',
  fields: [
    {
      label: 'Products Heading',
      name: 'heading',
      component: 'text',
      defaultValue: 'BEST SELLERS',
    },
    {
      name: 'products',
      label: 'Products',
      component: 'group-list',
      itemProps: {
        label:
          '{{item.product.title}}' || '{{item.product.handle}}' || 'Product',
      },
      defaultItem: {
        product: {
          handle: 'extra-strong-cold-brew-on-tap-96oz-straight-black',
          title: 'Cold Brew On Tap (96 oz) - Straight Black',
        },
      },
      fields: [
        {
          name: 'product',
          component: 'productSearch',
          label: 'Product',
        },
      ],
      defaultValue: [],
    },
  ],
  defaultValue: {
    heading: 'Best Sellers',
    products: [],
  },
};
