import { Heading, Flex } from 'theme-ui';

import { Markdown, Link } from '@snippets';
import { convertAlignToFlex } from '@utils';

import { themed } from './Tile.theme';

export const TileContent = themed(({ theme, item, tile = {} }) => {
  const { textAlign, ctaStyle } = tile;
  const textColor = item.color || 'currentColor';

  return (
    <Flex
      data-comp={TileContent.displayName}
      sx={{
        ...(tile?.textPosition === 'relative'
          ? theme.contentRelative
          : theme.contentAbsolute),
        alignItems: convertAlignToFlex(textAlign),
        textAlign,
        color: textColor,
      }}
    >
      <Heading as="h3" sx={theme.heading}>
        {item.heading}
      </Heading>

      <Markdown
        text={item.description}
        textAlign={textAlign}
        textSx={{
          ...theme.body,
          color: textColor,
        }}
      />

      {item.cta?.text && (
        <Link
          href={item.cta.url}
          variant={`buttons.${ctaStyle || 'primary'}`}
          sx={theme.button}
        >
          {item.cta.text}
        </Link>
      )}
    </Flex>
  );
});

TileContent.displayName = 'TileContent';
