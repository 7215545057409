import { Box, Paragraph } from 'theme-ui';

import { SubNav } from '../SubNav';
import { useMenuSidebar } from '../MenuSidebar';
import { themed } from './SubNavSidebar.theme';

/**
 * Renders the sub items of the selected
 * top level menu item in a nested sidebar (mobile)
 */
export const SubNavSidebar = themed(({ theme, selectedItem, ...props }) => {
  const [{}, { toggleNestedSidebar }] = useMenuSidebar();

  return (
    <Box
      data-comp={SubNavSidebar.displayName}
      sx={theme.wrapper}
      onClick={toggleNestedSidebar}
    >
      {/* Active item spacer for the up/down animation */}
      {selectedItem?.link?.text ? (
        <Box data-comp="AccordionNavItemActive" sx={theme.selectedItem}>
          <Paragraph>&nbsp;</Paragraph>
        </Box>
      ) : null}

      {/* Selected item nested links */}
      <SubNav {...selectedItem} layout="vertical" />
    </Box>
  );
});

SubNavSidebar.displayName = 'SubNavSidebar';
