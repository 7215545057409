import { Container, Box } from 'theme-ui';
import ReactPlayer from 'react-player';

import { Link, Section } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './HeroVideo.schema';
import { themed } from './HeroVideo.theme';

export const HeroVideo = withInView(
  themed(({ theme, cms }) => {
    const { video, content, section } = cms;

    return (
      <Section section={section}>
        <Container
          data-comp={HeroVideo.displayName}
          sx={{
            ...theme.media,
            backgroundImage: `url(${video?.firstFrame?.src})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          <ReactPlayer
            url={video?.url}
            playing
            muted
            loop
            width="100%"
            height="100%"
          />

          <Box sx={{ ...theme.content }}>
            <div sx={{ ...theme.heading }}>{content?.heading}</div>

            <div sx={{ ...theme.subheading }}>{content?.subheading}</div>

            {content?.cta?.text && (
              <Link
                variant="buttons.primary"
                href={content?.cta?.url}
                sx={{ ...theme.cta }}
              >
                {content?.cta?.text}
              </Link>
            )}
          </Box>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

HeroVideo.displayName = 'HeroVideo';
HeroVideo.Schema = Schema;
