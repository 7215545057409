import { Container, Text } from 'theme-ui';

import { Accordion, Accordions, Markdown, Section } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './Faq.schema';
import { themed } from './Faq.theme';

export const Faq = withInView(
  themed(({ theme, cms }) => {
    const { section, heading, questions } = cms;

    return (
      <Section section={section}>
        {/* Regular slider for hero media and contained content */}
        <Container data-comp={Faq.displayName} sx={{ pb: 20 }}>
          <Markdown
            sx={{
              textAlign: 'center',
              mb: 10,
              px: ['1rem', null, '10rem', '20rem'],
            }}
            textSx={{
              textAlign: 'center',
              fontSize: ['3.625rem', '6.75rem'],
              lineHeight: ['60px', '104px'],
              fontFamily: 'gothicCondensed',
              fontWeight: 800,
            }}
          >
            {heading}
          </Markdown>
          <Accordions minHeight="70px" sx={theme.accordion}>
            {questions.map((item) => {
              const { question, answer } = item;
              return (
                <Accordion key={question} sx={theme.accordionItem}>
                  <Accordion.Header sx={theme.accordionHeader} plusIcon>
                    <Text sx={theme.top}>{question}</Text>
                  </Accordion.Header>
                  <Accordion.Body
                    sx={{
                      ...theme.accordionBody,
                      pt: 4,
                    }}
                  >
                    <Markdown text={answer} textSx={theme.content} />
                  </Accordion.Body>
                </Accordion>
              );
            })}
          </Accordions>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

Faq.displayName = 'Faq';
Faq.Schema = Schema;
