import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      px: [10, 20],
      maxWidth: '860px',
      margin: '0 auto',
    };

    this.title = {
      variant: 'text.h3',
      mb: 12,
      textAlign: 'center',
    };
    this.accordion = {
      textAlign: 'center',
      border: 'none',
    };

    this.accordionItem = {
      mb: 10,
      border: 'none',
    };
    this.top = {
      whiteSpace: 'initial',
      textAlign: 'center',
      display: 'inline-block',
      paddingLeft: '1rem',
      textTransform: 'uppercase',
      fontFamily: 'gtPressuraBold',
      fontSize: ['22px', '27.5px'],
      fontWeight: 600,
      lineHeight: '109.09%',
    };

    this.content = {
      lineHeight: ['2.5rem', '3.5rem'],
      px: [0, 40],
      fontSize: ['22px', '27.5px'],
      fontWeight: 500,
    };

    this.accordionHeader = {
      px: 10,
      textAlign: 'center',
      ':hover': {
        backgroundColor: 'transparent',
        border: 'none',
      },
    };
    this.accordionBody = {
      px: 10,
      lineHeight: 12,
      p: {
        m: 0,
      },
    };
    this.more = {
      display: 'block',
      variant: 'buttons.plain',
      margin: '0 auto',
      textDecoration: 'underline',
    };
  })()
);
