import Script from 'next/script';
import { Section } from '@snippets';
import { Schema } from './Typeform.schema';

export function Typeform({ cms }) {
  const { section } = cms;

  return (
    <>
      <Script
        type="text/javascript"
        src="https://embed.typeform.com/next/embed.js"
      />

      <Section data-comp={Typeform.displayName} section={section}>
        <div
          data-tf-widget="eyPXHT4g"
          data-tf-iframe-props="title=Free Office Tasting"
          data-tf-medium="snippet"
          data-tf-hidden="utm_source=,utm_medium=xxxxx,utm_campaign=xxxxx,utm_term=xxxxx,utm_content=xxxxx"
          style={{
            width: '100%',
            height: '70vh',
            minHeight: '600px',
            maxHeight: '700px',
          }}
        />
      </Section>
    </>
  );
}

Typeform.displayName = 'Typeform';
Typeform.Schema = Schema;
