export const ingredients = {
  label: 'Ingredients',
  name: 'ingredients',
  component: 'group',
  itemProps: {
    label: '{{item.name}}' || 'New swatch',
  },
  defaultItem: {
    name: 'STRAIGHT BLACK',
  },
  fields: [
    {
      label: 'name',
      name: 'name',
      component: 'text',
    },
    {
      label: 'content',
      name: 'content',
      description: 'You can use markdown e.g. <u>**bold**</u> or # for heading',
      component: 'markdown',
    },
    {
      label: 'icons',
      description: 'Select icons',
      name: 'icons',
      component: 'group-list',
      itemProps: {
        label: '{{item.name}}' || 'New swatch',
      },
      validate: {
        maxItems: 4,
      },
      defaultItem: {
        name: 'Sugar Free',
        image: {
          src: 'https://cdn.shopify.com/s/files/1/1418/5086/files/organicArabic.svg?v=1663763669',
        },
      },
      fields: [
        {
          label: 'name',
          name: 'name',
          component: 'text',
        },
        {
          label: 'Image',
          name: 'image',
          component: 'image',
        },
      ],
    },
    {
      label: 'Image',
      name: 'image',
      component: 'image',
    },
  ],
};
