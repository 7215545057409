import { section } from '@settings/common/section';
import { color } from '@settings/common/color';

export const Schema = () => {
  return {
    label: 'Typeform',
    key: 'typeform',
    fields: [
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'full-bleed',
            mt: 'none',
            py: 'none',
          },
          mobile: {
            container: 'full-bleed',
            mt: 'none',
            py: 'none',
          },
        },
      },
    ],
  };
};
