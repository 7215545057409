import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.container = {
      flexDirection: 'column',
      gridGap: ['1.125rem', '1em'],
    };
    this.wrapper = {
      gridGap: ['1.125rem', '1.5rem'],
      flexDirection: ['column', 'row'],
    };

    this.box = ({ isSelected, showBadge, badge }) => ({
      display: 'flex',
      alignItems: 'left-start',
      flexDirection: 'column',
      mb: [10],
      flex: 1,
      position: 'relative',
      width: ['100%', '16rem'],
      // p: ['0.6563rem 1.5rem', '1.5rem'],
      border: ` 2px solid ${colors.craft}`,
      '& :before': showBadge && {
        width: '7rem',
        backgroundColor: `${colors.green}`,
        color: colors.yellow,
        fontWeight: 700,
        fontSize: 2,
        fontFamily: 'gtPressuraBold',
        textTransform: 'uppercase',
        lineHeight: 1,
        letterSpacing: ' 0.01em',
        whiteSpace: 'nowrap',
        position: 'absolute',
        top: '-1.25rem',
        right: '1.25rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'rotate(4deg)',
        padding: ' 0.5337rem',
        content: `"${badge}"`,
      },
    });

    this.title = {
      variant: 'text.h3',
      '@media only screen and (max-width: 479px)': {
        variant: 'text.h3',
      },
    };
    this.subtitle = {
      variant: 'text.base',
      '@media only screen and (max-width: 479px)': {
        variant: 'text.sm',
      },
    };
  })()
);
