import { section } from '@settings/common/section';
import { align } from '@settings/common/align';
import { color } from '@settings/common/color';

export function Schema({ article, blog, collection }) {
  if (article || blog || collection) return null;

  return {
    label: 'ATC Block Inveterate',
    key: 'atc-block-inveterate',
    fields: [
      {
        label: 'Block Variant',
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        name: 'type',
        options: [
          {
            value: 'primary',
            label: 'Primary',
          },
          {
            value: 'secondary',
            label: 'Secondary',
          },
        ],
        defaultValue: 'primary',
      },
      {
        label: 'Container ID',
        name: 'containerID',
        component: 'text',
      },
      {
        label: 'Text settings',
        name: 'text',
        description: 'Heading,  subheading, ',
        component: 'group',
        fields: [
          {
            label: 'Heading',
            name: 'heading',
            description:
              'You can use markdown e.g. <u>**bold**</u> or # for heading',
            component: 'markdown',
          },
          {
            label: 'Heading Color',
            name: 'headingColor',
            component: 'select',
            options: color.options,
          },
          {
            label: 'Subheading',
            name: 'subheading',
            component: 'text',
          },
          {
            label: 'Subheading Color',
            name: 'subheadingColor',
            component: 'select',
            options: color.options,
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'textAlign',
            description: "Doesn't apply on desktop when group list is inline",
            label: 'Heading alignment',
            options: align.options,
          },
          {
            label: 'Max Width',
            name: 'maxWidth',
            component: 'number',
            description: 'Set max width for heading and subheading',
            defaultValue: 600,
          },
        ],
        defaultValue: {
          heading: '##   Global SUBSCRIBE & SAVE AT LEAST 10% ON EVERY ORDER',
          headingColor: color.value.black,
          subheading:
            'Free shipping always. Pause or cancel anytime. 100% satisfaction guaranteed.',
          subheadingColor: color.value.orange,
          textAlign: align.value.center,
          maxWidth: 600,
        },
      },
      {
        label: 'Content settings',
        name: 'content',
        description:
          'Select variants, customize box, subscribe button, modal content',
        component: 'group',
        fields: [
          {
            name: 'reviews',
            label: 'Reviews',
            component: 'group',
            fields: [
              {
                label: 'Title',
                name: 'title',
                component: 'text',
                defaultValue: '4.8 stars based on 3,933 reviews',
              },
              {
                name: 'starRating',
                component: 'select',
                label: 'Star Rating',
                description: '1 - 5 only',
                options: [
                  { label: 'One', value: 1 },
                  { label: 'Two', value: 2 },
                  { label: 'Three', value: 3 },
                  { label: 'Four', value: 4 },
                  { label: 'Five', value: 5 },
                ],
              },
            ],
            defaultValue: {
              title: '4.8 stars based on 3,933 reviews',
              starRating: 4,
            },
          },
          {
            label: 'Select variants',
            name: 'variants',
            description: 'Select variants, Name, color product',
            component: 'group',
            fields: [
              {
                label: 'Variants',
                name: 'variants',
                component: 'group-list',
                itemProps: {
                  label: '{{item.title}}' || 'new item',
                },
                defaultItem: {
                  title: 'Mocha',
                  color: color.value.red,
                  limited: false,
                  product: {
                    handle: 'cold-brew-on-tap-96-oz-mocha',
                    title: 'Cold Brew On Tap (96 oz) - Mocha',
                  },
                },
                fields: [
                  {
                    label: 'Title',
                    name: 'title',
                    component: 'text',
                  },
                  {
                    label: 'Color',
                    name: 'color',
                    component: 'select',
                    options: color.options,
                  },
                  {
                    label: 'Limited edition',
                    name: 'limited',
                    component: 'toggle',
                    description: 'Set this variant as limited edition',
                    toggleLabels: {
                      true: 'On',
                      false: 'Off',
                    },
                    defaultValue: false,
                  },
                  {
                    label: 'Subscription product',
                    name: 'subscription',
                    component: 'toggle',
                    description: 'Use this variant as subscription product',
                    toggleLabels: {
                      true: 'On',
                      false: 'Off',
                    },
                    defaultValue: false,
                  },
                  {
                    label: 'product',
                    description: 'Select product',
                    name: 'product',
                    component: 'productSearch',
                  },
                ],
                defaultValue: [
                  {
                    title: 'Mocha',
                    color: color.value.red,
                    limited: false,
                    subscription: false,
                    product: {
                      handle: 'cold-brew-on-tap-96-oz-mocha',
                      title: 'Cold Brew On Tap (96 oz) - Mocha',
                    },
                  },
                  {
                    title: 'Caramel',
                    color: color.value.yellow,
                    limited: false,
                    subscription: false,
                    product: {
                      handle: 'cold-brew-on-tap-96-oz-caramel',
                      title: 'Cold Brew On Tap (96 oz) - Caramel',
                    },
                  },
                  {
                    title: 'Vanilla',
                    color: color.value.blue,
                    limited: true,
                    subscription: false,
                    product: {
                      handle: 'cold-brew-on-tap-96-oz-trial-offer-vanilla',
                      title: 'Cold Brew On Tap (96 oz) - Caramel',
                    },
                  },
                ],
              },
            ],
            defaultValue: {
              variants: [
                {
                  title: 'Mocha',
                  color: color.value.red,
                  limited: false,
                  subscription: false,
                  product: {
                    handle: 'cold-brew-on-tap-96-oz-mocha',
                    title: 'Cold Brew On Tap (96 oz) - Mocha',
                  },
                },
                {
                  title: 'Caramel',
                  color: color.value.yellow,
                  limited: false,
                  subscription: false,
                  product: {
                    handle: 'cold-brew-on-tap-96-oz-caramel',
                    title: 'Cold Brew On Tap (96 oz) - Caramel',
                  },
                },
                {
                  title: 'Vanilla',
                  color: color.value.blue,
                  limited: true,
                  subscription: false,
                  product: {
                    handle: 'cold-brew-on-tap-96-oz-trial-offer-vanilla',
                    title: 'Cold Brew On Tap (96 oz) - Caramel',
                  },
                },
              ],
            },
          },
          {
            label: 'CTA Settings',
            name: 'ctaSettings',
            component: 'group',
            fields: [
              {
                label: 'CTA Text',
                name: 'ctaText',
                component: 'text',
                defaultValue: 'or subscribe and save monthly',
              },
              {
                label: 'Container ID',
                name: 'containerID',
                description: 'ID of the container element',
                component: 'text',
                defaultValue: 'shopping-section-bottom',
              },
              {
                label: 'CTA link',
                name: 'link',
                component: 'link',
              },
            ],
            defaultValue: {
              ctaText: 'or subscribe and save monthly',
              containerID: 'shopping-section-bottom',
              link: {
                text: 'internal',
                url: '/subscribe',
              },
            },
          },
          {
            label: 'price strikethrough',
            name: 'priceStrikethrough',
            component: 'number',
            description: 'Set price for strikethrough',
            defaultValue: 39.99,
          },
          {
            label: 'ATC Block Secondary settings',
            name: 'secondary',
            description: 'Set variant options',
            component: 'group',
            fields: [
              {
                label: 'Global badge',
                description: 'set a global badge text',
                name: 'badge',
                component: 'text',
                defaultValue: 'Free Shipping',
              },
              {
                label: 'Show global badge',
                name: 'showBadge',
                component: 'toggle',
                description: 'show /hide savings badge',
                toggleLabels: {
                  true: 'On',
                  false: 'Off',
                },
                defaultValue: true,
              },
              {
                label: 'Step 1',
                name: 'stepOne',
                component: 'text',
              },
              {
                label: 'Step 2',
                name: 'stepTwo',
                component: 'text',
              },
              {
                label: 'Box settings',
                name: 'boxes',
                component: 'group-list',
                itemProps: {
                  label: '{{item.title}}' || 'new item',
                },
                validate: {
                  maxItems: 2,
                },
                defaultItem: {
                  title: '1 Box',
                  subtitle: '',
                  component: 16,
                  badge: 'you save 17%',
                  showBadge: true,
                  default: false,
                },
                fields: [
                  {
                    label: 'Default',
                    name: 'default',
                    component: 'toggle',
                    description: 'Set this variant as default selected',
                    toggleLabels: {
                      true: 'On',
                      false: 'Off',
                    },
                    defaultValue: false,
                  },
                  {
                    label: 'Title',
                    name: 'title',
                    component: 'text',
                  },
                  {
                    label: 'subtitle',
                    name: 'subtitle',
                    description: 'show a subtitle',
                    component: 'text',
                  },
                  {
                    label: 'Total glasses per box',
                    name: 'totalGlasses',
                    description:
                      'put the total glasses per box to calculate the price per glass',
                    component: 'number',
                  },
                  {
                    label: 'Savings badge',
                    description: 'set a savings badge text',
                    name: 'badge',
                    component: 'text',
                  },
                  {
                    label: 'Show savings badge',
                    name: 'showBadge',
                    component: 'toggle',
                    description: 'show /hide savings badge',
                    toggleLabels: {
                      true: 'On',
                      false: 'Off',
                    },
                    defaultValue: true,
                  },
                ],
                defaultValue: [
                  {
                    title: '1 Box',
                    subtitle: '16 glasses | 2.02/glass',
                    totalGlasses: 16,
                    badge: 'you save 17%',
                    default: false,
                    showBadge: true,
                  },
                  {
                    title: '2 Boxes',
                    subtitle: '32 glasses | 1.69/glass',
                    totalGlasses: 32,
                    badge: 'you save 17%',
                    default: true,
                    showBadge: true,
                  },
                ],
              },
            ],
            defaultValue: {
              badge: 'Free Shipping',
              showBadge: true,
              stepOne: 'Choose your flavor',
              stepTwo: 'How many glasses would you like?',
              boxes: [
                {
                  default: false,
                  title: '1 Box',
                  subtitle: '16 glasses | 2.02/glass',
                  totalGlasses: 16,
                  badge: 'you save 17%',
                  showBadge: false,
                },
                {
                  default: true,
                  title: '2 Boxes',
                  subtitle: '32 glasses | 1.69/glass',
                  totalGlasses: 32,
                  badge: 'you save 17%',
                  showBadge: true,
                },
              ],
            },
          },
        ],
        defaultValue: {
          reviews: {
            title: '4.8 stars based on 3,933 reviews',
            starRating: 4,
          },
          variants: {
            variants: [
              {
                title: 'Mocha',
                color: color.value.red,
                limited: false,
                product: {
                  handle: 'cold-brew-on-tap-96-oz-mocha',
                  title: 'Cold Brew On Tap (96 oz) - Mocha',
                },
              },
              {
                title: 'Caramel',
                color: color.value.yellow,
                limited: false,
                product: {
                  handle: 'cold-brew-on-tap-96-oz-caramel',
                  title: 'Cold Brew On Tap (96 oz) - Caramel',
                },
              },
              {
                title: 'Vanilla',
                color: color.value.blue,
                limited: true,
                product: {
                  handle: 'cold-brew-on-tap-96-oz-trial-offer-vanilla',
                  title: 'Cold Brew On Tap (96 oz) - Caramel',
                },
              },
            ],
          },
          ctaSettings: {
            ctaText: 'or subscribe and save monthly',
            containerID: 'shopping-section-bottom',
            link: {},
          },
          priceStrikethrough: 39.99,
          secondary: {
            badge: 'Free Shipping',
            showBadge: true,
            stepOne: 'Choose your flavor',
            stepTwo: 'How many glasses would you like?',
            boxes: [
              {
                default: false,
                title: '1 Box',
                subtitle: '16 glasses | 2.02/glass',
                totalGlasses: 16,
                badge: 'you save 17%',
                showBadge: false,
              },
              {
                default: true,
                title: '2 Boxes',
                subtitle: '32 glasses | 1.69/glass',
                totalGlasses: 32,
                badge: 'you save 17%',
                showBadge: true,
              },
            ],
          },
        },
      },
      {
        ...section,
        defaultValue: {
          mobile: {
            container: 'container',
            mt: 'none',
            py: 's',
          },
          desktop: {
            container: 'contentMedium',
            mt: 'none',
            py: 's',
          },
        },
      },
    ],
  };
}
