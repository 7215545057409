import { Box, Paragraph } from 'theme-ui';

import { Svg } from '@snippets';

import { useMenuSidebar } from '../MenuSidebar';
import { themed } from './AccordionNav.theme';

export const AccordionNavItem = themed(({ theme, item, index }) => {
  const [{ selectedItem }, { toggleNestedSidebar }] = useMenuSidebar();
  const isActive = selectedItem === item;

  return (
    <Box
      as="li"
      data-comp={`${AccordionNavItem.displayName}`}
      onClick={() => {
        toggleNestedSidebar({
          item,
          index: index ?? index + 1, // as nth-child uses 1-based index
        });
      }}
      sx={theme.item}
    >
      <Svg
        data-comp={`${AccordionNavItem.displayName}Arrow`}
        viewBox="0 0 48 48"
        src={
          isActive
            ? '/svgs/arrow-left.svg#arrow-left'
            : '/svgs/arrow-right.svg#arrow-right'
        }
        sx={theme.item.icon}
      />

      <Paragraph
        data-comp={`${AccordionNavItem.displayName}Text`}
        sx={theme.item.text}
      >
        {item.link.text}
      </Paragraph>
    </Box>
  );
});

AccordionNavItem.displayName = 'AccordionNavItem';
