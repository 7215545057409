import { Grid, Spinner, Flex, Paragraph, Box } from 'theme-ui';
import { useProductItemByHandle } from '@backpackjs/storefront';

import { Picture, Locale } from '@snippets';
import { themed } from './Product.theme';

export const Product = themed(({ theme, product, goToResultAndToggle }) => {
  const { productItem, ...status } = useProductItemByHandle({
    handle: product.handle,
  });
  const productUrl = product.url.split('?')[0];

  return (
    <Grid
      data-comp={Product.displayName}
      onClick={() => goToResultAndToggle(productUrl)}
      sx={theme.grid}
    >
      <Picture
        alt={product.title}
        images={[
          {
            src: product.featured_image.url,
            ratio: 7 / 5,
            width: 70,
            height: 50,
          },
        ]}
        sx={theme.picture}
      />

      <Flex sx={theme.details}>
        <Paragraph sx={theme.title}>{product.title}</Paragraph>

        <Paragraph sx={theme.type}>{product.type}</Paragraph>

        {/*  price */}
        <Box sx={theme.prices}>
          {status.finished ? (
            productItem ? (
              <Locale.Product product={productItem}>
                <Locale.Price>
                  {/* todo: theme.price style is not auto refreshing */}
                  {({ minVariantPrice, maxVariantPrice, isVariablePrice }) => {
                    return isVariablePrice ? (
                      <Flex>
                        <Paragraph sx={theme.price}>
                          {minVariantPrice.locale} —
                        </Paragraph>
                        <Paragraph sx={theme.price}>
                          {maxVariantPrice.locale}
                        </Paragraph>
                      </Flex>
                    ) : (
                      <Paragraph sx={theme.price}>
                        {minVariantPrice.locale}
                      </Paragraph>
                    );
                  }}
                </Locale.Price>
              </Locale.Product>
            ) : null // something went wrong
          ) : (
            <Spinner height="18px" />
          )}
        </Box>
      </Flex>
    </Grid>
  );
});

Product.displayName = 'Product';
