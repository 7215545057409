import { useInView } from 'react-intersection-observer';
import { Box, AspectRatio } from 'theme-ui';

import { useBreakpointValue } from '@hooks';

export function ShopifyVideo({
  sources = [],
  ratio = 0.75,
  muted = true,
  playsInline = true,
  loop = true,
  autoplay = true,
  controls = false,
  posters = [],
  overlay,
  children,
  sx,
  ...props
}) {
  const videoSources = sources.filter(
    ({ mimeType }) => mimeType === 'video/mp4'
  );
  const poster = useBreakpointValue([...posters]);
  const responsiveRatio = useBreakpointValue([
    (videoSources?.[1]?.width || 1) / (videoSources?.[1]?.height || 1) || ratio,
    (videoSources?.[0]?.width || 1) / (videoSources?.[0]?.height || 1) || ratio,
  ]);

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <AspectRatio
      ref={ref}
      data-comp="ShopifyVideo"
      ratio={responsiveRatio || ratio}
      sx={{
        bg: 'ghostwhite',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
      >
        <video
          muted={muted}
          playsInline={playsInline}
          loop={loop}
          autoPlay={autoplay}
          controls={controls}
          poster={poster}
          sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {inView && videoSources?.length
            ? videoSources.map((source) => {
                return (
                  <source
                    key={source.url}
                    src={source.url}
                    type={source.mimeType}
                  />
                );
              })
            : null}
          Your browser does not support the <code>video</code> element.
        </video>

        <Box
          sx={{
            display: overlay ? 'block' : 'none',
            position: 'absolute',
            width: '100%',
            height: '100%',
            bg: 'black',
            opacity: 0.7,
          }}
        />
      </Box>
      {children}
    </AspectRatio>
  );
}

/*
Example props
  {
    "sources": [
      {
          "url": "https://cdn.shopify.com/videos/c/vp/e4e1db06d7dd44e494a4e24d57ac9066/e4e1db06d7dd44e494a4e24d57ac9066.HD-720p-1.6Mbps.mp4",
          "mimeType": "video/mp4",
          "format": "mp4",
          "height": 720,
          "width": 860
      },
      {
          "url": "https://cdn.shopify.com/videos/c/vp/e4e1db06d7dd44e494a4e24d57ac9066/e4e1db06d7dd44e494a4e24d57ac9066.m3u8",
          "mimeType": "application/x-mpegURL",
          "format": "m3u8",
          "height": 720,
          "width": 860
      },
      {
          "url": "https://cdn.shopify.com/videos/c/vp/e4e1db06d7dd44e494a4e24d57ac9066/e4e1db06d7dd44e494a4e24d57ac9066.SD-480p-0.9Mbps.mp4",
          "mimeType": "video/mp4",
          "format": "mp4",
          "height": 480,
          "width": 574
      }
    ]
  }

*/
