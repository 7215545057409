import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.sidebar = {
      price: {
        variant: 'flex.column',
        mb: '26px',
        minHeight: '44px',

        regular: {
          variant: 'text.xs',
          textAlign: 'right',
        },
        strike: {
          variant: 'text.xs',
          textAlign: 'right',
          textDecoration: 'line-through',
          color: 'gray',
        },
      },
    };

    // cart empty — rendered in /cart
    this.page = {
      price: {
        ...this.sidebar.price,

        regular: {
          variant: 'text.xs-sm',
          textAlign: 'right',
        },
        strike: {
          variant: 'text.xs-sm',
          textAlign: 'right',
          textDecoration: 'line-through',
          color: 'gray',
        },
      },
    };
  })()
);
