import { Box, Grid } from 'theme-ui';

import { Ctas, CtaMenu, CtaSearch } from './Ctas';
import { Logo } from './Logo';
import { Nav } from './Nav';
import { themed } from './Menu.theme';

export const Menu = themed(({ theme, isFixed, ...props }) => {
  return (
    <Grid as="header" data-comp={Menu.displayName} sx={theme.wrapper}>
      {/* 🍔 🔍  — mobile only */}
      <Box sx={theme.toggle}>
        <CtaMenu />

        {/* Search toggle mobile */}
        {/* <CtaSearch sx={{ ml: 6 }} /> */}
      </Box>

      <Logo isFixed={isFixed} />

      {/* Top level nav */}
      <Nav isFixed={isFixed} />

      {/* 🔍 👩‍💻  🛒 */}
      {/* <Ctas /> */}
    </Grid>
  );
});

Menu.displayName = 'Menu';
