import { Flex, Paragraph } from 'theme-ui';

import { themed } from './Content.theme';

export const Content = themed(({ theme, review }) => {
  const { quote } = review;

  return (
    <Flex
      data-comp={Content.displayName}
      variant="flex.column.center"
      as="blockquote"
      sx={{
        pb: 13,
        textAlign: 'center',
      }}
    >
      {quote && (
        <Paragraph
          sx={{
            textTransform: 'uppercase',
            fontSize: 9,
            mt: 10,
            mb: 7,
            fontFamily: 'gtPressuraBold',
            px: [14, 5],
          }}
        >
          “{quote}”
        </Paragraph>
      )}
    </Flex>
  );
});

Content.displayName = 'Content';
