import { useCallback } from 'react';
import { Flex, Box, Text, Switch, Label } from 'theme-ui';

import { useModal } from '@hooks';

import { colors } from '@theme/theme.colors';
import { themed } from './Subscribe.theme';
import { Supplementary } from './Supplementary';

export const Subscribe = themed(
  ({
    theme,
    subLabel,
    sellingPlanId,
    label,
    handleSelect,
    isSelected,
    supplementary,
  }) => {
    const [{ modal }, { openModal, closeModal }] = useModal();
    const currentPlan = sellingPlanId;
    const toggleSizeGuideModal = useCallback(() => {
      if (modal) {
        closeModal();
        return;
      }

      openModal(<Supplementary supplementary={supplementary} />);
    }, [modal]);

    return (
      <Flex data-comp={Subscribe.displayName} sx={theme.container}>
        <Flex
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 4,
            my: 5,
          }}
        >
          <Box>
            <Switch
              checked={isSelected}
              onChange={handleSelect}
              sx={theme.switch(isSelected)}
            />
          </Box>
          <Label sx={{ flex: 1, flexDirection: 'column', mx: [0, 5] }}>
            <Text
              variant="text.base"
              sx={{
                fontFamily: 'gtPressuraBold',
              }}
            >
              {label}
            </Text>
            <Flex sx={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text
                variant="text.sm"
                sx={{
                  fontFamily: 'gtPressura',
                }}
              >
                {subLabel}
              </Text>

              {supplementary?.showSupplementary && (
                <Text
                  sx={{
                    color: colors.green,
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    ml: 3,
                    fontFamily: 'gtPressuraBold',
                  }}
                  onClick={toggleSizeGuideModal}
                >
                  {supplementary?.label}
                </Text>
              )}
            </Flex>
          </Label>
        </Flex>

        <Flex sx={theme.wrapper} />
      </Flex>
    );
  }
);

Subscribe.displayName = 'Subscribe';
