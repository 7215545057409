import { Flex, Text, Button, Heading } from 'theme-ui';

import { themed } from './Variants.theme';
import { CalculatedPrice } from './CalculatedPrice';

export const Variants = themed(
  ({
    theme,
    heading,
    variants,
    handleSelect,
    productsVariants = [],
    subscribePrice,
    selectedPlanDiscount,
  }) => {
    return (
      <Flex data-comp={Variants.displayName} sx={theme.container}>
        <Heading variant="text.h4">{heading}</Heading>
        <Flex sx={theme.wrapper}>
          {variants?.length > 0 &&
            variants
              .slice(0, 2)
              .map(
                (
                  {
                    title,
                    subtitle,
                    totalGlasses,
                    showBadge,
                    badge,
                    isSelected,
                  },
                  index
                ) => (
                  <Button
                    key={index}
                    variant={isSelected ? 'outlineInverse' : 'outline'}
                    sx={theme.box({ showBadge, badge })}
                    onClick={() => handleSelect(index)}
                  >
                    <Flex
                      sx={{
                        justifyContent: 'space-between',
                        width: '100%',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        ':after': {
                          background: isSelected
                            ? 'url("/svgs/checked_colored.svg#checked_colored") center center no-repeat'
                            : 'none',
                          position: 'absolute',
                          display: 'block',
                          content: '""',
                          width: '2rem',
                          height: '2rem',
                          top: '35%',
                          transformY: 'translate(-35%)',
                          right: '1rem',
                        },
                      }}
                    >
                      <Text sx={theme.title}>{title}</Text>

                      {totalGlasses ? (
                        <CalculatedPrice
                          selectedVariant={productsVariants[index]}
                          totalGlasses={totalGlasses}
                          subscribePrice={subscribePrice}
                          discount={selectedPlanDiscount}
                        />
                      ) : (
                        <Text sx={theme.subtitle}>{subtitle}</Text>
                      )}
                    </Flex>
                  </Button>
                )
              )}
        </Flex>
      </Flex>
    );
  }
);

Variants.displayName = 'Variants';
