import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.bar = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      color: 'white',
      p: 8,
    };
  })()
);
