import { Container } from 'theme-ui';

import { Section } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './AtcBlockSubscribe.schema';
import { themed } from './AtcBlockSubscribe.theme';
import { Header } from './Header';
import { Content } from './Content';

export const AtcBlockSubscribe = withInView(
  themed(({ theme, cms }) => {
    const { section, text, content } = cms;

    if (!content || !text) return null;

    return (
      <Section section={section}>
        <Container
          id={AtcBlockSubscribe.displayName}
          data-comp={AtcBlockSubscribe.displayName}
        >
          <Header header={text} />
          <Content content={content} />
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

AtcBlockSubscribe.displayName = 'AtcBlockSubscribe';
AtcBlockSubscribe.Schema = Schema;
