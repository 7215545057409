import { colors } from '@theme/theme.colors';
import { Flex, Paragraph, Box } from 'theme-ui';
import { keyframes } from '@emotion/react';
import { Picture } from '@snippets';
import { themed } from './Banner.theme';
// import { Picture } from '../../../snippets';

const fadeIn = keyframes({
  from: { transform: 'translate(100%)' },
  to: { transform: 'translate(-200%)' },
});

export const Banner = themed(({ theme, banners }) => {
  return (
    <Box
      sx={{
        transform: ['translateY(-220%)', 'translateY(-100%)'],
        opacity: 0.2,
      }}
    >
      <Box
        as="ul"
        sx={{
          rotate: '-5deg',
          display: 'inline-flex',
          animation: `${fadeIn} 40s linear infinite`,
        }}
      >
        {banners &&
          banners.map(({ message, image }) => (
            <Box
              as="li"
              sx={{
                color: colors.craft,
                display: 'flex',
                mx: '5rem',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Picture
                alt={image?.alt}
                aboveTheFold
                images={[
                  {
                    src: image?.src,
                    // width: 768,
                    ratio: 1,
                  },
                  {
                    src: image?.src,
                    width: 1024,
                    ratio: 1.2,
                  },
                  {
                    src: image?.src,
                    width: 1280,
                    ratio: 1.2,
                  },
                  {
                    src: image?.src,
                    width: 1440,
                    ratio: 1.2,
                  },
                ]}
                sx={{
                  width: '10%',
                  height: 'auto',
                }}
                imageSx={{
                  mt: 5,
                  height: 'auto',
                }}
              />
              <Paragraph
                sx={{
                  display: 'inline',
                  mx: 10,
                  fontSize: ['2rem', '5vw'],
                  textTransform: 'uppercase',
                  fontWeight: 600,
                  whiteSpace: 'nowrap',
                }}
              >
                {message}
              </Paragraph>
            </Box>
          ))}
      </Box>
    </Box>
  );
});

Banner.displayName = 'Banner';
