import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'flex.column.start',
      width: '100%',
    };

    this.title = {
      fontSize: 7,
      lineHeight: 1.1,
      pr: 6,
      flex: 1,
      fontFamily: 'tabletGothic',
      textTransform: 'uppercase',
    };

    this.price = {
      fontSize: 3,
      color: 'darkGreen',
      mt: 2,
    };
  })()
);
