import { Box, Flex, Paragraph, Spinner } from 'theme-ui';
import { useProductItemByHandle } from '@backpackjs/storefront';

import { Locale, Picture } from '@snippets';

import { useMenuDrawer } from '../MenuDrawer';
import { useMenuSidebar } from '../MenuSidebar';
import { themed } from './SubNav.theme';

export const SubNavProductItem = themed(({ theme, handle }) => {
  if (!handle) return null;

  const { productItem, ...status } = useProductItemByHandle({ handle });
  const [{}, { closeDrawerAndNavigate }] = useMenuDrawer();
  const [{}, { closeSideBarAndNavigate }] = useMenuSidebar();

  const image = productItem?.images[0];

  return status?.finished ? (
    productItem ? (
      <Box
        data-comp={SubNavProductItem.displayName}
        onClick={() => {
          const url = `/products/${productItem.handle}`;
          const item = { link: { url } };
          closeDrawerAndNavigate(item);
          closeSideBarAndNavigate(item);
        }}
        sx={theme.product}
      >
        <Picture
          alt={productItem.title}
          images={[{ src: image.originalSrc, ratio: image.ratio, width: 253 }]}
          sx={theme.product.picture}
        />

        <Paragraph sx={theme.product.title}>{productItem.title}</Paragraph>

        <Locale.Product product={productItem}>
          <Locale.Price>
            {({ minVariantPrice, maxVariantPrice, isVariablePrice }) => {
              return isVariablePrice ? (
                <Flex>
                  <Paragraph sx={theme.product.price}>
                    From {minVariantPrice.locale}
                  </Paragraph>

                  <Paragraph
                    sx={{
                      ...theme.product.price,
                      ml: 2,
                    }}
                  >
                    to {maxVariantPrice.locale}
                  </Paragraph>
                </Flex>
              ) : (
                <Paragraph sx={theme.product.price}>
                  {minVariantPrice.locale}
                </Paragraph>
              );
            }}
          </Locale.Price>

          <Locale.Loader>
            <Spinner height="16px" />
          </Locale.Loader>
          <Locale.Error>{({ errors }) => errors.join(',')}</Locale.Error>
        </Locale.Product>
      </Box>
    ) : (
      'Error fetching product'
    )
  ) : (
    <Box>
      <Spinner />
    </Box>
  );
});

SubNavProductItem.displayName = 'SubNavProductItem';
