import { Flex, Text } from 'theme-ui';
import { Link } from '@snippets';

import { themed } from './AddItemToCart.theme';

export const AddItemToCart = themed(
  ({
    theme,
    type,
    selectedVariant,
    subscribePrice,
    ctaSettings,
    selectedPlanID,
  }) => {
    const variantId = selectedVariant?.legacyResourceId;

    const sellingPlan =
      subscribePrice && selectedVariant
        ? `&selling_plan=${selectedPlanID?.replace(
            'gid://shopify/SellingPlan/',
            ''
          )}`
        : '&';

    // let searchParms = new URLSearchParams(
    //   window.location.search || ''
    // ).toString();

    if (selectedVariant?.product?.tags?.includes('inveterate-product')) {
      sellingPlan = `&selling_plan=4868440161&`;
    }

    return (
      <Flex data-comp={AddItemToCart.displayName} sx={theme.container}>
        <Link
          href={`https://wanderingbearcoffee.com/cart/add?id=${variantId}&quantity=${selectedVariant?.position}${sellingPlan}`}
          tabIndex="-1"
          variant="buttons.primary"
          sx={{
            cursor: 'pointer',
            width: '50%',
          }}
        >
          <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Text variant="text.h4">add to cart</Text>
          </Flex>
        </Link>
        {type && (
          <Link
            sx={theme.subscribe}
            href={ctaSettings?.link?.url || `#${ctaSettings?.containerID}`}
            scroll
          >
            <Text>{ctaSettings?.link?.text || ctaSettings?.ctaText}</Text>
          </Link>
        )}
      </Flex>
    );
  }
);

AddItemToCart.displayName = 'AddItemToCart';
