import { Fragment } from 'react';
import { Flex } from 'theme-ui';

import { themed } from './Header.theme';

export const Header = themed(({ theme, setHeight, children, ...props }) => {
  return (
    <Flex data-comp={Header.displayName} {...props} sx={theme.header}>
      <>{children}</>
    </Flex>
  );
});

Header.displayName = 'Header';
