import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.image = {
      mb: [2, 11],
    };

    this.iconImage = {
      maxWidth: '200px',
      mx: 'auto',
    };

    this.imageLink = {
      ...this.image,
      ':hover': {
        img: {
          transform: 'scale(1.03)',
        },
      },
      img: {
        transform: 'scale(1.01)',
        transition: 'transform 0.3s ease-in-out',
      },
    };

    this.content = {
      flexDirection: 'column',
      px: 4,
    };

    this.heading = {
      mb: 4,
      variant: 'text.h4',
      fontFamily: 'tabletGothic',
    };

    this.body = {
      mt: 0,
      variant: 'text.md',
    };

    this.button = {
      mt: 4,
    };
  })()
);
