import { forwardRef } from 'react';

import { Svg } from '@snippets';

export const StarsIcon = forwardRef(
  (
    {
      averageScore,
      emptyColor,
      fullColor,
      hoveredIndex,
      index,
      totalReviews,
      width,
      ...props
    },
    ref
  ) => {
    if (typeof averageScore === 'undefined') return null;

    const scoreMinusIndex = averageScore - (index + 1);
    const isEmpty = scoreMinusIndex <= -1;
    const isHalf = !isEmpty && scoreMinusIndex < -0.5;
    const inHoverRange = index <= hoveredIndex;
    const starIndex = index + 1;

    const starSvgSrc =
      totalReviews === 0
        ? '/svgs/noprecache/yotpo/star-empty.svg#star-empty'
        : isEmpty
        ? '/svgs/noprecache/yotpo/star-empty.svg#star-empty' // for empty as just border -> '/svgs/star-empty.svg#star-empty'
        : isHalf
        ? '/svgs/noprecache/yotpo/star-half-empty.svg#star-half-empty'
        : '/svgs/noprecache/yotpo/star-full.svg#star-full'; // isFull

    return (
      <Svg
        data-comp={StarsIcon.displayName}
        alt={`${starIndex} ${starIndex === 1 ? 'star' : 'stars'}`}
        viewBox="0 0 24 24"
        key={index}
        width={width}
        src={starSvgSrc}
        sx={{
          width: '25px',
          px: 1,
          color: inHoverRange ? fullColor : isEmpty ? emptyColor : fullColor,
        }}
      />
    );
  }
);

StarsIcon.displayName = 'StarsIcon';
