import { store } from '@backpackjs/storefront';

// instant un-debounced search term (not used)
const productIngredients = store.recoil.atom({
  key: 'product/ingredients',
  default: [],
});

export default {
  // atoms
  productIngredients,

  // selectors
};
