import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.grid = {
      gridArea: 'menu',
      display: ['grid', 'none'],
      width: '100%',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      gridGap: 0,
    };

    this.accordion = {
      width: '100%',
      height: 'auto',
      overflow: 'hidden',
      transition: 'max-height .5s ease-in-out',
      position: 'relative',
      borderBottom: '1px solid',
      borderColor: 'darkGreen',
    };
  })()
);
