export function Arrow() {
  return (
    <svg
      width="43"
      height="106"
      viewBox="0 0 43 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7666 4.19364C16.7977 5.5699 15.8828 6.80946 15.3042 8.00151C14.4431 9.78734 13.6647 11.5922 12.9594 13.4345C10.2328 20.5749 8.66034 28.1658 8.32024 35.7858C7.63755 51.6816 12.5128 67.4151 21.8808 80.2524C23.97 83.1282 26.2912 85.8225 28.7983 88.3349C29.3853 87.6429 29.9768 86.9647 30.5865 86.2958C32.5924 84.1255 36.1697 84.6476 37.4519 87.2961C39.6557 91.902 41.4506 96.8988 42.6901 101.785C43.1796 103.73 41.6366 105.629 39.6235 105.501C34.4295 105.18 29.2955 104.003 24.4829 102.02C23.1092 101.458 21.5421 100.486 21.6134 99.0002C21.652 98.4088 21.9566 97.884 22.2519 97.3775C22.8186 96.433 23.422 95.5072 24.0298 94.5952C21.8036 92.4797 19.7406 90.1591 17.816 87.794C8.15218 75.9356 2.13953 61.1783 0.920276 45.9263C0.285198 37.8895 0.94379 29.7403 2.83174 21.8953C3.78536 17.9317 5.05547 14.0487 6.62327 10.2831C7.40717 8.40026 8.27366 6.5365 9.21333 4.71008C10.0541 3.09835 10.6916 1.56741 12.5332 0.973504C12.6717 0.928849 12.8052 0.916252 12.9338 0.935721C14.8723 0.737116 16.7198 2.15218 16.7666 4.19364Z"
        fill="currentColor"
      />
    </svg>
  );
}
