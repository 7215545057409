import { color } from '@settings/common/color';

export const Schema = () => {
  return {
    label: 'Logo Bar',
    key: 'logoBar',
    fields: [
      {
        name: 'color',
        label: 'Color',
        description: 'Color of the text and logo when the bar is transparent',
        component: 'color',
        defaultValue: '#ffffff',
      },
      {
        name: 'menu',
        label: 'Menu',
        component: 'list',
        field: {
          label: 'Link',
          name: 'link',
          component: 'link',
        },
        defaultValue: [
          {
            text: 'SHOP',
            url: 'https://wanderingbearcoffee.com/collections',
          },
          {
            text: 'REVIEWS',
            url: 'https://wanderingbearcoffee.com/pages/reviews',
          },
          {
            text: 'SECRET MENU',
            url: 'https://wanderingbearcoffee.com/collections/secret-menu',
          },
        ],
      },
      {
        name: 'cta',
        label: 'CTA',
        component: 'link',
        defaultValue: {
          text: 'SHOP NOW',
          url: 'https://wanderingbearcoffee.com/collections',
        },
      },
    ],
  };
};
