import { create } from '@theme/create';

export const themed = create(
  new (function () {
    (this.sidebar = {
      wrapper: {
        variant: 'flex.column',
        boxShadow: 'top',
        py: 9,
        px: 9,
        mb: 0,
      },

      summary: {
        display: 'none',
        color: 'red',
      },

      shipping: {
        variant: 'text.sm',
        color: 'gray',
        mb: 6,
      },

      checkout: {
        variant: 'buttons.primary',
        mb: [8, null, 9],
      },
    }),
      // cart empty — rendered in /cart
      (this.page = {
        wrapper: {
          ...this.sidebar.wrapper,
          boxShadow: ['none', 'top'],
          pt: [0, null, 11],
          pb: [0, null, 10],
          px: [0, null, 9],
          mb: [11, null, 9],
        },

        summary: {
          variant: 'text.lg',
          display: ['none', null, 'block'],
          mb: 8,
        },

        shipping: {
          variant: 'text.sm',
          color: 'gray',
          mb: 6,
        },

        shipping: {
          ...this.sidebar.shipping,
        },

        checkout: {
          ...this.sidebar.checkout,
        },
      });
  })()
);
