import { align, color } from '../common';

export const promoBar = {
  label: 'Promo Bar',
  name: 'promoBar',
  component: 'group',
  fields: [
    {
      name: 'enabled',
      label: 'Enabled',
      component: 'toggle',
      toggleLabels: {
        true: 'Yes',
        false: 'No',
      },
      defaultValue: true,
    },
    {
      name: 'closable',
      label: 'Closable',
      component: 'toggle',
      toggleLabels: {
        true: 'Yes',
        false: 'No',
      },
      defaultValue: true,
    },
    {
      component: 'number',
      name: 'duration',
      label: 'Autoplay Duration',
      description: 'Time on each message. (sec)',
      step: 1,
      defaultValue: 3,
    },
    {
      component: 'number',
      name: 'speed',
      label: 'Autoplay Speed',
      description: 'Time between message transitions. (sec)',
      step: 0.25,
      defaultValue: 0.75,
    },
    {
      label: 'Messages',
      name: 'messages',
      component: 'group-list',
      itemProps: {
        label: '{{item.copy}}' || '{{item.link?.text}}' || 'Message',
      },
      defaultItem: {
        copy: '<p><b>Your</b> new promotional <i>message</i><p>',
        color: color.value.background,
        background: color.value.text,
        align: 'center',
        link: {
          url: '',
          text: '',
        },
      },
      // each message
      fields: [
        {
          label: 'Background Color',
          name: 'background',
          component: 'select',
          options: color.options,
          defaultValue: color.value.text,
        },
        {
          label: 'Text Color',
          name: 'color',
          component: 'select',
          options: color.options,
          defaultValue: color.value.background,
        },
        {
          label: 'Text Alignment',
          name: 'align',
          component: 'select',
          options: align.options,
          defaultValue: align.value.center,
        },
        {
          label: 'Message',
          description: 'A promo bar message i.e Free shipping on orders..',
          name: 'copy',
          component: 'html',
        },
        {
          label: 'Message Link (optional)',
          name: 'link',
          component: 'link',
          defaultValue: {
            url: '',
            text: '',
          },
        },
      ],
    },
  ],
};
