import { Suspense } from 'react';
import { Box, Flex, Spinner } from 'theme-ui';
import { isBrowser } from '@utils';

import { themed } from './Search.theme';
import { Input } from './Input';
import { Suggestions } from './Suggestions';
import { Results } from './Results';

export const Search = themed(({ theme }) => {
  return (
    <Box data-comp={Search.displayName}>
      <Input alwaysFocused />

      {isBrowser ? (
        <Suspense
          fallback={
            <Flex sx={theme.loader}>
              <Spinner width="20px" />
            </Flex>
          }
        >
          {/* If no results — show suggestions */}
          <Suggestions />

          {/* If results — render results, else nothing found empty */}
          <Results />
        </Suspense>
      ) : null}
    </Box>
  );
});

Search.displayName = 'Search';
