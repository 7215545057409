import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.closed = {
      variant: 'box.fill',
      bg: 'text',
      opacity: 0,
      pointerEvents: 'none',
      position: 'fixed',
      transition: 'opacity .2s cubic-bezier(0.2, 0.5, 0.2, 1)',
      willChange: 'opacity',
      zIndex: '-1',
      '&:hover': {
        cursor: 'none',
      },
    };

    this.open = {
      ...this.closed,
      opacity: 0.5,
      pointerEvents: 'auto',
      zIndex: '40',
      '&:hover': {
        cursor: `url('/svgs/overlay-close.svg') 0 0, auto`,
      },
    };
  })()
);
