import { section, align, color, icons } from '@settings/common';
import { generateId } from '@utils';

export function Schema({ article, blog, collection }) {
  if (article || blog || collection) return null;

  return {
    label: 'ATC Block Quantified',
    key: 'atc-block-quantified',
    fields: [
      {
        label: 'Content settings',
        name: 'content',
        description:
          'Select variants, customize box, subscribe button, modal content',
        component: 'group',
        fields: [
          {
            name: 'reviews',
            label: 'Reviews',
            component: 'group',
            fields: [
              {
                name: 'starRating',
                component: 'select',
                label: 'Star Rating',
                description: '1 - 5 only',
                options: [
                  { label: 'One', value: 1 },
                  { label: 'Two', value: 2 },
                  { label: 'Three', value: 3 },
                  { label: 'Four', value: 4 },
                  { label: 'Five', value: 5 },
                ],
              },
            ],
            defaultValue: {
              starRating: 4,
            },
          },
          {
            label: 'Variant settings',
            name: 'variants',
            description: 'Set variant options',
            component: 'group',
            fields: [
              {
                label: 'Select Variants',
                name: 'variants',
                description: 'Select variants, Name, color product',
                component: 'group',
                fields: [
                  {
                    label: 'Variants',
                    name: 'variants',
                    component: 'group-list',
                    itemProps: {
                      label: '{{item.title}}',
                    },
                    defaultItem: {
                      title: 'Straight Black',
                      color: color.value.black,
                      limited: false,
                      product: {
                        handle: 'extra-strong-cold-brew-on-tap-96oz-straight-black',
                        title: 'Cold Brew On Tap (96 oz) - Straight Black',
                      },
                    },
                    fields: [
                      {
                        label: 'Title',
                        name: 'title',
                        component: 'text',
                      },
                      {
                        label: 'Color',
                        name: 'color',
                        component: 'select',
                        options: color.options,
                      },
                      {
                        label: 'Background Image',
                        name: 'image',
                        component: 'image',
                        description: 'Optional',
                      },
                      {
                        label: 'product',
                        description: 'Select product',
                        name: 'product',
                        component: 'productSearch',
                      },
                      {
                        label: 'Limited edition',
                        name: 'limited',
                        component: 'toggle',
                        description: 'Set this variant as limited edition',
                        toggleLabels: {
                          true: 'On',
                          false: 'Off',
                        },
                        defaultValue: false,
                      },
                      {
                        label: 'Ingredients',
                        name: 'ingredients',
                        component: 'group-list',
                        description: 'Set the variants ingredient list',
                        itemProps: {
                          label: '{{item.title}}',
                        },
                        defaultItem: {
                          title: 'Flavor Profiles',
                          description: 'Coffee forward, with subtle hints of vanilla',
                        },
                        fields: [
                          {
                            label: 'Title',
                            name: 'title',
                            component: 'text',
                          },
                          {
                            label: 'Description',
                            name: 'description',
                            component: 'text',
                          },
                          {
                            name: 'image',
                            label: 'Image',
                            component: 'image',
                          },
                        ],
                        defaultValue: [
                          {
                            title: 'Flavor Profiles',
                            description: 'Coffee forward, with subtle hints of vanilla',
                          },
                          {
                            title: 'Ingredients',
                            description: 'filtered water, 100% organic Arabica coffee from Peru, organic natural flavor',
                          },
                        ],
                      },
                    ],
                    defaultValue: [
                      {
                        title: 'Straight Black',
                        color: color.value.black,
                        limited: false,
                        product: {
                          handle: 'extra-strong-cold-brew-on-tap-96oz-straight-black',
                          title: 'Cold Brew On Tap (96 oz) - Straight Black',
                        },
                        ingredients:[
                          {
                            title: 'Flavor Profiles',
                            description: 'Coffee forward, with subtle hints of vanilla',
                          },
                          {
                            title: 'Ingredients',
                            description: 'filtered water, 100% organic Arabica coffee from Peru, organic natural flavor',
                          },
                        ],
                      },
                      {
                        title: 'Caramel',
                        color: color.value.yellow,
                        limited: false,
                        product: {
                          handle: 'cold-brew-on-tap-96-oz-caramel',
                          title: 'Cold Brew On Tap (96 oz) - Caramel',
                        },
                        ingredients:[
                          {
                            title: 'Flavor Profiles',
                            description: 'Coffee forward, with subtle hints of Caramel',
                          },
                          {
                            title: 'Ingredients',
                            description: 'Filtered water, 100% organic Arabica coffee from Peru, organic natural flavor',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                label: 'Flavor Settings',
                name: 'flavor',
                description: 'Set flavor settings, Title, Ingredients link text, Ingredients modal title',
                component: 'group',
                fields: [
                  {
                    label: 'Title',
                    name: 'title',
                    component: 'text',
                    defaultValue: 'Flavors',
                  },
                  {
                    label: 'Ingredients Link Text',
                    name: 'linkText',
                    component: 'text',
                    defaultValue: 'Full Ingredients List',
                  },
                  {
                    label: 'Ingredients Modal Title',
                    name: 'modalTitle',
                    component: 'text',
                    defaultValue: 'NUTRITION & INGREDIENTS',
                  },
                  {
                    label: 'Product Icons',
                    name: 'productIcons',
                    component: 'group-list',
                    itemProps: {
                      label: '{{item.iconText}}',
                    },
                    validate(list) {
                      if (list.length > 3) {
                        window.__bpCMS.alerts.error(`Max of 3 tiles only.`);
                      }
                    },
                    fields: [
                      {
                        name: 'image',
                        label: 'Image',
                        component: 'image',
                      },
                      {
                        label: 'Icon Text',
                        name: 'iconText',
                        component: 'text',
                      },
                    ],
                  },
                ],
              },
              {
                label: 'Box settings',
                name: 'boxes',
                component: 'group',
                fields: [
                  {
                    label: 'Title',
                    name: 'title',
                    component: 'text',
                  },
                  {
                    label: 'Dimension link text',
                    name: 'linkText',
                    component: 'text',
                  },
                  {
                    label: 'Dimensions Settings',
                    name: 'dimensions',
                    component: 'group-list',
                    itemProps: {
                      label: '{{item.title}}',
                    },
                    validate(list) {
                      if (list.length > 3) {
                        window.__bpCMS.alerts.error(`Max of 3 tiles only.`);
                      }
                    },
                    fields: [
                      {
                        name: 'image',
                        label: 'Image',
                        component: 'image',
                      },
                      {
                        name: 'title',
                        label: 'Title',
                        component: 'text',
                      },
                      {
                        name: 'description',
                        label: 'Description',
                        component: 'text',
                      },
                      {
                        name: 'dimension',
                        label: 'Dimension',
                        component: 'text',
                      },
                    ],
                    defaultValue: [
                      {
                        title: '32oz',
                        description: 'Fits in any fridge (including doors)',
                        dimension: 'Dimensions: 2.85" x 2.85" x 7.8"',
                      },
                      {
                        title: '96oz',
                        description: 'Designed for the fridge shelf',
                        dimension: 'Dimensions: 6.75" x 4" x 7.875"',
                      },
                      {
                        title: '128oz',
                        description: 'Wide but worth it',
                        dimension: 'Dimensions: 6.75" x 6" x 7.875"',
                      },
                    ],
                  },
                ],
              },
            ],
            defaultValue: {
              variants: {
                variants: [
                  {
                    title: 'Straight Black',
                    color: color.value.black,
                    limited: false,
                    product: {
                      handle: 'extra-strong-cold-brew-on-tap-96oz-straight-black',
                      title: 'Cold Brew On Tap (96 oz) - Straight Black',
                    },
                  },
                  {
                    title: 'Caramel',
                    color: color.value.yellow,
                    limited: false,
                    product: {
                      handle: 'cold-brew-on-tap-96-oz-caramel',
                      title: 'Cold Brew On Tap (96 oz) - Caramel',
                    },
                  },
                ],
              },
              flavor: {
                title: 'Flavors',
                linkText: 'Full Ingredients List',
                modalTitle: 'NUTRITION & INGREDIENTS',
              },
              boxes: {
                title: 'QTY',
                linkText: 'BOX DIMENSIONS',
                dimensions: [
                  {
                    title: '32oz',
                    description: 'Fits in any fridge (including doors)',
                    dimension: 'Dimensions: 2.85" x 2.85" x 7.8"',
                  },
                  {
                    title: '96oz',
                    description: 'Designed for the fridge shelf',
                    dimension: 'Dimensions: 6.75" x 4" x 7.875"',
                  },
                  {
                    title: '128oz',
                    description: 'Wide but worth it',
                    dimension: 'Dimensions: 6.75" x 6" x 7.875"',
                  },
                ],
              },
            },
          },
          {
            label: 'subscription settings',
            name: 'subscription',
            component: 'group',
            fields: [
              {
                label: 'Main Title',
                name: 'title',
                component: 'text',
                defaultValue: 'Frequency',
              },
              {
                label: 'One Time Label',
                name: 'oneTimeLabel',
                component: 'text',
                defaultValue: 'One Time Purchase',
              },
              {
                label: 'Subscription Label',
                name: 'subscriptionLabel',
                component: 'text',
                defaultValue: 'Subscribe & Save',
              },
              {
                label: 'Subscription Description',
                name: 'subscriptionDesc',
                component: 'text',
                defaultValue: 'Delivery every 1 month. Skip or cancel anytime.',
              },
              {
                label: 'Subscription toggle',
                name: 'default',
                component: 'toggle',
                description:
                  'you can select the default subscription toggle state',
                toggleLabels: {
                  true: 'Checked',
                  false: 'Unchecked',
                },
                defaultValue: true,
              },
              {
                label: 'Supplementary information',
                name: 'additional',
                description: 'you can add additional information',
                component: 'group',
                fields: [
                  {
                    label: 'Hide / show additional information',
                    name: 'showSupplementary',
                    component: 'toggle',
                    description:
                      'you can show or hide the additional information',
                    toggleLabels: {
                      true: 'Show',
                      false: 'Hide',
                    },
                    defaultValue: true,
                  },
                  {
                    label: 'Additional information label',
                    name: 'label',
                    component: 'text',
                    defaultValue: 'WHY SUBSCRIBE?',
                  },
                  {
                    label: 'Text settings',
                    name: 'text',
                    description: 'Set heading and subheading',
                    component: 'group',
                    fields: [
                      {
                        label: 'title',
                        name: 'title',
                        component: 'markdown',
                        description:
                          'You can use markdown e.g. <u>**bold**</u> or # for heading',
                        defaultValue: 'WHY SUBSCRIBE?',
                      },
                      {
                        label: 'subtitle',
                        name: 'subtitle',
                        component: 'markdown',
                        description:
                          'You can use markdown e.g. <u>**bold**</u> or ## for subheading',
                        defaultValue:
                          'SAVE MONEY AND NEVER WORRY ABOUT RUNNING OUT OF COFFEE WITH FRESH BREW SHIPPED ON YOUR SCHEDULE.',
                      },
                    ],
                  },
                  {
                    label: 'Items',
                    name: 'items',
                    description: 'You can add title, description and icon',
                    component: 'group-list',
                    itemProps: {
                      label: '{{item.title}}',
                    },
                    validate(list) {
                      if (list.length > 4) {
                        window.__bpCMS.alerts.error(`Max of 4 tiles only.`);
                      }
                    },
                    defaultItem: {
                      title: 'SAVE 10% ON EVERY ORDER',
                      description:
                        'We’ll reward your commitment to caffeination with a 10% discount on every order.',
                      icon: icons.value.star,
                    },
                    fields: [
                      {
                        label: 'Title',
                        name: 'title',
                        component: 'text',
                      },
                      {
                        label: 'Description',
                        name: 'description',
                        component: 'textarea',
                      },
                      {
                        label: 'Icon',
                        name: 'icon',
                        component: 'select',
                        description: 'Select the icon',
                        options: icons.options,
                        defaultValue: icons.value.star,
                      },
                    ],
                    defaultValue: [
                      {
                        title: 'SAVE 10% ON EVERY ORDER',
                        description:
                          'We’ll reward your commitment to caffeination with a 10% discount on every order.',
                        icon: icons.value.star,
                      },
                      {
                        title: 'DELIVERED ON YOUR SCHEDULE',
                        description:
                          'Total flexibility. Cancel, skip, reschedule or change your products anytime you want in your account.',
                        icon: icons.value.strong,
                      },
                      {
                        title: 'FAST, FRESH, AND NO COMMITMENTS',
                        description:
                          'Free and fast shipping, concierge customer service, and satisfaction guaranteed!',
                        icon: icons.value.leaves,
                      },
                    ],
                  },
                ],
                defaultValue: {
                  label: 'WHY SUBSCRIBE?',
                  showSupplementary: true,
                  text: {
                    title: '# WHY SUBSCRIBE?',
                    subtitle:
                      'SAVE MONEY AND NEVER WORRY ABOUT RUNNING OUT OF COFFEE WITH FRESH BREW SHIPPED ON YOUR SCHEDULE.',
                  },
                  items: [
                    {
                      title: 'SAVE 10% ON EVERY ORDER',
                      description:
                        'We’ll reward your commitment to caffeination with a 10% discount on every order.',
                      icon: icons.value.star,
                    },
                    {
                      title: 'DELIVERED ON YOUR SCHEDULE',
                      description:
                        'Total flexibility. Cancel, skip, reschedule or change your products anytime you want in your account.',
                      icon: icons.value.strong,
                    },
                    {
                      title: 'FAST, FRESH, AND NO COMMITMENTS',
                      description:
                        'Free and fast shipping, concierge customer service, and satisfaction guaranteed!',
                      icon: icons.value.leaves,
                    },
                  ],
                },
              },
            ],
            defaultValue: {
              title: 'Frequency',
              oneTimeLabel: 'One Time Purchase',
              subscriptionLabel: 'Subscribe & Save',
              subscriptionDesc: 'Delivery every 1 month. Skip or cancel anytime.',
              default: true,
              additional: {
                label: 'Why subscribe?',
                showSupplementary: true,
                text: {
                  title: '# Why subscribe?',
                  subtitle: 'SAVE MONEY AND NEVER WORRY ABOUT RUNNING OUT OF COFFEE WITH FRESH BREW SHIPPED ON YOUR SCHEDULE.',
                },
                items: [
                  {
                    title: 'SAVE 10% ON EVERY ORDER',
                    description:
                      'We’ll reward your commitment to caffeination with a 10% discount on every order.',
                    icon: icons.value.star,
                  },
                  {
                    title: 'DELIVERED ON YOUR SCHEDULE',
                    description:
                      'Total flexibility. Cancel, skip, reschedule or change your products anytime you want in your account.',
                    icon: icons.value.strong,
                  },
                  {
                    title: 'FAST, FRESH, AND NO COMMITMENTS',
                    description:
                      'Free and fast shipping, concierge customer service, and satisfaction guaranteed!',
                    icon: icons.value.leaves,
                  },
                ],
              },
            },
          },
        ],
      },
      {
        ...section,
        defaultValue: {
          mobile: {
            container: 'container',
            mt: 'none',
            py: 's',
          },
          desktop: {
            container: 'contentMedium',
            mt: 'none',
            py: 's',
          },
        },
      },
    ],
  };
}
