import { Container, Box } from 'theme-ui';

import { ProductSwiper, Link } from '@snippets';

import { themed } from './Slider.theme';

export const Slider = themed(
  ({ theme, groups, activeIndex, ctaStyle = 'primary' }) => {
    const cta = groups?.[activeIndex]?.cta;

    return (
      <Container data-comp={Slider.displayName} sx={theme.wrapper}>
        <Box sx={theme.swiper}>
          {groups?.map(({ products }, index) => {
            if (index !== activeIndex) return null;
            return (
              <ProductSwiper
                products={products}
                // sx={{ height: '100%' }}
                key={index}
              />
            );
          })}
        </Box>

        {cta?.text && (
          <Link
            variant={`buttons.${ctaStyle}`}
            href={cta.url}
            sx={theme.button}
          >
            {cta.text}
          </Link>
        )}
      </Container>
    );
  }
);

Slider.displayName = 'Slider';
