import { useState, useEffect, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Thumbs, Autoplay } from 'swiper';
import { Container, Flex, Box, Image } from 'theme-ui';

import { withInView } from '@utils';
import { Markdown, Section } from '@snippets';
import { colors } from '@theme/theme.colors';

import { Navigation as Navigate } from './Navigation';

import { Schema } from './TestimonialsSlider.schema';
import { themed } from './TestimonialsSlider.theme';
import { Content } from './Content';

export const TestimonialsSlider = withInView(
  themed(({ theme, cms }) => {
    const { section, headingSettings, curveEnabled, testimonials } = cms;

    const { heading, fontFamily } = headingSettings;

    const [swiper, setSwiper] = useState();

    const [thumbsSwiper, setThumbsSwiper] = useState();

    const [activeIndex, setActiveIndex] = useState(0);

    const setActiveIndexAndSlideToOnSlideChange = useCallback(() => {
      if (!swiper) return;
      swiper?.on('slideChange', () => {
        setActiveIndex(swiper?.realIndex);
      });
    }, [swiper]);

    useEffect(() => {
      setActiveIndexAndSlideToOnSlideChange();
    }, [swiper]);

    return (
      <Section section={section} sx={{ overflow: 'hidden' }}>
        <Container
          data-comp={TestimonialsSlider.displayName}
          sx={{
            background: colors.yellow,
            position: 'relative',
            py: 16,
            ...(curveEnabled
              ? {
                  ':after': {
                    content: '""',
                    position: 'absolute',
                    height: '2.75rem',
                    top: '-2.75rem',
                    backgroundImage: `url('/svgs/yellow-border-top.svg')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    width: '100%',
                  },
                  ':before': {
                    content: '""',
                    position: 'absolute',
                    bottom: '-2rem',
                    height: '2.375rem',
                    backgroundImage: `url('/svgs/yellow-border-bottom.svg')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    width: '100%',
                  },
                }
              : null),
          }}
        >
          <Flex variant="flex.column.center">
            {headingSettings && heading && (
              <Markdown
                variant="text.h2"
                text={heading}
                sx={{
                  fontFamily: [fontFamily],
                  lineHeight: 1.5,
                  textAlign: 'center',
                }}
              />
            )}
          </Flex>

          {testimonials ? (
            <Box
              sx={{
                maxWidth: '700px',
                position: 'relative',
                mx: 'auto',
              }}
            >
              <Swiper
                {...defaultParams}
                onSwiper={setSwiper}
                autoplay={{
                  delay: 5000,
                }}
                autoHeight
                fadeEffect={{ crossFade: true }}
                centeredSlides={false}
                spaceBetween={1}
                navigation={false}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[Thumbs, Autoplay, EffectFade]}
                sx={{
                  ...theme.slider,
                }}
              >
                {testimonials?.map((review, index) => (
                  <SwiperSlide key={index}>
                    <Content review={review} />
                  </SwiperSlide>
                ))}
              </Swiper>
              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={1}
                slidesPerView={testimonials?.length}
                modules={[Thumbs]}
                sx={theme.thumbsSwiper(testimonials?.length * 7 || 5)}
              >
                {testimonials?.map((slide, index) => (
                  <SwiperSlide key={index}>
                    <Image
                      alt={slide.alt}
                      src={slide.image.src}
                      // style={{
                      //   opacity: activeIndex === index ? 1 : 0.6,
                      // }}
                      sx={{
                        opacity: activeIndex === index ? 1 : 0.6,
                        maxWidth: ['100px', 'auto'],
                      }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>

              <Navigate swiper={swiper} />
            </Box>
          ) : null}
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

TestimonialsSlider.displayName = 'TestimonialsSlider';
TestimonialsSlider.Schema = Schema;

const defaultParams = {
  allowSlideNext: true,
  allowSlidePrev: true,
  allowTouchMove: true,
  centeredSlides: true,
  fadeEffect: { crossFade: true },
  freeMode: false,
  loop: true,
  navigation: false,
  slidesPerGroup: 1,
  slidesPerView: 1,
  spaceBetween: 0,
  watchOverflow: true,
};
