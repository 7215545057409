export const buttons = {
  primary: {
    variant: 'text.base',
    fontFamily: 'gtPressura',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    px: 7,
    py: ['11px', null, null, '13px'],
    borderRadius: 0,
    border: (t) => `3px solid ${t.colors.yellow}`,
    cursor: 'pointer',
    minWidth: 168,
    textAlign: 'center',
    backgroundColor: 'darkGreen',
    color: 'yellow',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: 'yellow',
      border: (t) => `3px solid ${t.colors.darkGreen}`,
      color: 'darkGreen',
    },
  },
  secondary: {
    variant: 'text.base',
    fontFamily: 'gtPressura',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    px: 7,
    py: ['11px', null, null, '13px'],
    borderRadius: 0,
    cursor: 'pointer',
    minWidth: 168,
    textAlign: 'center',
    backgroundColor: 'yellow',
    color: 'darkGreen',
    transition: '0.3s',
    border: (t) => `3px solid ${t.colors.darkGreen}`,
    '&:hover': {
      backgroundColor: 'darkGreen',
      border: (t) => `3px solid ${t.colors.yellow}`,
      color: 'yellow',
    },
  },
  menu: {
    color: 'black',
    mr: 6,
    background: 'transparent',
  },
  outline: {
    borderRadius: 0,
    variant: 'text.base',
    fontFamily: 'gtPressura',
    letterSpacing: '0.3px',
    px: 7,
    py: ['11px', null, null, '13px'],
    minWidth: 168,
    textAlign: 'center',
    backgroundColor: 'cream',
    border: '1px solid',
    borderColor: 'craft',
    color: 'black',
    cursor: 'pointer',
    transition: '0.3s',
  },
  outlineInverse: {
    variant: 'buttons.outline',
    backgroundColor: 'craft',
    color: 'white',
  },
  outlineSecondary: {
    variant: 'buttons.outline',
    backgroundColor: 'cream',
    borderColor: 'coffee',
    '&:hover': {
      backgroundColor: '#F8ECE0',
    }
  },

  outlineSecondaryInverse: {
    variant: 'buttons.outline',
    backgroundColor: 'coffee',
    borderColor: 'coffee',
    color: 'white',
  },

  underlineLink: {
    cursor: 'pointer',
    fontFamily: 'body',
    fontWeight: 600,
    lineHeight: 1.5,
    color: 'text',
    bg: 'transparent',
    p: 0,
    m: 0,
    borderRadius: 0,
    border: 0,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  link: {
    1: {
      cursor: 'pointer',
      fontFamily: 'body',
      fontWeight: 600,
      lineHeight: 1.5,
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  plain: {
    bg: 'transparent',
    border: 0,
    borderRadius: 0,
    color: 'text',
    cursor: 'pointer',
    fontFamily: 'body',
    m: 0,
    minWidth: 'unset',
    p: 0,
    position: 'relative',
    textDecoration: 'none',
    ':hover': {
      color: 'currentColor',
    },
  },
};
