import { Box } from 'theme-ui';
import { useCustomer } from '@backpackjs/storefront';

import { Link, Svg } from '@snippets';

import { themed } from '../Menu.theme';

export const CtaAccount = themed(({ theme, ...props }) => {
  const customer = useCustomer();

  return (
    <Box
      data-comp={CtaAccount.displayName}
      {...props}
      sx={{
        ...props.sx,
        ...theme.cta,
      }}
    >
      {customer ? (
        <Link href="/account">
          <Svg
            alt="View account"
            src="/svgs/cta/account.svg#account"
            viewBox="0 0 48 48"
            sx={theme.cta.icon}
          />
        </Link>
      ) : (
        <Link href="/account/login">
          <Svg
            alt="Login"
            src="/svgs/cta/login.svg#login"
            viewBox="0 0 48 48"
            sx={theme.cta.icon}
          />
        </Link>
      )}
    </Box>
  );
});

CtaAccount.displayName = 'CtaAccount';
