import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      height: '100%',
      width: '100%',
    };
  })()
);
