export const colors = {
  black: '#1a1919',
  text: '#0C0C0C',
  white: '#FFFFFF',
  cream: '#fbf2ec',
  background: '#FFFFFF',

  // Grays
  lightGray: '#DFE3E7',
  gray: '#C4C4C4',
  grayTwo: '#616571',

  // Primary
  green: '#003E3C',
  darkGreen: '#0c3f3b',
  blueGreen: '#005f5c',
  yellow: '#f9de58',
  brown: '#382f2d',
  craft: '#bd8053',
  red: '#a93935',
  secondRed: '#943638',
  tan: '#f4e6dc',

  blue: '#007A99',
  orange: '#E87A3E',
  coffee: '#7F6952',
  darkCream: '#F5E7DD',

};
