import Head from 'next/head';

function HeadMetas() {
  return (
    <Head>
      <link
        rel="icon"
        type="image/x-icon"
        href="https://cdn.shopify.com/s/files/1/1418/5086/files/WB_square_bear_32x32.png?v=1562594240"
      />
      <meta
        key="viewport-meta"
        name="viewport"
        content="width=device-width, initial-scale=1"
      />
      <meta key="description" name="description" content="A storefront site" />
      <meta
        key="http-equiv"
        httpEquiv="Content-Type"
        content="text/html; charset=utf-8"
      />
      <meta key="shortcut-icon" href="/favicon.png" />

      {/* Project specific */}
      <meta key="robots" name="robots" content="index,follow" />
      <meta key="google-robots" name="googlebot" content="index,follow" />
      <meta
        key="subject"
        name="subject"
        content="PackDigital backpack storefront"
      />
      <meta
        key="keywords"
        name="keywords"
        content="PackDigital backpack storefront"
      />
      <meta key="author" href="https://packdigital.com" />
      <meta key="canonical" href="https://storefront.packdigital.com" />
      <meta key="default-generator" content="Backpack Storefront" />
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-P3BDF84');`,
        }}
      />

      <script
        type="module"
        dangerouslySetInnerHTML={{
          __html: ` try {
    const settings = {};
    const config = (await import("https://shopify-gtm-suite.getelevar.com/configs/a5550cef3650774221d9ea1c322c1ce5041009b0/config.js")).default;
    const scriptUrl = settings.proxyPath
      ? settings.proxyPath + config.script_src_custom_pages_proxied
      : config.script_src_custom_pages;

      if (scriptUrl) {
        const { handler } = await import(scriptUrl);
        await handler(config, settings);
      }
    } catch (error) {
    console.error("Elevar Error:", error);
    }`,
        }}
      />
      <script
        type="module"
        dangerouslySetInnerHTML={{
          __html: ` window.ElevarDataLayer = window.ElevarDataLayer ?? [];`,
        }}
      />
      <script
        type="module"
        dangerouslySetInnerHTML={{
          __html: `let userProperties = {}; 
                    window.ElevarDataLayer.push({
	                  event: "dl_user_data",
	                  user_properties: userProperties 
                    });`,
        }}
      />
    </Head>
  );
}

export default HeadMetas;
