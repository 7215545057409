import { useState, useEffect } from 'react';
import { Flex, Box, Text, Paragraph, Heading } from 'theme-ui';
import { Markdown, Svg, } from '@snippets';
import { colors } from '@theme/theme.colors';
import dynamic from 'next/dynamic';

import { themed } from './Ingredients.theme';

const Image = dynamic(() => import('../../Image').then((module) => module.Image), {
  ssr: false,
  suspense: false,
});

export const Ingredients = themed(({ theme, flavor, selectedSwatch }) => {
  return (
    <Flex data-comp={Ingredients.displayName} sx={theme.container}>
      <Heading variant="text.h3" sx={{ px: 4, textTransform: 'uppercase', fontFamily: 'gtPressura', mb: '1.25rem' }}>
        {flavor?.modalTitle}
      </Heading>
      <Box sx={{
        width: '100%',
        maxHeight: '50vh',
        overflowX: 'hidden',
        overflowY: 'auto',
        borderTop: `1px solid ${colors.black}`,
        borderBottom: `1px solid ${colors.black}`,
        px: '0.9375rem',
        py: '1.25rem'
      }}>
        <Heading variant="text.h3" sx={{ textTransform: 'uppercase', fontFamily: 'gtPressura' }}>
          {selectedSwatch?.title}
        </Heading>
        {selectedSwatch?.ingredients?.map((ingredient) => (
          <Flex sx={{ flexDirection: 'column', my: '1.25rem' }}>
            <Heading variant="text.h5" sx={{ textTransform: 'uppercase', fontFamily: 'gtPressura', fontSize: '15px', fontWeight: 700, marginBottom: '0.625rem' }}>
              {ingredient?.title}
            </Heading>
            <Text>
              {ingredient?.description}
            </Text>
            {ingredient?.image &&
              <Image image={ingredient?.image}></Image>
            }
          </Flex>
        ))}
      </Box>
      {flavor?.productIcons?.length > 0 &&
        <Flex sx={{ justifyContent: 'space-around', alignItems: 'start', width: '100%', mt: ['1rem', '2rem'], flexWrap: 'wrap', rowGap: '16px' }}>
          {flavor?.productIcons?.map((productIcon) => (
            <Flex sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: '6px', minWidth: '100px' }}>
              <Image style={{
                width: '60px',
                height: '60px'
              }} image={productIcon?.image} />
              <Text sx={{ fontSize: '16px', color: colors.blueGreen, lineHeight: '20px', textAlign: 'center' }}>{productIcon?.iconText}</Text>
            </Flex>
          ))}
        </Flex>
      }
    </Flex>
  );
});

Ingredients.displayName = 'Ingredients';
