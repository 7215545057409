import { create } from '@theme/create';


export const themed = create(
  new (function () {
    this.container = {
      display: 'flex',
      alignItems: 'start',
      flexDirection: 'column',
      mt: [12]
    };
    this.heading = {
      variant: 'text.nav',
      pb: 4,
    };
  })()
);
