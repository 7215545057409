import { section } from '@settings/common/section';
import { align } from '@settings/common/align';
import { color } from '@settings/common/color';
import { icons } from '@settings/common/icons';

export function Schema({ article, blog, collection }) {
  if (article || blog || collection) return null;

  return {
    label: 'Featured Icons',
    key: 'featured-icons',
    fields: [
      {
        label: 'Heading - Subheading',
        name: 'text',
        description: 'Heading,  subheading, ',
        component: 'group',
        fields: [
          {
            label: 'Heading',
            name: 'heading',
            description:
              'You can use markdown e.g. <u>**bold**</u> or # for heading',
            component: 'markdown',
          },
          {
            label: 'Heading Color',
            name: 'headingColor',
            component: 'select',
            options: color.options,
          },
          {
            label: 'Subheading',
            name: 'subheading',
            component: 'text',
          },
          {
            label: 'Subheading Color',
            name: 'subheadingColor',
            component: 'select',
            options: color.options,
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'textAlign',
            description: "Doesn't apply on desktop when group list is inline",
            label: 'Heading alignment',
            options: align.options,
          },
          {
            label: 'Max Width',
            name: 'maxWidth',
            component: 'number',
            description: 'Set max width for heading and subheading',
            defaultValue: 600,
            validate: (maxWidth) => {
              if (maxWidth <= 0)
                window.__bpCMS.alerts.error('Max width must be greater than 0');
            },
          },
        ],
        defaultValue: {
          heading: '',
          headingColor: color.value.black,
          subheading: '',
          subheadingColor: color.value.orange,
          textAlign: align.value.center,
          maxWidth: 600,
        },
      },
      {
        label: 'Featured Items',
        name: 'content',
        component: 'group',
        fields: [
          {
            label: 'Items',
            name: 'items',
            description: 'You can add title, description and icon',
            component: 'group-list',
            itemProps: {
              label: '{{item.title}}',
            },
            validate(list) {
              if (list.length > 4) {
                window.__bpCMS.alerts.error(`Max of 4 tiles only.`);
              }
            },
            defaultItem: {
              title: 'SAVE 10% ON EVERY ORDER',
              description:
                'We’ll reward your commitment to caffeination with a 10% discount on every order.',
              icon: icons.value.star,
            },
            fields: [
              {
                label: 'Title',
                name: 'title',
                component: 'text',
              },
              {
                label: 'Description',
                name: 'description',
                component: 'textarea',
              },
              {
                label: 'Icon',
                name: 'icon',
                component: 'select',
                description: 'Select the icon',
                options: icons.options,
                defaultValue: icons.value.star,
              },
            ],
            defaultValue: [
              {
                title: 'READY WITH NO WORK',
                description: '',
                icon: icons.value.happy,
              },
              {
                title: '150 MG CAFFEINE PER GLASS',
                description: '',
                icon: icons.value.strong,
              },
              {
                title: 'STAYS FRESH AFTER OPENING',
                description: '',
                icon: icons.value.leaves,
              },
            ],
          },
        ],
      },

      {
        ...section,
        defaultValue: {
          mobile: {
            container: 'container',
            mt: 'none',
            py: 's',
          },
          desktop: {
            container: 'contentMedium',
            mt: 'none',
            py: 's',
          },
        },
      },
    ],
  };
}
