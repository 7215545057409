import { Paragraph } from 'theme-ui';
import { themed } from './Collection.theme';

export const Collection = themed(
  ({ theme, collection, goToResultAndToggle }) => {
    return (
      <Paragraph
        data-comp={Collection.displayName}
        key={collection.id}
        onClick={() => goToResultAndToggle(collection.url)}
        sx={theme.title}
      >
        {collection.title}
      </Paragraph>
    );
  }
);

Collection.displayName = 'Collection';
