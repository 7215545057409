import { Box } from 'theme-ui';

import { Search, ItemsOrEmpty } from '@snippets';
import { useSidebar } from '@hooks';

import { themed } from './Content.theme';

export const Content = themed(({ theme, ...props }) => {
  const [{ isCart, isSearch }] = useSidebar();

  // Cart ItemsOrEmpty or Search — side drawers
  return (
    <Box data-comp={Content.displayName} {...props} sx={theme.wrapper}>
      {isCart ? <ItemsOrEmpty /> : null}

      {isSearch ? <Search /> : null}
    </Box>
  );
});

Content.displayName = 'Content';
