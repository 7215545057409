import { Box } from 'theme-ui';

import { Svg } from '@snippets';

import { colors } from '@theme/theme.colors';
import { useMenuSidebar } from '../../MenuSidebar';
import { themed } from '../Menu.theme';

export const CtaMenu = themed(({ theme, ...props }) => {
  const [{ menuSidebar }, { toggleSidebar }] = useMenuSidebar();

  return (
    <Box
      data-comp={CtaMenu.displayName}
      {...props}
      onClick={toggleSidebar}
      sx={{
        ...props.sx,
        ...theme.cta,
      }}
    >
      <Box
        sx={{
          margin: 0,
          padding: 0,
          border: 0,
          fontSize: '100%',
          verticalAlign: 'baseline',
          color: 'inherit',
          mt: '1rem',
          '> span': {
            backgroundColor: menuSidebar ? colors.yellow : 'currentcolor',
            display: 'block',
            height: '0.125rem',
            position: 'relative',
            top: 0,
          },
        }}
      >
        <Box
          as="span"
          sx={{
            marginBottom: menuSidebar ? '-0.5rem' : '0.4rem',
            width: '1.4rem',
            transform: menuSidebar && 'rotate(45deg)',
          }}
        />
        <Box
          as="span"
          sx={{
            marginBottom: '0.4rem',
            width: '1.4rem',
            opacity: menuSidebar && '0',
            transitionDelay: menuSidebar && '0s',
          }}
        />
        <Box
          as="span"
          sx={{
            marginBottom: '0.4rem',
            width: '1.4rem',
            top: menuSidebar && '-0.175rem',
            transform: menuSidebar && 'rotate(-45deg)',
          }}
        />
      </Box>
      {/* {menuSidebar ? (
        <Svg
          alt="Close menu"
          src="/svgs/close.svg#close"
          sx={theme.cta.icon}
          viewBox="0 0 48 48"
        />
      ) : (
        <Svg
          alt="Open menu"
          src="/svgs/cta/menu.svg#menu"
          sx={theme.cta.icon}
          viewBox="0 0 48 48"
        />
      )} */}
    </Box>
  );
});

CtaMenu.displayName = 'CtaMenu';
