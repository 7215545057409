import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      mb: [2, 4],
    };
    this.heading = {
      variant: 'text.nav',
      pb: 4,
    };
  })()
);
