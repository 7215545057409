import { AtcBlock } from './AtcBlock';
import { AtcBlockSubscribe } from './AtcBlockSubscribe';
import { AtcBlockInveterate } from './AtcBlockInveterate';
import { AtcBlockQuantified } from './AtcBlockQuantified';
import { Faq } from './FAQ';
import { FeaturedIcons } from './FeaturedIcons';
import { HalfMedia } from './HalfMedia';
// import { Hero } from './Hero';
import { HeroMain } from './HeroMain';
import { HeroVideo } from './HeroVideo';
import { IconsRow } from './IconsRow';
import { Image } from './Image';
import { LogoBar } from './LogoBar';
import { Markdown } from './Markdown';
import { ReviewSlider } from './ReviewSlider';
import { Reviews } from './Reviews';
// import { ProductRecs } from './ProductRecs';
import { ProductRecsCustom } from './ProductRecsCustom';
import { ProductReviews } from './ProductReviews';
import { ProductBanner } from './ProductBanner';
import { ProductsSlider } from './ProductsSlider';
import { TestimonialsSlider } from './TestimonialsSlider';
import { FourTile } from './FourTile';
import { Typeform } from './Typeform';
import { TwoColumn } from './TwoColumn';
import { TwoTile } from './TwoTile';

export default [
  AtcBlock,
  AtcBlockSubscribe,
  AtcBlockInveterate,
  AtcBlockQuantified,
  Faq,
  FeaturedIcons,
  HalfMedia,
  // Hero,
  HeroMain,
  HeroVideo,
  Image,
  IconsRow,
  LogoBar,
  Markdown,
  ReviewSlider,
  Reviews,
  // ProductRecs,
  ProductReviews,
  ProductRecsCustom,
  ProductBanner,
  ProductsSlider,
  TestimonialsSlider,
  FourTile,
  TwoColumn,
  TwoTile,
  Typeform,
];
