import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      variant: 'layout.content',
      py: [15, 16, 17, 18],
      div: {
        fontFamily: '"GT Pressura", sans-serif !important',
      },
      '.oke-w-review-side': {
        backgroundColor: 'transparent !important',
        borderStyle: 'none !important',
        flexBasis: 'unset !important',
        padding: '24px 0px !important',
      },
      '.oke-w-review-main': {
        padding: '0 !important',
      },
      '.oke-reviewContent-title': {
        fontFamily: '"GT Pressura Bold", sans-serif !important',
        fontSize: '18px',
      },
      '.oke-w-reviewer-avatar': {
        display: 'none',
      },
      '.oke-w-reviewer': {
        '> div:nth-child(2)': { display: 'flex', gap: '12px' },
        fontSize: '14px !important',
      },
      '.oke-w-reviewer-verified': {
        display: 'flex',
        flexDirection: 'row-reverse',
        gap: '4px',
      },
      '.oke-w-review-recommendation': {
        fontSize: '14px',
        display: 'flex',
        gap: '4px',
        ':before': {
          content: '"Bottom Line:"',
          display: 'inline',
          fontFamily: '"GT Pressura Bold", sans-serif !important',
        },
      },
      '.oke-w-reviewerRecommendation': {
        ':before': {
          display: 'none !important',
        },
      },
      '.oke-w-review': {
        flexDirection: 'column !important',
      },
      '.oke-w-reviews-list-item, .oke-w-controls-nav': {
        borderBottomColor: '#C4C4C4 !important',
      },
      '.oke-w-reviews-main--nav-divider, .oke-w-review-reply': {
        borderTopColor: '#C4C4C4 !important',
      },
      '.oke-w-reviews-filterToggle, .oke-w-writeReview, .oke-showMore-button, .oke-w-filterOption-label':
        {
          backgroundColor: 'transparent !important',
          borderColor: '#C4C4C4 !important',
          color: 'black !important',
        },
    };

    this.heading = {
      variant: 'text.h2',
      textAlign: 'center',
      mb: [12, 14, 16],
    };

    this.spinnerWrapper = {
      width: '100%',
      height: '400px',
      position: 'relative',
    };

    this.spinner = {
      width: '30px',
      color: 'gray',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    };
  })()
);
