import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      position: 'relative',
      width: '100%',
    };

    this.input = {
      variant: 'text.base',
      py: 5,
      pl: 5,
      pr: 13,
      borderRadius: 0,
      m: 0,
      bg: 'background',
      border: 'none',
      '::placeholder': {
        color: 'black',
        variant: 'text.base',
      },
    };

    this.button = {
      variant: 'buttons.plain',
      position: 'absolute',
      right: 0,
      top: 0,
      height: '100%',
      minWidth: 'unset',
      px: 3,
    };

    this.icon = {
      color: 'text',
      width: '24px',
    };
  })()
);
