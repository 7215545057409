import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.bar = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      color: 'white',
      p: 8,
    };
    this.slider = {
      width: '100%',
      // height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // pt: [72, 20],
    };
    this.thumbsSwiper = (itemsLength) => ({
      minHeight: 100,
      mt: '1rem',
      maxWidth: [`${itemsLength + 10}rem`, `${itemsLength}rem`],
      '.swiper-slide ': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        flexBasis: '50%',
      },
      '.swiper-wrapper': {
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      '.swiper-thumbs': {
        my: 18,
      },
    });
  })()
);
