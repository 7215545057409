import { Fragment, useRef, useState, useEffect } from 'react';
import { Container } from 'theme-ui';
import { useRouter } from '@backpackjs/storefront';

import { useSetHeaderHeightCssVar } from '@hooks';

import { PromoBar } from './PromoBar';
import { Menu } from './Menu';
import { MenuSidebarShell, useMenuSidebar } from './MenuSidebar';
import { MenuDrawerShell } from './MenuDrawer';
import { themed } from './Header.theme';

export const Header = themed(({ theme }) => {
  const { isPreview } = useRouter();
  const headerRef = useRef(null);
  const [{ menuSidebar }, {}] = useMenuSidebar();

  const [isFixed, setIsFixed] = useState(false);

  const isSelected = menuSidebar || isFixed;
  // Sets --header-height CSS var
  useSetHeaderHeightCssVar({ headerRef });

  const stickNavbar = () => {
    if (window !== undefined) {
      window.pageYOffset > 50 ? setIsFixed(true) : setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);

  return (
    <>
      <PromoBar />

      <Container
        data-comp={Header.displayName}
        as="header"
        ref={headerRef}
        sx={
          isPreview
            ? theme.inCustomizer(isSelected)
            : theme.inProduction(isSelected)
        }
      >
        <Menu isFixed={isSelected} />
        <MenuSidebarShell />

        <MenuDrawerShell />
      </Container>
    </>
  );
});

Header.displayName = 'Header';
