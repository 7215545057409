import { useState, useEffect, useCallback } from 'react';
import { Flex, Spinner } from 'theme-ui';
import dynamic from 'next/dynamic';
import { useModal } from '@hooks';
import { useProductByHandle } from '@backpackjs/storefront';

import { themed } from './Content.theme';
import { ContentComponent } from './ContentComponent';
import { Ingredients } from './Swatches/Ingredients';
import SelectedVariant from '@store/SelectedVariant';

const Image = dynamic(() => import('./Image').then((module) => module.Image), {
  ssr: false,
  suspense: false,
});

export const Content = themed(({ theme, content }) => {
  const {
    subscription,
    variants: { variants, flavor },
  } = content;

  const [allSwatches, setAllSwatches] = useState([]);
  const [selectedSwatch, setSelectedSwatch] = useState(variants?.variants[0]);
  const [selectedPosition, setSelectedPosition] = useState(1);

  const { product } = useProductByHandle({
    handle: selectedSwatch?.product?.handle,
  });

  const [selectedSubscription, setSelectedSubscription] = useState(
    subscription?.default
  );

  const handleSubscriptionSelect = (isSubscription) => {
    console.log(isSubscription)
    setSelectedSubscription(isSubscription);
  };

  const handleSwatchSelect = (color, swatchTitle) => {
    const swatchSelected = allSwatches.map((variant) =>
      (variant?.color === color && variant.title === swatchTitle) ||
      variant.id === swatchTitle
        ? {
            ...variant,
            isSelected: true,
          }
        : {
            ...variant,
            isSelected: false,
          }
    );

    const currentVariant = allSwatches.filter(
      ({ title, id }) => title === swatchTitle || id === swatchTitle
    )[0];
    setSelectedSwatch(currentVariant);
    setAllSwatches(swatchSelected);
  };

  const handleSwatchSelector = (selected) => {
    let defaultSwatches = allSwatches;

    if (defaultSwatches.length == 0) {
      defaultSwatches = variants?.variants;
    }

    const swatchSelected = defaultSwatches.map((variant) =>
    (variant?.color === selected?.color && variant?.title === selected?.title)
      ? {
          ...variant,
          isSelected: true,
        }
      : {
          ...variant,
          isSelected: false,
        }
    );
    const currentVariant = defaultSwatches.find(
      (_variant) => _variant.title === selected.title
    );
    setSelectedSwatch(currentVariant);
    setAllSwatches(swatchSelected);
  }

  useEffect(() => {
    const initialAllSwatches = variants?.variants.map((swatches, index) =>
      index === 0
        ? { ...swatches, isSelected: true }
        : { ...swatches, isSelected: false }
    );
    setAllSwatches(initialAllSwatches);
    setSelectedSwatch(variants?.variants[0]);
  }, [variants?.variants]);

  const [{ modal }, { openModal, closeModal }] = useModal();

  const toggleIngredientsModal = () => {
    if (modal) {
      closeModal();
      return;
    }

    openModal(<Ingredients flavor={flavor} selectedSwatch={selectedSwatch} />);
  };

  if (!product || !product?.sellingPlanGroups?.length) return null;
  return (
    <ContentComponent
      content={content}
      product={product}
      selectedSwatch={selectedSwatch}
      allSwatches={allSwatches}
      handleSwatchSelect={handleSwatchSelect}
      handleSwatchSelector={handleSwatchSelector}
      toggleIngredientsModal={toggleIngredientsModal}
      handleSubscriptionSelect={handleSubscriptionSelect}
      selectedSubscription={selectedSubscription}
      selectedPosition={selectedPosition}
      setSelectedPosition={setSelectedPosition}
    />
  );
});

Content.displayName = 'Content';
