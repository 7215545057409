import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.closed = {
      zIndex: 40,
      position: 'fixed',
      p: [10, 19, 12],
      py: [12, 12, 15],
      top: ['3.5625rem', '30%', '50%'],
      left: '50%',
      transform: [
        'translate(-50%)',
        'translate(-50%)',
        'translateX(-50%) translateY(-50%)',
      ],
      bg: 'cream',
      opacity: 0,
      pointerEvents: 'none',
      minWidth: ['90%', '60rem'],
      height: ['calc(100% - 50px)', 'initial'],
      overflow: 'auto',
      width: ['100%', '80%'],
    };

    this.open = {
      ...this.closed,
      opacity: 1,
      pointerEvents: 'auto',
    };

    this.close = {
      position: ['relative', 'absolute', 'absolute'],
      display: 'flex',
      flexDirection: 'row-reverse',
      cursor: 'pointer',
      right: [null, '4rem', '12px'],
      top: [null, 1, 0],
      height: ['2rem', '4rem'],
    };

    this.icon = {
      width: [40, 60, 40],
      cursor: 'pointer',
    };

    this.contentHidden = {
      opacity: 0,
      willChange: 'opacity',
      transition: 'opacity .3s ease-in-out',
    };

    this.contentVisible = {
      ...this.contentHidden,
      opacity: 1,
      color: 'brown',
    };
  })()
);
