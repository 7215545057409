export const fonts = {
  options: [
    { label: 'GT Pressura', value: 'gtPressura' },
    { label: 'Tablet Gothic', value: 'tabletGothic' },
  ],
  value: {
    gtPressura: 'gtPressura',
    tabletGothic: 'tabletGothic',
  },
};
