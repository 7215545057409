import { Fragment } from 'react';
import { Heading, Text, Flex } from 'theme-ui';
import { useCartCount } from '@backpackjs/storefront';

import { Svg } from '@snippets';
import { useSidebar } from '@hooks';

import { themed } from './Header.theme';

export const Header = themed(({ theme, ...props }) => {
  const cartCount = useCartCount();
  const [
    { isCart, isSearch },
    { closeSidebar, openSearchSidebar, openCartSidebar },
  ] = useSidebar();

  // Cart or Search — side bars
  return (
    <Flex
      data-comp={Header.displayName}
      {...props}
      sx={{
        ...props.sx,
        ...theme.wrapper,
      }}
    >
      <Svg
        viewBox="0 0 48 48"
        alt={isCart ? 'Close Cart' : 'Close Search'}
        src="/svgs/chevron/left.svg#left"
        onClick={closeSidebar}
        sx={{
          ...theme.icon,
          ...theme.icon.left,
        }}
      />

      <Heading sx={theme.heading}>
        <Text>{isCart ? 'CART' : 'SEARCH'}</Text>

        {isCart && cartCount ? (
          <Text sx={theme.heading.count}>({cartCount})</Text>
        ) : null}
      </Heading>

      {/* Search or Cart Toggle icon */}
      <>
        {isCart ? (
          <Svg
            alt="View search"
            onClick={openSearchSidebar}
            src="/svgs/cta/search.svg#search"
            viewBox="0 0 48 48"
            sx={{
              ...theme.icon,
              ...theme.icon.right,
            }}
          />
        ) : null}

        {isSearch ? (
          <Svg
            alt="View cart"
            onClick={openCartSidebar}
            src={
              cartCount
                ? '/svgs/cta/cart.svg#cart'
                : '/svgs/cta/cart-empty.svg#cart-empty'
            }
            viewBox="0 0 48 48"
            sx={{
              ...theme.icon,
              ...theme.icon.right,
            }}
          />
        ) : null}
      </>
    </Flex>
  );
});

Header.displayName = 'Header';
