import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.tile = {
      position: 'relative',
    };

    this.tileLink = {
      ...this.tile,
      img: {
        transform: 'scale(1.01)',
        transition: 'transform 0.3s ease-in-out',
      },
      ':hover': {
        img: { transform: 'scale(1.03)' },
      },
    };

    this.contentRelative = {
      flexDirection: 'column',
      position: 'relative',
      mt: 8,
      py: [10, 0],
    };

    this.contentAbsolute = {
      ...this.contentRelative,
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      mt: 0,
      p: [10, 10, 14],
      pointerEvents: 'none',
    };

    this.heading = {
      mb: 4,
      variant: 'text.h4',
    };

    this.body = {
      variant: 'text.sm',
    };

    this.button = {
      mt: 8,
      pointerEvents: 'auto',
    };
  })()
);
