import { create } from '@theme/create';

export const themed = create(
  new (function () {
    (this.sidebar = {
      ctas: {
        variant: 'flex.row.between.center',
        mb: 0,
      },

      link: {
        variant: 'text.link.2',
        display: 'block',
        cursor: 'pointer',
      },
    }),
      // cart empty — rendered in /cart
      (this.page = {
        ctas: {
          ...this.sidebar.ctas,
          mb: [8, null, 10],
        },

        link: {
          ...this.sidebar.link,
          display: 'none',
        },
      });
  })()
);
