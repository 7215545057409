export const fonts = {
  body: '"GT Pressura", system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
  gtPressura:
    '"GT Pressura", system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
  gtPressuraBold:
    '"GT Pressura Bold", system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
  tabletGothic:
    '"Tablet Gothic Eb", system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
  gothicCondensed:
    '"Tablet Gothic Condensed", system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
};
