import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    };

    this.heading = {
      variant: 'text.h2',
      textAlign: 'center',
      fontSize: ['3.625rem', '6.75rem'],
      lineHeight: ['80px', '118px'],
      fontFamily: 'gothicCondensed',
      fontWeight: 800,
      py: 0,
      mb: [10, 0],
    };

    this.content = {
      position: 'relative',
      width: '100%',
      minHeight: '150px',
    };

    this.spinner = {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    };
  })()
);
