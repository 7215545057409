import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.media = {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    };
    this.roundImage = {
      position: 'absolute',
      width: ['120px', '160px'],
      height: ['120px', '160px'],
      borderRadius: '50%',
      overflow: 'hidden',
      right: ['50%', 0],
      top: ['initial', '50%'],
      bottom: ['0', 'initial'],
      transform: ['translate(50%, 50%)', 'translate(50%, -50%)'],
    };
  })()
);
