import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.title = {
      variant: 'text.xs',
      textDecoration: 'uppercase',
      fontWeight: 600,
      cursor: 'pointer',
      pt: 3,
      '&:hover': {
        textDecoration: 'underline',
      },
    };
  })()
);
