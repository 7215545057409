import { Flex, Box } from 'theme-ui';

import { NavItem } from './NavItem';
import { useMenu } from './useMenu';
import { themed } from './Menu.theme';

export const Nav = themed(({ theme, isFixed }) => {
  const { desktop } = useMenu();

  if (!desktop?.menu) return null;

  return (
    <Flex
      data-comp={Nav.displayName}
      as="ul"
      role="navigation"
      aria-label="Main menu"
      sx={theme.nav(isFixed)}
    >
      {desktop?.menu?.map((item, index) => (
        <Box as="li" key={index}>
          <NavItem key={item.id} item={item} index={index} isFixed={isFixed} />
        </Box>
      ))}
    </Flex>
  );
});

Nav.displayName = 'Nav';
