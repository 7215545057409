import account from './account';
import cart from './cart.js';
import footer from './footer.js';
import header from './header';
import search from './search.js';
import social from './social.js';
import product from './product';

export default [
  // account,
  // cart,
  footer,
  header,
  product,
  // search,
  social,
];
