import { Container, Heading, Box, Flex } from 'theme-ui';

import { Section } from '@snippets';
import { pxWithFullBleed } from '@utils/common';
import { withInView } from '@utils';

import { Schema } from './ProductRecsCustom.schema';
import { themed } from './ProductRecsCustom.theme';
import { Product } from './Product';

export const ProductRecsCustom = withInView(
  themed(({ theme, cms }) => {
    const { heading, subheading, textAlign, products, section } = cms;
    return (
      <Section section={section}>
        <Container data-comp={ProductRecsCustom.displayName} sx={theme.wrapper}>
          <Box
            sx={{
              width: '100%',
              margin: 'auto',
              position: 'relative',
              top: 0,
              left: 0,
              transform: 'translateY(-65px)',
              padding: ' 0 2.3%',
              '@media only screen and (min-width: 64em)': {
                transform: 'translateY(-95px)',
                padding: ' 0 9%',
              },
            }}
          >
            <Heading
              sx={{
                ...theme.heading,
                textAlign,
                ...pxWithFullBleed(section),
              }}
            >
              {heading}
            </Heading>
            <Heading
              sx={{
                display: ['none', null, 'block'],
                textTransform: 'uppercase',
                fontFamily: 'gtPressuraBold',
                textAlign: 'center',
                fontWeight: 700,
                mt: '1.5rem',
                '@media only screen and (min-width: 64em)': {
                  fontSize: '2.2222222vw',
                },
                '@media only screen and (min-width: 112.5em)': {
                  fontSize: '40px',
                },
              }}
            >
              {subheading}
            </Heading>
          </Box>
          <Flex
            sx={{
              display: 'flex',
              width: '100%',
              flexDirection: ['column', 'row'],
              justifyContent: 'space-between',
            }}
          >
            {products &&
              products.length > 0 &&
              products.map(({ cta, product, style }, index) => (
                <Product
                  key={index}
                  product={product}
                  cta={cta}
                  style={style}
                />
              ))}
          </Flex>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

ProductRecsCustom.displayName = 'ProductRecsCustom';
ProductRecsCustom.Schema = Schema;
