export const icons = {
  options: [
    { label: 'none', value: 'none' },
    {
      label: 'star',
      value: '/svgs/star.svg#star',
    },
    {
      label: 'strong',
      value: '/svgs/strong.svg#strong',
    },
    {
      label: 'leaves',
      value: '/svgs/leaves.svg#leaves',
    },
    {
      label: 'happy',
      value: '/svgs/happy.svg#happy',
    },
  ],
  value: {
    none: 'none',
    star: '/svgs/star.svg#star',
    strong: '/svgs/strong.svg#strong',
    leaves: '/svgs/leaves.svg#leaves',
    happy: '/svgs/happy.svg#happy',
  },
};
