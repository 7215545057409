import { Box } from 'theme-ui';
import { SubNav } from '../SubNav/SubNav';

import { themed } from './MenuDrawer.theme';

/**
 * Renders the desktop sub menu for a hovered item
 * in a vertical sliding drawer
 */
export const MenuDrawer = themed(({ theme, hovered, ...props }) => {
  return (
    <Box data-comp={MenuDrawer.displayName} {...props} sx={theme.drawer}>
      <SubNav
        id={hovered?.link?.text}
        links={hovered?.links || []}
        medias={hovered?.medias || []}
        products={hovered?.products || []}
        layout="horizontal"
      />
    </Box>
  );
});

MenuDrawer.displayName = 'MenuDrawer';
