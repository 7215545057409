import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.image = {
      mb: 8,
      bg: '#FAFAFA',
    };

    this.spinner = {
      width: '30px',
      color: 'gray',
      m: 'auto',
    };
  })()
);
