import { Box } from 'theme-ui';

import { Svg } from '@snippets';

import { themed } from './Navigation.theme';

export const NavigationButton = themed(
  ({
    theme,
    src = '/svgs/black-arrow-left.svg#black-arrow-left',
    alt = 'Previous product',
    isNext = false,
    inactive,
    ...props
  }) => {
    return (
      <Box
        data-comp={NavigationButton.displayName}
        disabled={inactive}
        {...props}
        sx={{
          ...(isNext ? theme.buttonNext : theme.buttonPrev),
          opacity: inactive ? 0.3 : 1,
          ...props.sx,
        }}
      >
        <Svg
          alt={alt}
          src={src}
          viewBox="0 0 25 25"
          draggable={false}
          sx={theme.icon}
        />
      </Box>
    );
  }
);

NavigationButton.displayName = 'NavigationButton';
