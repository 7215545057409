import { useState, useEffect, useCallback } from 'react';
import { Flex, Box, Text } from 'theme-ui';

import { useModal } from '@hooks';
import { Swatch, Dropdown } from '@snippets';
import { colors } from '@theme/theme.colors';

import { themed } from './Swatches.theme';
import { VariantsSelector } from '../Variants/VariantsSelector/VariantsSelector';

export const Swatches = themed(
  ({ theme, swatches, swatchTitle, handleSelect, handleSwatchSelector, toggleIngredientsModal, selectedSwatch, ingredientsLinkText }) => {
    return (
      <Flex data-comp={Swatches.displayName} sx={theme.container}>
        <Flex
          sx={{ flexWrap: 'wrap', width: '100%', justifyContent: 'space-between', alignItems: 'center', mb: 8 }}
        >
          <Flex sx={{ alignItems: 'center' }}>
            <Text sx={{ fontSize: '18px', textTransform: 'uppercase', fontWeight: 700,  mr: 3 }}>{swatchTitle}</Text>
            <Text sx={{ display: ['block', 'none'], fontSize: '13px', fontWeight: 400, fontStyle: 'italic', letterSpacing: '0.39px', color: colors.grayTwo }}>{swatches.length} Flavors Available</Text>
          </Flex>
          <Text
            sx={{
              color: colors.grayTwo,
              cursor: 'pointer',
              textDecoration: 'underline',
              fontFamily: 'gtPressuraBold',
              fontSize: '14px',
              fontWeight: 700,
              textTransform: 'uppercase'
            }}
            onClick={toggleIngredientsModal}
          >
            {ingredientsLinkText}
          </Text>
        </Flex>
        <Box
          sx={{ display: ['block', 'none'], width: '100%' }}
        >
          <VariantsSelector handleSwatchSelector={handleSwatchSelector} swatches={swatches} selectedSwatch={selectedSwatch} />
        </Box>
        <Flex
          sx={{ display: ['none', 'flex'], flexWrap: 'wrap', width: '100%', justifyContent: 'start' }}
        >
          {swatches.map(({ color, image, title, isSelected, id }) => (
            <Box key={id} sx={{ maxWidth: '72px', minWidth: '72px' }}>
              <Swatch
                color={color}
                image={image}
                colorName={title || id}
                handleClick={handleSelect}
                size={2}
                isSelected={isSelected}
              />
              <Text sx={{ display: 'block', textAlign: 'center', fontWeight: `${isSelected? '700':'400'}` }}>{title}</Text>
            </Box>
          ))}
        </Flex>
      </Flex>
    );
  }
);

Swatches.displayName = 'Swatches';
