import { Flex, Text } from 'theme-ui';
import { Link } from '@snippets';
import { themed } from './AddItemToCart.theme';

export const AddItemToCart = themed(
  ({
    theme,
    selectedSubscription,
    selectedVariant,
    selectedPlanID,
    selectedPlanDiscount,
  }) => {
    const productId = selectedVariant?.legacyResourceId;
    const sellingPlan = selectedSubscription
      ? `&selling_plan=${selectedPlanID?.replace(
          'gid://shopify/SellingPlan/',
          ''
        )}`
      : '&';

    // let searchParms = new URLSearchParams(
    //   window.location.search || ''
    // ).toString();

    const totalBoxes = selectedVariant?.position;

    const discount = selectedPlanDiscount

    const showSubscriptionPrice =
      selectedVariant?.price
    return (
      <Flex data-comp={AddItemToCart.displayName} sx={theme.container}>
        <Link
          href={`https://wanderingbearcoffee.com/cart/add?id=${productId}&quantity=${selectedVariant?.position}${sellingPlan}`}
          tabIndex="-1"
          variant="buttons.secondary"
          sx={{
            cursor: 'pointer',
            width: '100%',
          }}
        >
          {selectedSubscription && showSubscriptionPrice ? (
            <Flex
              sx={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Text variant="text.h4">Subscribe</Text>
              <Flex sx={{ alignItems: 'center', fontWeight: 700 }}>
                <Text
                  variant="text.h4"
                  sx={{ textDecoration: 'line-through', mr: 10 }}
                >
                  {`$${Number(
                    selectedVariant?.price
                  ) * totalBoxes
                    } `}
                </Text>
                <Text variant="text.h2">
                  {`$${Number((selectedVariant?.price) - discount).toFixed(2) * totalBoxes} `}
                </Text>
              </Flex>
            </Flex>
          ) : (
            <Flex
              sx={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Text variant="text.h4">add to bag</Text>
              <Text variant="text.h2">
                ${Number(selectedVariant?.price) * totalBoxes}
              </Text>
            </Flex>
          )}
        </Link>
      </Flex>
    );
  }
);

AddItemToCart.displayName = 'AddItemToCart';
