import { useState } from 'react';
import { Box, Button, Container, Flex } from 'theme-ui';
import { withInView } from '@utils';
import { Link, Logo } from '@snippets';

import { Schema } from './LogoBar.schema';
import { themed } from './LogoBar.theme';

export const LogoBar = withInView(
  themed(({ theme, cms, inView }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
      <>
        <Container
          data-comp={LogoBar.displayName}
          sx={{ ...theme.bar({ inView, menuOpen }) }}
        >
          <Flex>
            {/* Mobile Menu Button */}
            <Button
              variant="buttons.menu"
              sx={{ ...theme.menuButtonMobile({ inView, menuOpen, cms }) }}
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <span />
              <span />
              <span />
            </Button>

            {/* Logo */}
            <Box sx={{ ...theme.logoContainer }}>
              <Link href="https://wanderingbearcoffee.com/" target="_blank">
                <Box sx={{ ...theme.logo({ inView, menuOpen, cms }) }}>
                  <Logo />
                </Box>
              </Link>
            </Box>

            {/* Desktop Menu */}
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Flex as="ul" sx={{ ...theme.menuDesktop({ inView, cms }) }}>
                {(cms?.menu || []).map((menuItem, index) => {
                  return (
                    <Box as="li" sx={{ ...theme.menuItemDesktop }} key={index}>
                      <Link key={menuItem.text} href={menuItem.url}>
                        {menuItem.text}
                      </Link>
                    </Box>
                  );
                })}
              </Flex>
            </Box>

            {/* CTA */}
            <Box sx={{ ...theme.ctaContainer }}>
              <Link
                variant="buttons.secondary"
                href={cms?.cta?.url}
                target="_blank"
                sx={{ ...theme.cta }}
              >
                {cms?.cta?.text}
              </Link>
            </Box>
          </Flex>
        </Container>

        {/* Mobile Menu */}
        <Container sx={{ ...theme.menuMobile({ menuOpen }) }}>
          {(cms?.menu || []).map((menuItem) => (
            <Link
              key={menuItem.text}
              href={menuItem.url}
              sx={{ ...theme.menuItemMobile }}
            >
              {menuItem.text}
            </Link>
          ))}
        </Container>
      </>
    );
  }),
  { triggerOnce: false }
);

LogoBar.displayName = 'LogoBar';
LogoBar.Schema = Schema;
