import { Box, Paragraph, Flex } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { Link } from '@snippets';

import { themed } from './DesktopMenu.theme';

export const DesktopMenu = themed(({ theme }) => {
  const settings = useSettings();
  const menu = settings?.footer?.menu;

  return (
    <Box data-comp={DesktopMenu.displayName} sx={theme.grid}>
      {menu?.map((menuItem, index) => {
        const items = menuItem?.subMenuItems;
        return (
          <Box key={index} sx={theme.menuItem}>
            <Paragraph variant="label.1" sx={theme.name}>
              {menuItem?.name}
            </Paragraph>

            {items?.length > 0 && (
              <Box as="ul" sx={theme.list}>
                {items.map((subMenuItem, index) => {
                  return (
                    <Flex as="li" key={index}>
                      <Link
                        prefetch={false}
                        href={subMenuItem.cta?.url}
                        sx={theme.link}
                      >
                        {subMenuItem?.cta?.text}
                      </Link>
                    </Flex>
                  );
                })}
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
});

DesktopMenu.displayName = 'DesktopMenu';
