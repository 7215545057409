import { Text } from 'theme-ui';

export function CalculatedPrice({
  selectedVariant,
  totalGlasses,
  subscribePrice,
  discount
}) {
  const totalBoxes = selectedVariant?.position;
  const totalPrice = subscribePrice
    ? Number((selectedVariant?.price) - discount)
    : Number(selectedVariant?.price);

  return (
    <Text
      sx={{
        variant: 'text.base',
        '@media only screen and (max-width: 479px)': {
          variant: 'text.sm',
        },
      }}
    >
      {`${totalGlasses} glasses | $${(
        (totalPrice * totalBoxes) /
        totalGlasses
      ).toFixed(2)}/glass `}
    </Text>
  );
}
