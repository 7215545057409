import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.sidebar = {
      quantity: {
        variant: 'flex.row',
        mt: 4,
        width: 'auto',
        height: '32px',
        border: 'none',
        'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        'input[type=number]': {
          MozAppearance: 'textfield',
        },

        item: {
          variant: 'flex.row.center',

          input: {
            variant: 'text.sm',
            textAlign: 'center',
            px: 8,
            width: '40px',
            border: 'none',
          },
          icon: {
            cursor: 'pointer',
            width: '18px',
            height: '18px',
          },
        },
      },
    };

    // cart empty — rendered in /cart
    this.page = {
      quantity: {
        ...this.sidebar.quantity,

        item: {
          ...this.sidebar.quantity.item,

          input: {
            ...this.sidebar.quantity.item.input,
          },

          icon: {
            ...this.sidebar.quantity.item.icon,
          },
        },
      },
    };
  })()
);
