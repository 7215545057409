import Script from 'next/script';

function Libs() {
  return (
    <>
      <Script
        type="module"
        dangerouslySetInnerHTML={{
          __html: `console.log("body")`,
        }}
      />
      <Script
        type="module"
        dangerouslySetInnerHTML={{
          __html: `console.log("load")`,
        }}
      />
    </>
  );
}

export default Libs;
