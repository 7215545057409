import { useState, useEffect } from 'react';
import { Heading, Flex, Image } from 'theme-ui';

import { convertAlignToFlex } from '@utils/common';
import { Markdown, Link, Svg } from '@snippets';

import { themed } from './Content.theme';

export const Content = themed(({ theme, content }) => {
  const [searchParams, setSearchParams] = useState('');

  const textAlign = [content?.alignmentMob, content?.alignment];
  const flexAlign = [
    convertAlignToFlex(content?.alignmentMob),
    convertAlignToFlex(content?.alignment),
  ];

  const btnlink = content?.cta?.url;
  const hasHash = btnlink.includes('#');

  useEffect(() => {
    setSearchParams(
      new URLSearchParams(window.location.search || '').toString()
    );
  }, []);

  return (
    <Flex data-comp={Content.displayName} sx={theme.outer}>
      <Flex
        sx={{
          variant: 'flex.column',
          width: '100%',
          maxWidth: ['600px', '730px'],
          alignItems: flexAlign,
        }}
      >
        <Heading
          sx={{
            ...theme.heading,
            textAlign,
          }}
        >
          {content?.heading}
        </Heading>

        {content?.imageLogo && (
          <Image
            src={content?.imageLogo.src}
            alt={content?.heading}
            sx={{
              mt: 11,
            }}
          />
        )}

        {content?.body && (
          <Markdown
            text={content.body}
            textAlign={textAlign}
            sx={{
              ...(content?.heading
                ? theme.bodyWithHeading
                : theme.bodyWithoutHeading),
            }}
          />
        )}

        {content?.cta?.text && (
          <Link
            variant={`buttons.${content?.ctaStyle || 'underlineLink'}`}
            href={`${content?.cta?.url}${hasHash ? null : `?${searchParams}`}`}
            newTab={content?.cta?.newTab}
            sx={{
              mt: content?.heading || content?.body ? [10, 11] : 0,
              alignSelf: flexAlign,
            }}
          >
            {content?.cta?.text}
          </Link>
        )}

        {content?.showArrow && (
          <Link href="#IconsRow" scroll>
            <Svg
              alt="Scroll Down"
              viewBox="0 0 48 48"
              src="/svgs/chevron/down.svg#down"
              sx={{
                maxWidth: '50px',
                mt: 13,
              }}
            />
          </Link>
        )}
      </Flex>
    </Flex>
  );
});

Content.displayName = 'Content';
