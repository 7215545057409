import { Box, Container } from 'theme-ui';
import { isBrowser } from '@utils';

import { useMenuSidebar } from './useMenuSidebar';
import { themed } from './MenuSidebar.theme';
import { MenuSidebar } from './MenuSidebar';

/**
 * Mobile menu sidebar shell
 */
export const MenuSidebarShell = themed(({ theme, ...props }) => {
  const [{ menuSidebar }] = useMenuSidebar();

  return (
    <Box
      as="aside"
      data-comp={MenuSidebarShell.displayName}
      {...props}
      sx={{
        ...props.sx,
        ...theme.shell,
      }}
    >
      <Container
        data-comp={`${MenuSidebarShell.displayName}Scroll`}
        sx={theme.scroll}
      >
        {isBrowser && menuSidebar ? <MenuSidebar /> : null}
      </Container>
    </Box>
  );
});

MenuSidebarShell.displayName = 'MenuSidebarShell';
