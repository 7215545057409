import { useCallback } from 'react';
import { Flex, Spinner, Paragraph } from 'theme-ui';
import { useCountry, useCountries } from '@backpackjs/storefront';

import { Svg, Dropdown } from '@snippets';

import { themed } from './CountrySelector.theme';

export const CountrySelector = themed(({ theme, ...props }) => {
  const countries = useCountries();
  const { country, updateCountry, ...updateStatus } = useCountry();
  const singleCountry = countries?.length === 1;
  const haveCountries = countries?.length > 0;

  const updateSelectedCountry = useCallback(
    async (selected) => {
      const selectedCountry = countries.find(
        (_country) => _country.name === selected.name
      );
      await updateCountry({
        country: selectedCountry,
      });
    },
    [countries?.length]
  );

  return singleCountry ? null : haveCountries ? ( // only one country
    <Dropdown
      variant="countries"
      initial={country}
      onSelected={updateSelectedCountry}
      {...props}
      sx={{
        ...props.sx,
        ...theme.dropdown,
      }}
    >
      <Dropdown.Selected>
        {({ selecting, selected }) => (
          <Flex
            sx={{
              ...theme.item,
              bg: selecting ? 'lightGray' : 'background',
            }}
          >
            <Svg
              alt={selected.name}
              viewBox="0 85.333 512 341.333"
              src={selected.locale.flag}
              sx={theme.item.icon}
            />

            <Paragraph sx={theme.item.name}>{selected.name}</Paragraph>

            <Svg
              alt="Select a country"
              viewBox="0 0 48 48"
              src={
                selecting
                  ? '/svgs/chevron/up.svg#up'
                  : '/svgs/chevron/down.svg#down'
              }
              sx={theme.item.toggle}
            />
          </Flex>
        )}
      </Dropdown.Selected>

      {countries.map((_country) => (
        <Dropdown.Option key={_country.name} option={_country}>
          {/* todo: theme values do not auto refresh */}
          {({ selecting, isSelected, option }) =>
            isSelected ? null : (
              <Flex
                sx={{
                  ...theme.item,
                  bg: isSelected ? 'lightGray' : 'background',
                }}
              >
                <Svg
                  alt={option.name}
                  viewBox="0 85.333 512 341.333"
                  src={option.locale.flag}
                  sx={theme.item.icon}
                />

                <Paragraph sx={theme.item.name}>{option.name}</Paragraph>
              </Flex>
            )
          }
        </Dropdown.Option>
      ))}
    </Dropdown>
  ) : (
    <Spinner height="20px" />
  );
});

CountrySelector.displayName = 'CountrySelector';
