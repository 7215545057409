import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.buttonPrev = {
      variant: 'buttons.plain',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
      left: ['10px', '-16px'],
      right: 'auto',
      transition: 'opacity 0.2s ease-in-out',
    };

    this.buttonNext = {
      ...this.buttonPrev,
      left: 'auto',
      right: ['10px', '-16px'],
    };

    this.icon = {
      width: '30px',
      userSelect: 'none',
    };
  })()
);
