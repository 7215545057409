import { Box, Paragraph } from 'theme-ui';

import { useMenuDrawer } from '../MenuDrawer';
import { useMenuSidebar } from '../MenuSidebar';
import { themed } from './SubNav.theme';

export const SubNavLinks = themed(({ theme, links = [], ...props }) => {
  const [, { closeDrawerAndNavigate }] = useMenuDrawer();
  const [, { closeSideBarAndNavigate }] = useMenuSidebar();

  return (
    <Box
      data-comp={SubNavLinks.displayName}
      {...props}
      sx={{
        ...props.sx,
        ...theme.links,
      }}
    >
      {links.map((item, index) => {
        if (!item?.link?.text) return null;
        return (
          <Paragraph
            key={`SubNavLink${index}—${item.link.text}`}
            onClick={() => {
              closeDrawerAndNavigate(item); // desktop
              closeSideBarAndNavigate(item); // mobile
            }}
            sx={item.highlighted ? theme.link.highlighted : theme.link.regular}
          >
            {item.link.text}
          </Paragraph>
        );
      })}
    </Box>
  );
});

SubNavLinks.displayName = 'SubNavLinks';
