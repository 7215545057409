import { sectionWithHeight } from '@settings/common/section';

export const Schema = () => ({
  label: 'FAQ',
  key: 'faq',
  fields: [
    {
      name: 'heading',
      component: 'markdown',
      label: 'Heading',
      defaultValue: '# Frequently asked questions',
      description:
        'Ensure heading is "h1" by starting with "# " e.g. "# Shop All". Text below will become large and bold to indicate "h1".',
    },
    {
      name: 'questions',
      label: 'Questions',
      component: 'group-list',
      itemProps: {
        label: '{{item.question}}',
      },
      defaultItem: {
        question: 'make your question?',
        answer: "here's your answer",
      },
      fields: [
        {
          label: 'question',
          name: 'question',
          component: 'text',
        },
        {
          label: 'answer',
          name: 'answer',
          description:
            'You can use markdown e.g. <u>**bold**</u> or # for heading',
          component: 'markdown',
        },
      ],
      defaultValue: [
        {
          question: 'IS YOUR COFFEE ORGANIC?',
          answer:
            'Absolutely. 100% of our coffee is made with 100% organic beans. ',
        },
      ],
    },
    sectionWithHeight,
  ],
  defaultValue: [],
});
