import { Box } from 'theme-ui';
import dynamic from 'next/dynamic';

import { useMenuDrawer } from './useMenuDrawer';
import { themed } from './MenuDrawer.theme';
import { MenuDrawer } from './MenuDrawer';

/**
 * Desktop menu drawer shell — hovered item sub items
 */
export const MenuDrawerShell = themed(({ theme, props }) => {
  const [{ hovered }, { unHoverDrawer, hoverDrawer }] = useMenuDrawer();

  return (
    <Box
      data-comp={MenuDrawerShell.displayName}
      as="aside"
      {...props}
      onMouseEnter={hoverDrawer}
      onMouseLeave={unHoverDrawer}
      sx={{
        ...theme.shell,
        ...(hovered ? theme.shell.open : theme.shell.closed),
      }}
    >
      {hovered ? <MenuDrawer hovered={hovered} /> : null}
    </Box>
  );
});

MenuDrawerShell.displayName = 'MenuDrawerShell';
