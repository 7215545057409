import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.grid = {
      horizontal: {
        bg: 'black',
        columnGap: 10,
        gridTemplateColumns: ['1fr', null, '.25fr .75fr'],
        justifyContent: 'center',
        pb: 14,
        // pt: 12,
      },
      vertical: {
        bg: 'black',
        columnGap: 0,
        gridTemplateColumns: '1fr',
        gridGap: 0,
        px: 0,
        rowGap: 10,
      },
    };

    this.bestSeller = {
      position: 'relative',
      text: {
        mt: 14,
        display: ['block', null, 'none'],
      },
    };

    this.links = {};

    this.link = {
      regular: {
        // variant: 'text.navSubItem',
        fontFamily: 'gtPressuraBold',
        fontSize: '5.8666667vw',
        lineHeight: '8vw',
        color: colors.cream,
        py: '1rem',
        fontWeight: 700,
        textTransform: 'uppercase',
      },
      highlighted: {
        // variant: 'text.navSubItem',
        fontFamily: 'gtPressuraBold',
        fontSize: '5.8666667vw',
        lineHeight: '8vw',
        color: colors.cream,
        py: '1rem',
        fontWeight: 600,
        textTransform: 'uppercase',
        color: colors.cream,
      },
    };

    this.products = {
      gridTemplateColumns: ['1fr', null, 'repeat(3, 1fr)'],
      columnGap: [0, null, 10],
      gridColumn: [1, null, '2 / 3'],
    };

    this.productsSwiper = {
      ...this.products,
      '.swiper-container': {
        width: '100%',
      },
    };

    this.product = {
      maxWidth: ['100%', null, 306],
      cursor: 'pointer',

      picture: {
        width: '100%',
      },

      title: {
        variant: 'text.base',
        mt: [2, null, 3],
      },

      price: {
        variant: 'text.xs',
        mt: [1, null, 2],
      },
    };

    this.medias = {
      gridTemplateColumns: ['1fr', null, 'repeat(2, 306px)'],
      columnGap: [0, null, 10],
    };

    this.media = {
      maxWidth: ['100%', null, 306],
      cursor: 'pointer',

      picture: {
        width: '100%',
      },

      title: {
        variant: 'text.base',
        mt: [2, null, 3],
      },
    };
  })()
);
