// <Container> variants

export const layout = {
  container: {
    position: 'relative', // full-bleed
  },
  padded: {
    position: 'relative',
    px: [10, 11, 11, 12],
  },
  content: {
    position: 'relative',
    px: [10, 11, 11, 12],
    maxWidth: '90em', // 1440px
    mx: 'auto',
  },
  contentMedium: {
    position: 'relative',
    px: [10, 11, 11, 12],
    maxWidth: '76em', // 1216px
    mx: 'auto',
  },
  contentNarrow: {
    position: 'relative',
    px: [10, 11, 11, 12],
    maxWidth: '61em', // 976px
    mx: 'auto',
  },
  drawer: {
    position: 'relative',
    maxWidth: ['100%', null, '68.3125em'], // 1093px
    mx: 'auto',
    '@media only screen and (max-width: 68.3125em)': {
      padding: [8, 12],
    },
  },
};
