export default {
  label: 'Social Settings',
  name: 'social',
  component: 'group',
  fields: [
    {
      label: 'Social Links',
      name: 'links',
      component: 'group-list',
      itemProps: {
        label: `{{item.network}}:@{{item.handle}}`,
      },
      defaultItem: {
        network: 'facebook',
        handle: 'your-handle',
      },
      fields: [
        {
          name: 'network',
          component: 'select',
          label: 'Social Network',
          options: [
            {
              value: 'facebook',
              label: 'Facebook',
            },
            {
              value: 'instagram',
              label: 'Instagram',
            },
            {
              value: 'pinterest',
              label: 'Pinterest',
            },
            {
              value: 'twitter',
              label: 'Twitter',
            },
            {
              value: 'youtube',
              label: 'YouTube',
            },
            {
              value: 'vimeo',
              label: 'Vimeo',
            },
            {
              value: 'tiktok',
              label: 'TikTok',
            },
          ],
        },
        {
          label: 'Handle',
          name: 'handle',
          component: 'text',
        },
      ],
    },
  ],
};
