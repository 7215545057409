import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.loader = {
      variant: 'flex.row.center',
      height: `calc(var(--viewport-height) - var(--header-height) - var(--promoBar-height) - 200px)`,
    };
  })()
);
