import { useCallback } from 'react';
import { useRouter } from '@backpackjs/storefront';
import { useGotoRecoilSnapshot } from 'recoil'; // todo expose on @backpackjs/storefront

import animation from '@animations';
import store, { useRecoilCallback, useRecoilValue } from '@store';

const DEBUG = true;

export const useMenuDrawer = (cb = () => {}, data = []) => {
  const router = useRouter();
  const gotoSnapshot = useGotoRecoilSnapshot();
  const hovered = useRecoilValue(store.hoveredItem);
  const menuDrawer = useRecoilValue(store.menuDrawer);

  // on click, close the drawer and navigate to url
  const closeDrawerAndNavigate = useRecoilCallback(
    ({ snapshot }) =>
      async (item) => {
        const release = snapshot.retain();
        if (!item?.link?.url) return null;

        try {
          await animation.drawerContentOut();

          const updatedState = snapshot
            .map(({ set }) => set(store.hoveredItem, null))
            .map(({ set }) => set(store.menuDrawer, false));

          // update state
          gotoSnapshot(updatedState);

          await animation.drawerShellOut();
          router.push(item.link.url);
        } catch (error) {
          log('error', error.message);
        } finally {
          release();
        }
      }
  );

  // on link hover, clear a previous un-hover timer
  const clearUnHoverTimer = useCallback((id) => {
    if (window.unHover) {
      clearTimeout(window.unHover);
      window.unHover = null;
    }
  }, []);

  const hoverDrawer = useCallback(() => {
    clearUnHoverTimer();
  }, []);

  const hoverItem = useRecoilCallback(({ snapshot }) => async (hoveredItem) => {
    const release = snapshot.retain();

    try {
      let updatedState;

      if (hoveredItem) {
        clearUnHoverTimer();

        const { links = [], products = [], medias = [] } = hoveredItem;
        const hoveredSubLinks = [
          links?.length ?? null,
          products?.length,
          ...medias,
        ].filter(Boolean);
        const hoveredSubLinksCount = hoveredSubLinks.length;

        // if direct link close shell
        if (!hoveredSubLinksCount) {
          await animation.drawerShellOut();
          // console.log('direct link: closed drawer and setting hoverItem to', hoverItemData);
          updatedState = snapshot
            .map(({ set }) => set(store.hoveredItem, hoveredItem))
            .map(({ set }) => set(store.menuDrawer, false));
        } else {
          await animation.drawerShellIn();
          // console.log('nested link: opened drawer and setting hoverItem to', hoverItemData);
          updatedState = snapshot
            .map(({ set }) => set(store.hoveredItem, hoveredItem))
            .map(({ set }) => set(store.menuDrawer, true));
        }
      }
      // hoveredItem is null (reset)
      else {
        await animation.drawerShellOut();
        // console.log('no link: closed drawer and setting hoveredItem to', null)
        updatedState = snapshot
          .map(({ set }) => set(store.hoveredItem, hoveredItem))
          .map(({ set }) => set(store.menuDrawer, false));
      }

      // update state
      gotoSnapshot(updatedState);
    } finally {
      release();
    }
  });

  const unHoverItem = useCallback(() => {
    // if we have a previous un-hover we clear it
    clearUnHoverTimer();

    // we set a new un-hover timer, that can be cancelled
    // by the mouseEnter o another link or the MenuDrawer enter
    window.unHover = setTimeout(() => {
      hoverItem(null); // closes the drawer
      clearUnHoverTimer();
    }, 100);
    // console.log('created timer: via unHoverItem', JSON.stringify(window.unHover))
  }, []);

  const unHoverDrawer = useRecoilCallback(({ snapshot }) => () => {
    const release = snapshot.retain();

    // we set a new un-hover timer, that can be cancelled by the mouseEnter
    window.unHover = setTimeout(async function () {
      // console.log('executing timer: unHoverDrawer after 500ms setting hoveredItem to null')
      try {
        hoverItem(null);

        clearUnHoverTimer();
      } finally {
        release();
      }
    }, 100);

    // console.log('created timer: via unHoverDrawer', JSON.stringify(window.unHover))
  });

  return [
    // state
    {
      hovered, // used to trigger the drawer
      menuDrawer, // used for animations
    },
    // actions
    {
      hoverItem,
      hoverDrawer,
      unHoverItem,
      unHoverDrawer,
      closeDrawerAndNavigate,
    },
  ];
};
