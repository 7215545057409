import PropTypes from 'prop-types';
import { useRef, useEffect, useState } from 'react';
import { Box } from 'theme-ui';
import { VideoPlayButton } from './VideoPlayButton';
import { themed } from './VideoPlayer.theme';

/**
 * Video player component
 */
export const VideoPlayer = themed(
  /**
   * @param {import ('./types/videoPlayer.types').Props } props
   */
  ({
    styles,
    muted,
    playsInline,
    loop,
    autoPlay,
    controls,
    poster,
    source,
    type,
    title,
    showPlayButton,
    playButtonIcon,
    pauseButtonIcon,
    playIconStyle,
    playButtonStyle,
    viewBox,
    theme,
  }) => {
    const videoRef = useRef();
    const [isPlaying, setIsPlaying] = useState(autoPlay);

    const handlePlay = () => {
      setIsPlaying((isPlaying) => !isPlaying);
    };

    useEffect(() => {
      !isPlaying ? videoRef.current.pause() : videoRef.current.play();
    }, [isPlaying]);

    return (
      <Box
        data-comp={VideoPlayer.displayName}
        sx={{
          ...styles,
        }}
      >
        {showPlayButton && (
          <VideoPlayButton
            onClick={handlePlay}
            showIcon={showPlayButton}
            src={isPlaying ? pauseButtonIcon : playButtonIcon}
            styles={playButtonStyle}
            iconStyles={playIconStyle}
            viewBox={viewBox}
          />
        )}
        <video
          ref={videoRef}
          title={title}
          sx={{ ...theme.video }}
          muted={muted}
          playsInline={playsInline}
          loop={loop}
          autoPlay={isPlaying}
          controls={controls}
          poster={poster}
        >
          <source src={source} type={`video/${type}`} />
          Your browser does not support the <code>video</code> element.
        </video>
      </Box>
    );
  }
);

VideoPlayer.propTypes = {
  styles: PropTypes.object,
  muted: PropTypes.bool,
  playsInline: PropTypes.bool,
  loop: PropTypes.bool,
  autoPlay: PropTypes.bool,
  controls: PropTypes.bool,
  poster: PropTypes.string,
  source: PropTypes.string.isRequired,
  showPlayButton: PropTypes.bool,
  type: PropTypes.string,
  title: PropTypes.string,
  theme: PropTypes.object,
};

VideoPlayer.defaultProps = {
  muted: true,
  playsInline: true,
  loop: true,
  autoPlay: false,
  controls: false,
  poster: '',
  type: 'mp4',
  title: 'video media',
  showPlayButton: false,
};
VideoPlayer.displayName = 'VideoPlayer';
