import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.header = {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      mb: [13, 14],
      flex: 1,
      borderBottom: ` 1px solid ${colors.gray.mediumLight}`,
    };
    this.heading = {
      variant: 'text.nav',
      pb: 4,
    };
    this.switch = (isSelected) => ({
      width: '5.5rem',
      height: '2.5rem',
      borderRadius: '6.1875rem',
      backgroundColor: colors.gray,
      'input:checked ~ &': {
        backgroundColor: colors.craft,
        '& > div': {
          transform: 'translateX(3rem)',
        },
      },
      '& > div': {
        position: 'absolute',
        width: '2rem',
        height: '2rem',
        top: 2,
        left: 1,
        right: 0,
        backgroundSize: '150%',
        background: isSelected
          ? 'url("/svgs/checked_colored.svg#checked_colored") center center no-repeat'
          : '',
      },
    });
  })()
);
