import { Fragment, useState, useMemo, Children, cloneElement } from 'react';
import { Box } from 'theme-ui';

import { Header } from './Header';
import { Content } from './Content';
import { Footer } from './Footer';
import { themed } from './Sidebar.theme';

export const Sidebar = themed(({ theme, open, children, ...props }) => {
  const [footerHeight, setFooterHeight] = useState(0);

  // sorts drawer children into Header, Content and Footer slots
  const SidebarSlots = useMemo(() => {
    let Header = null;
    let Content = null;
    let Footer = null;

    // scrollHeight is (header + content) - footer
    const scrollHeight = `calc(var(--viewport-height)${
      footerHeight ? ` - ${footerHeight}px` : ''
    })`;

    Children.forEach(children, (child) => {
      const displayName =
        child?.props?.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.displayName ||
        child?.type?.displayName;

      switch (displayName) {
        case 'Header':
          Header = cloneElement(child, { key: 'Header', open, ...child.props });
          break;

        case 'Content':
          Content = cloneElement(child, {
            key: 'Content',
            open,
            ...child.props,
          });
          break;

        case 'Footer':
          Footer = cloneElement(child, {
            key: 'Footer',
            open,
            setHeight: setFooterHeight,
            ...child.props,
          });
          break;

        default:
          break;
      }
    });

    return (
      <>
        <Box
          data-comp={`${Sidebar.displayName}Scroll`}
          sx={{
            ...theme.inner.scroll,
            height: scrollHeight,
          }}
        >
          {[Header, Content]}
        </Box>
        {Footer}
      </>
    );
  }, [Children.count(children), children, open, footerHeight]);

  return (
    <Box data-comp={Sidebar.displayName} sx={theme.inner}>
      {SidebarSlots}
    </Box>
  );
});

Sidebar.displayName = 'Sidebar';

Sidebar.Header = Header;
Sidebar.Content = Content;
Sidebar.Footer = Footer;
