import { Box, Paragraph, Flex } from 'theme-ui';
import PropTypes from 'prop-types';

import { themed } from './Prices.theme';

export const Prices = themed(
  ({
    theme: themes,
    estimatedCost,
    isDiscounted,
    isOnSale,
    inSidebar,
    ...props
  }) => {
    const theme = inSidebar ? themes.sidebar : themes.page;

    return (
      <Box data-comp={Prices.displayName}>
        {/* Full price before savings and discounts */}
        {estimatedCost?.totalCompareAtAmount ? (
          <Flex sx={theme.price}>
            <Paragraph sx={theme.price.title}>Total</Paragraph>
            <Paragraph sx={theme.price.amount}>
              {estimatedCost.totalCompareAtAmount.locale}
            </Paragraph>
          </Flex>
        ) : null}

        {/* Savings from any items that are on sale, or discounts from subscriptions */}
        {isOnSale ? (
          <Flex sx={theme.price}>
            <Paragraph sx={theme.price.title}>Savings</Paragraph>
            <Paragraph sx={theme.price.amount}>
              -{estimatedCost.totalSavingsAmount.locale}
            </Paragraph>
          </Flex>
        ) : null}

        {/* Price after savings and discounts */}
        <Flex sx={theme.price}>
          <Paragraph sx={theme.price.title}>Subtotal</Paragraph>
          <Paragraph sx={theme.price.amount}>
            {estimatedCost.subtotalAmount.locale}
          </Paragraph>
        </Flex>

        {/* Discount amount applied from discount codes */}
        {isDiscounted ? (
          <Flex sx={theme.price}>
            <Paragraph sx={theme.price.title}>Discount</Paragraph>
            <Paragraph sx={theme.price.amount}>
              -{estimatedCost?.discountAmount?.locale}
            </Paragraph>
          </Flex>
        ) : null}

        {/* Discount amount applied from discount codes */}
        {isDiscounted ? (
          <Flex sx={theme.price}>
            <Paragraph sx={theme.price.title}>Discounted Total</Paragraph>
            <Paragraph sx={theme.price.amount}>
              {estimatedCost?.totalDiscountAmount?.locale}
            </Paragraph>
          </Flex>
        ) : null}

        {/* Estimated tax total */}
        {estimatedCost?.totalTaxAmount ? (
          <Flex sx={theme.price}>
            <Paragraph sx={theme.price.title}>Estimated Tax</Paragraph>
            <Paragraph sx={theme.price.amount}>
              {estimatedCost.totalTaxAmount.locale}
            </Paragraph>
          </Flex>
        ) : null}

        {/* Estimated total duty */}
        {estimatedCost?.totalDutyAmount?.locale ? (
          <Flex sx={theme.price}>
            <Paragraph sx={theme.price.title}>Duty</Paragraph>
            <Paragraph sx={theme.price.amount}>
              {estimatedCost.totalDutyAmount.locale}
            </Paragraph>
          </Flex>
        ) : null}

        {/* Estimated total price — after savings, discounts, tax and duty */}
        <Flex sx={theme.price}>
          <Paragraph sx={theme.price.title}>Estimated Total</Paragraph>
          <Paragraph sx={theme.price.amount}>
            {estimatedCost.totalAmount?.locale}
          </Paragraph>
        </Flex>
      </Box>
    );
  }
);

Prices.displayName = 'Prices';
Prices.propTypes = {
  estimatedCost: PropTypes.object,
  isDiscounted: PropTypes.bool,
  isOnSale: PropTypes.bool,
  inSidebar: PropTypes.bool,
};
