import { useBreakpointValue } from '@hooks';
import { VideoPlayer } from '@snippets/VideoPlayer';

import { isValidVideo } from '@utils';

import { themed } from './Video.theme';

export const Video = themed(({ theme, video }) => {
  const videoDesktop = video?.localVideoDesktop?.src
    ? video?.localVideoDesktop.src
    : video?.videoSrcDesktop;
  const videoMobile = video?.localVideoMobile?.src
    ? video?.localVideoMobile.src
    : video?.videoSrcMobile;
  const videoSource = useBreakpointValue([videoMobile, videoDesktop]);
  const videoTitle = video?.title;
  const validVideo = isValidVideo(videoSource);

  return (
    <>
      {validVideo && (
        <VideoPlayer
          styles={theme.video}
          source={videoSource}
          title={videoTitle}
          autoPlay={video?.autoplay}
          showPlayButton={false}
          playButtonIcon="/svgs/video_play.svg#videoPlay"
          pauseButtonIcon="/svgs/pause.svg#pause"
          playIconStyle={theme.icon}
          playButtonStyle={theme.playButton}
          viewBox="-2 -5 115 40"
        />
      )}
    </>
  );
});

Video.displayName = 'Video';
