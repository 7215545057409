import { Flex, Box, Text, Paragraph } from 'theme-ui';
import { Svg } from '@snippets';
import { themed } from './Content.theme';

export const Content = themed(({ theme, content }) => {
  return (
    <Flex data-comp={Content.displayName} sx={theme.container}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: ['column', 'row'],
        }}
      >
        {content?.items?.slice(0, 4).map((item) => (
          <Flex
            key={item.id}
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: ['100%', '50%'],
              my: [8, 0],
            }}
          >
            <Svg src={item.icon} viewBox="0 0 48 48" sx={{ maxWidth: 50 }} />
            <Text
              variant="text.h4"
              sx={{
                marginBottom: ' 1rem',
                marginTop: '1.125rem',
                textAlign: 'center',
                width: ['100%', '70%'],
              }}
            >
              {item.title}
            </Text>
            <Paragraph
              sx={{
                textAlign: 'center',
                whiteSpace: 'pre-wrap',
                maxWidth: '80%',
              }}
            >
              {item.description}
            </Paragraph>
          </Flex>
        ))}
      </Box>
    </Flex>
  );
});

Content.displayName = 'Content';
