import { Flex, Text } from 'theme-ui';
import { Svg, Link } from '@snippets';
import { themed } from './AddItemToCart.theme';
import { colors } from '@theme/theme.colors';

export const AddItemToCart = themed(
  ({ theme, selectedSubscription, selectedVariant, selectedPlanID, selectedPlanDiscount, selectedSwatch, variants }) => {
    const productId = selectedVariant?.legacyResourceId
    const sellingPlan = selectedSubscription
      ? `&selling_plan=` + selectedPlanID?.replace('gid://shopify/SellingPlan/','')
      : '&';

      // let searchParms = new URLSearchParams(
      //   window.location.search || ''
      // ).toString();

    const totalBoxes = selectedVariant?.position;

    const discount = selectedPlanDiscount

    const showSubscriptionPrice =
      selectedVariant?.price
    const firstBoxPrice = variants[0]?.price;
    const comparePrice = selectedSubscription ? (selectedVariant?.price - selectedPlanDiscount) : selectedVariant?.price;
    const savingPercent = Math.round((firstBoxPrice - comparePrice) / firstBoxPrice * 100);
    const savingAmount = Math.round((firstBoxPrice - comparePrice) * totalBoxes).toFixed(2);
    return (
      <Flex data-comp={AddItemToCart.displayName} sx={theme.container}>
        <Link
          href={`https://wanderingbearcoffee.com/cart/add?id=${productId}&quantity=${selectedVariant?.position}${sellingPlan}`}
          tabIndex="-1"
          variant="buttons.secondary"
          sx={{
            cursor: 'pointer',
            width: '100%',
          }}
        >
          <Flex
            sx={{ justifyContent: 'center', alignItems: 'center' }}
          >
            {selectedSubscription && showSubscriptionPrice ?
              <Text variant="text.h4">Subscribe Monthly</Text>
              :
              <Text variant="text.h4">Add To Bag</Text>
            }
          </Flex>
        </Link>
        {firstBoxPrice > comparePrice &&
          <Text sx={{mt: '8px', fontSize: '14px', fontWeight: 700, letterSpacing: '0.42px', color: colors.secondRed }}>You're saving ${savingAmount} ({savingPercent}%) {selectedSubscription ? `+ free shipping` : ``}  </Text>
        }
      </Flex>
    );
  }
);

AddItemToCart.displayName = 'AddItemToCart';
