import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.shell = {
      bg: 'background',
      boxShadow: 'left',
      minHeight: `var(--viewport-height)`,
      position: 'fixed',
      right: 0,
      top: 0,
      transition: 'transform .3s ease-in-out',
      width: ['100%', '400px'],
      willChange: 'transform',
      zIndex: 50,

      inProduction: {
        closed: {
          transform: 'translateX(100%)',
        },
        open: {
          transform: 'translateX(0)',
        },
      },

      // push it bellow the customizer toolbar
      inCustomizer: {
        closed: {
          top: 'var(--backpack-toolbar-height)',
          transform: 'translateX(100%)',
          minHeight: `calc(var(--viewport-height) - var(--backpack-toolbar-height))`,
        },
        open: {
          top: 'var(--backpack-toolbar-height)',
          transform: 'translateX(0)', // right to left
        },
      },

      scroll: {
        overflowY: 'scroll',
        webkitOverflowScrolling: 'touch',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      },
    };

    // dynamic drawer wrapper
    this.inner = {
      scroll: {
        overflowY: 'scroll',
        webkitOverflowScrolling: 'touch',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      },
    };
  })()
);
