import { store } from '@backpackjs/storefront';

// instant un-debounced search term (not used)
const rawTerm = store.recoil.atom({
  key: 'search/rawTerm',
  default: '',
});

// debounced term used
const term = store.recoil.atom({
  key: 'search/term',
  default: '',
});

// true when search input is focused
const searchIsFocused = store.recoil.atom({
  key: 'search/searchIsFocused',
  default: false,
});

// holds both results
const results$ = store.recoil.selector({
  key: 'search/results$',
  get: async ({ get }) => {
    const _term = get(term);
    if (!_term) return null;
    const response = await searchShopify(_term);
    if (response && response.error) {
      throw response.error;
    }
    return response;
  },
});

// returns product results
const foundProducts$ = store.recoil.selector({
  key: 'search/foundProducts$',
  get: ({ get }) => {
    const _results = get(results$);
    return _results?.products?.length ? _results?.products : null;
  },
});

// returns collection results
const foundCollections$ = store.recoil.selector({
  key: 'search/foundCollections$',
  get: ({ get }) => {
    const _results = get(results$);
    return _results?.collections?.length ? _results?.collections : null;
  },
});

// search fetch function
const searchShopify = async (term) => {
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify({ term }),
    };
    const request = await fetch(`/api/shopify/search`, options);
    const { response } = await request.json();
    return response?.results || null;
  } catch (e) {
    console.error('searchShopify:error', e);
    return [];
  }
};

export default {
  // atoms
  rawTerm,
  term,
  searchIsFocused,

  // selectors
  results$,
  foundProducts$,
  foundCollections$,
};
