import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid } from 'theme-ui';

import { SubNavProductItem } from './SubNavProductItem';
import { themed } from './SubNav.theme';

export const SubNavProductsSwiper = themed(
  ({ theme, products: items, ...props }) => {
    return (
      <Grid
        data-comp={SubNavProductsSwiper.displayName}
        {...props}
        sx={{
          ...props.sx,
          ...theme.productsSwiper,
        }}
      >
        <Swiper spaceBetween={20} slidesPerView={1.4}>
          {items.map((item, index) => (
            <SwiperSlide key={item.product.handle + index}>
              <SubNavProductItem
                key={item.product.handle + index}
                handle={item.product.handle}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
    );
  }
);

SubNavProductsSwiper.displayName = 'SubNavProductsSwiper';
