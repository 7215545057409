import { Container } from 'theme-ui';

import { Section } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './AtcBlockInveterate.schema';
import { themed } from './AtcBlockInveterate.theme';
import { Header } from './Header';
import { Content } from './Content';

export const AtcBlockInveterate = withInView(
  themed(({ theme, cms }) => {
    const { section, containerID, text, content, type } = cms;

    return (
      <Section section={section}>
        <Container
          data-comp={AtcBlockInveterate.displayName}
          id={containerID || AtcBlockInveterate.displayName}
        >
          <Header header={text} />
          <Content content={content} type={type} />
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

AtcBlockInveterate.displayName = 'AtcBlock';
AtcBlockInveterate.Schema = Schema;
