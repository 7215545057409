import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Image, Box } from 'theme-ui';

export const Svg = forwardRef(
  (
    {
      src, // must include the svg id — e.g "/svgs/bag.svg#bag-id" for <svg id="bag-id" ...
      alt = 'Illustration',
      viewBox,
      preserveAspectRatio = true,
      ...props
    },
    ref
  ) => {
    const srcHasId = /#/.test(src);

    if (srcHasId && !viewBox) {
      throw new Error('viewBox not provided');
    }

    return srcHasId ? (
      <Box
        data-comp={Svg.displayName}
        ref={ref}
        sx={{
          variant: 'flex.row.center',
        }}
        {...props}
      >
        <Box
          alt={alt}
          data-comp={`${Svg.displayName}Svg`}
          as="svg"
          role="img"
          viewBox={viewBox}
          preserveAspectRatio={preserveAspectRatio ? '' : 'none'}
          sx={{
            width: '100%',
            height: '100%',
            display: 'block',
          }}
        >
          <title>{alt}</title>

          <Box as="use" x="0" y="0" href={src} />
        </Box>
      </Box>
    ) : (
      <Image role="presentation" alt={alt} src={src} {...props} />
    );
  }
);

Svg.displayName = 'Svg';
Svg.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  viewBox: PropTypes.string,
  preserveAspectRatio: PropTypes.bool,
};
