import { create } from '@theme/create';

export const themed = create(
  new (function () {
    // have results
    this.hidden = {
      variant: 'flex.column',
      display: 'none',
    };

    this.visible = {
      ...this.hidden,
      display: 'block',
    };

    (this.heading = {
      variant: 'text.searchHeading',
      mb: 8,
    }),
      (this.term = {
        variant: 'text.base',
        cursor: 'pointer',
        mb: 5,
        '&:hover': {
          textDecoration: 'underline',
        },
      });
  })()
);
