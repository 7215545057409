const label = {
  fontSize: 2, // 14px
  lineHeight: 18,
  fontWeight: 800,
  fontFamily: 'body',
  textTransform: 'uppercase',
  letterSpacing: '0.02em',
};

const link = {
  fontSize: 1, // 12px
  lineHeight: 15,
  textTransform: 'uppercase',
  fontWeight: 800,
  fontFamily: 'body',
  position: 'relative',
  overflow: 'hidden',
  ':after': {
    content: '""',
    position: 'absolute',
    top: 'calc(100% - 1px)',
    left: 0,
    width: '100%',
    height: '1px',
    bg: 'currentColor',
    transition: 'transform 0.4s ease-in-out',
  },
  ':before': {
    content: '""',
    position: 'absolute',
    top: 'calc(100% - 1px)',
    left: '-200%',
    width: '100%',
    height: '1px',
    bg: 'currentColor',
    transition: 'transform 0.4s ease-in-out',
  },
  ':hover:after': {
    transform: 'translateX(200%)',
  },
  ':hover:before': {
    transform: 'translateX(200%)',
  },
};

export const text = {
  // HEADINGS
  h1: {
    fontFamily: 'tabletGothic',
    fontSize: [11, 12, 13], // [40px, 48px, 56px]
    lineHeight: [36, '42px', 48],
    fontWeight: 800,
    textTransform: 'uppercase',
  },
  h2: {
    variant: 'text.h1',
    fontFamily: 'gtPressuraBold',
    fontSize: [10, 11], // [36px, 40px]
    lineHeight: [36, 40],
    letterSpacing: '0.01em',
  },
  h3: {
    variant: 'text.h2',
    fontFamily: 'gtPressuraBold',
    fontSize: [8, 9], // [24px, 30px]
    lineHeight: [32, 38],
  },
  h4: {
    variant: 'text.h2',
    fontSize: [7, 8], // [20px, 24px]
    lineHeight: [24, 32],
  },
  h5: {
    variant: 'text.h2',
    fontSize: '22px',
    lineHeight: 22,
  },
  h6: {
    variant: 'text.h2',
    fontSize: 1, // 12px
    lineHeight: 18,
  },

  // BODY
  sm: {
    variant: 'text.base',
    fontSize: 1, // 12px
    lineHeight: 18,
  },
  base: {
    fontFamily: 'body',
    fontSize: 4, // 16px
    lineHeight: 24,
    letterSpacing: '0.01em',
  },
  lg: {
    variant: 'text.base',
    fontSize: 6, // 18px
    lineHeight: 24,
  },

  // LABELS, LINKS, OTHERS
  label: {
    ...label,
    1: label, // 14px
    2: {
      ...label,
      fontSize: 0, // 11px
    },
  },

  link: {
    ...link,
    1: link, // 12px
    2: {
      ...link,
      fontSize: 2, // 14px
      lineHeight: 18,
    },
  },

  buttonText: {
    fontFamily: 'body',
    fontSize: 2, // 14px
    lineHeight: 20,
    fontWeight: 700,
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
  },

  // NAVIGATION
  navItem: {
    fontSize: 2, // 14px
    lineHeight: 18,
    fontWeight: 800,
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    fontFamily: 'body',
    cursor: 'pointer',
  },
  navSubItem: {
    fontSize: 6, // 18px
    lineHeight: 22,
    fontWeight: 400,
    cursor: 'pointer',
    fontFamily: 'body',
  },
  searchHeading: {
    variant: 'text.navItem',
    position: 'sticky',
    top: ['-32px', '56px'],
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'lightGray',
    zIndex: 2,
    pt: 8,
    pb: 4,
    mb: 5,
    bg: 'background',
  },
};
