export function Schema() {
  return {
    label: 'Hero Video',
    key: 'hero-video',
    fields: [
      {
        label: 'Video Settings',
        name: 'video',
        component: 'group',
        fields: [
          {
            name: 'alt',
            label: 'Alt',
            component: 'text',
            description: 'Brief description of the video',
          },
          {
            name: 'url',
            label: 'Video URL (desktop)',
            component: 'text',
          },
          {
            name: 'firstFrame',
            label: 'First Frame Image',
            component: 'image',
          },
        ],
        defaultValue: {
          alt: 'Video',
          url: 'https://vimeo.com/326385146',
        },
      },
      {
        label: 'Content Settings',
        name: 'content',
        description: 'Heading, subheading, cta',
        component: 'group',
        fields: [
          {
            name: 'heading',
            label: 'Heading',
            component: 'text',
          },
          {
            name: 'subheading',
            label: 'Sub-Heading',
            component: 'text',
          },
          {
            name: 'cta',
            label: 'Call To Action',
            component: 'link',
          },
        ],
        defaultValue: {
          heading: 'LIKE HAVING A COFFEE SHOP ON TAP IN YOUR FRIDGE',
          subheading: "THE BEST FRESHEST COLD BREW YOU'LL DRINK.",
          cta: {
            text: 'Label',
            url: '#',
          },
        },
      },
    ],
  };
}
