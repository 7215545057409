import { useRef, useEffect } from 'react';
import { Input as BaseInput, Box } from 'theme-ui';

import { useSearch, useSidebar } from '@hooks';
import { Svg } from '@snippets';

import { themed } from './Input.theme';

export const Input = themed(({ theme, ...props }) => {
  const input = useRef();
  const [{ isSearch }, {}] = useSidebar();
  const [{ rawTerm }, { setRawTerm, resetRawTerm, setSearchIsFocused }] =
    useSearch();

  useEffect(() => {
    if (isSearch) {
      input.current.focus();
    }
  }, [isSearch]);

  return (
    <Box
      data-comp={Input.displayName}
      {...props}
      sx={{
        ...props.sx,
        ...theme.wrapper,
      }}
    >
      {/* search || searching icon */}
      <Svg
        alt="Search input"
        viewBox="0 0 48 48"
        src={
          rawTerm
            ? '/svgs/cta/searching.svg#searching'
            : '/svgs/cta/search.svg#search'
        }
        sx={theme.searching}
      />

      <BaseInput
        ref={input}
        placeholder="Search here"
        role="search"
        aria-label="Products and collections"
        onFocus={() => setSearchIsFocused(true)}
        onBlur={() => setSearchIsFocused(false)}
        onChange={(e) => setRawTerm(e.target.value)}
        value={rawTerm}
        sx={theme.field}
      />

      {/*  clear search icon */}
      <Svg
        src="/svgs/close.svg#close"
        alt="Clear search"
        viewBox="0 0 48 48"
        onClick={resetRawTerm}
        sx={rawTerm ? theme.clear.visible : theme.clear.hidden}
      />
    </Box>
  );
});

Input.displayName = 'Input';
