import { useState, useEffect } from 'react';
import { useProductByHandle } from '@backpackjs/storefront';

import { themed } from './Content.theme';
import { ContentComponent } from './ContentComponent';

export const Content = themed(({ theme, content }) => {
  const {
    subscription,
    variants: { variants, heading, boxes },
  } = content;

  const [allSwatches, setAllSwatches] = useState([]);
  const [selectedSwatch, setSelectedSwatch] = useState(variants?.variants[0]);

  const { product } = useProductByHandle({
    handle: selectedSwatch?.product?.handle,
  });

  const [selectedSubscription, setSelectedSubscription] = useState(
    subscription?.default
  );

  const handleSubscriptionSelect = () => {
    setSelectedSubscription(!selectedSubscription);
  };

  console.log({ selectedSwatch });
  console.log({ product });
  console.log(variants?.variants[0]);
  const handleSwatchSelect = (color, swatchTitle) => {
    const swatchSelected = allSwatches.map((variant) =>
      (variant?.color === color && variant.title === swatchTitle) ||
      variant.id === swatchTitle
        ? {
            ...variant,
            isSelected: !variant.isSelected,
          }
        : {
            ...variant,
            isSelected: false,
          }
    );
    const currentVariant = allSwatches.filter(
      ({ title, id }) => title === swatchTitle || id === swatchTitle
    )[0];
    setAllSwatches(swatchSelected);
    setSelectedSwatch(currentVariant);
  };

  useEffect(() => {
    setAllSwatches(
      variants.variants.map((swatches, index) =>
        index === 0
          ? { ...swatches, isSelected: true }
          : { ...swatches, isSelected: false }
      )
    );
    setSelectedSwatch(variants?.variants[0]);
  }, [variants?.variants]);
  if (!product || !product?.sellingPlanGroups?.length) return null;
  return (
    <ContentComponent
      content={content}
      product={product}
      selectedSwatch={selectedSwatch}
      allSwatches={allSwatches}
      handleSwatchSelect={handleSwatchSelect}
      handleSubscriptionSelect={handleSubscriptionSelect}
      selectedSubscription={selectedSubscription}
    />
  );
});

Content.displayName = 'Content';
