import { useEffect } from 'react';
import { useProduct, useRouter } from '@backpackjs/storefront';

import store, { useSetRecoilState, useRecoilValue } from '@store';

const VERBOSE = false;

export const useInitSelectedVariant = () => {
  const product = useProduct();
  const router = useRouter();
  const setSelectedOptions = useSetRecoilState(
    store.setSelectedOptions$(product?.handle)
  );
  const selectedVariant = useRecoilValue(
    store.selectedVariant(product?.handle)
  );

  const setInitialVariant = () => {
    if (!product) return undefined;

    const queryKey = 'variant';
    const queryValue =
      router.query[queryKey] ||
      router.asPath.match(new RegExp(`[&?]${queryKey}=(.*)(&|$)`));

    const queriedVariantId =
      Array.isArray(queryValue) && queryValue?.length
        ? queryValue[1]
        : queryValue;

    if (queriedVariantId) {
      // if ?variant=123123123 is passed we use that as selectedVariant
      // if (VERBOSE) console.log('useInitSelectedVariant:queriedVariantId', queriedVariantId)
      const queriedVariant = product.variants.find(
        ({ legacyResourceId }) => legacyResourceId === queriedVariantId
      );

      if (!queriedVariant) {
        if (VERBOSE) console.log('Set initial variant to first variant');
        setSelectedOptions(product.variants[0].selectedOptionsMap);
      } else {
        // good
        if (VERBOSE)
          console.log('Set initial variant to queried variant', queriedVariant);
        setSelectedOptions(queriedVariant.selectedOptionsMap);
      }
    } else {
      const firstAvailableVariant = product.variants.find(
        ({ availableForSale }) => availableForSale
      );

      if (firstAvailableVariant) {
        if (VERBOSE) console.log('Set initial variant to first available');
        setSelectedOptions(firstAvailableVariant.selectedOptionsMap);
      } else if (product?.variants?.length) {
        // set initial selectedVariant
        if (VERBOSE) console.log('Set initial variant to first variant');
        setSelectedOptions(product.variants[0].selectedOptionsMap);
      } else {
        // Should never be get here..
        setSelectedOptions(null);
      }
    }

    return () => {
      // on unmount
      setSelectedOptions(null);
    };
  };

  useEffect(() => {
    setInitialVariant();
  }, [product?.handle]);

  return selectedVariant;
};
