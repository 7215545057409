import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.container = {
      mt: 12,
      flexDirection: 'column'
    };

    this.header = {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      mb: [13, 14],
      flex: 1,
      borderBottom: ` 1px solid ${colors.gray.mediumLight}`,
    };
    this.heading = {
      variant: 'text.nav',
      pb: 4,
    };
    this.radio = (isSelected) => ({
      width: '1.5rem',
      height: '1.5rem',
      borderRadius: '50%',
      backgroundColor: 'transparent',
      'input:checked ~ &': {
        backgroundColor: colors.craft,
        '& > div': {
          transform: 'translateX(3rem)',
        },
      },
    })
  })()
);
