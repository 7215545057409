import { Flex, Box, Text, Paragraph, Heading } from 'theme-ui';
import { Markdown, Svg, } from '@snippets';
import { colors } from '@theme/theme.colors';
import dynamic from 'next/dynamic';

import { themed } from './Dimensions.theme';

const Image = dynamic(() => import('../../Image').then((module) => module.Image), {
  ssr: false,
  suspense: false,
});

export const Dimensions = themed(({ theme, dimensions, boxes }) => {

  return (
    <Flex data-comp={Dimensions.displayName} sx={theme.container}>
      <Heading variant="text.h3" sx={{ textTransform: 'uppercase', fontFamily: 'gtPressura', mb: '1.25rem' }}>
        {boxes?.linkText}
      </Heading>
      <Box sx={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: ['1fr', '1fr 1fr 1fr'],
        gridGap: '2rem'
      }}>
        {
          dimensions?.map(item => {
            return (
              <Flex key={item.id} sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                {item?.image &&
                  <Image imageSx={{objectFit: 'contain'}} image={item?.image}></Image>
                }
                <Text
                  sx={{
                    marginBottom: ' 1rem',
                    marginTop: '1.125rem',
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: colors.green,
                    mb: '0.5rem',
                    maxWidth: '240px',
                  }}
                >{item.title}</Text>
                <Paragraph
                  sx={{
                    textAlign: 'center',
                    fontSize: '18px',
                    lineHeight: '25px',
                    fontWeight: 600,
                    mb: '0.5rem',
                    maxWidth: '240px',
                  }}
                >{item.description}</Paragraph>
                <Paragraph
                  sx={{
                    textAlign: 'center',
                    maxWidth: '240px',
                  }}
                >{item.dimension}</Paragraph>
              </Flex>
            )
          })
        }
      </Box>
    </Flex>
  );
});

Dimensions.displayName = 'Dimensions';
