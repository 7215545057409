import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.header = {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      mb: [13, 14],
      flex: 1,
      width: '80%',
    };
    this.heading = {
      variant: 'text.nav',
      pb: 4,
    };
  })()
);
