import { Paragraph, Flex, Grid } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { Link } from '@snippets';

import { themed } from './Legal.theme';

export const Legal = themed(({ theme }) => {
  const settings = useSettings();

  return (
    <Flex data-comp={Legal.displayName} sx={theme.wrapper}>
      <Grid sx={theme.grid}>
        {settings?.footer?.legalLinks?.map((legalLink, index) => {
          return (
            <Link
              key={legalLink.cta.text + index}
              href={legalLink.cta.url}
              sx={theme.link}
            >
              {legalLink.cta.text}
            </Link>
          );
        })}
      </Grid>

      <Paragraph sx={theme.copyright}>
        {'© '}
        {new Date().getFullYear() > 2010 && new Date().getFullYear()}{' '}
        {settings?.footer?.copyrightNotice}
      </Paragraph>
    </Flex>
  );
});

Legal.displayName = 'Legal';
