import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      width: '100%',
      flexDirection: ['column', 'row-reverse'],
      alignItems: ['center', 'flex-start'],
      justifyContent: 'space-between',
    };

    this.grid = {
      gridAutoFlow: 'column',
      gridTemplateColumns: 'repeat(auto-fill, auto)',
      gridGap: 13,
      mb: [4, 0],
    };

    this.link = {
      variant: 'text.sm',
      fontSize: 1,
      textAlign: ['center', 'left'],
      position: 'relative',
      ':after': {
        content: '""',
        position: 'absolute',
        top: '100%',
        left: 0,
        width: '100%',
        maxWidth: 0,
        height: '1px',
        bg: 'currentColor',
        transition: 'max-width 0.2s ease-in-out',
      },
      ':hover:after': {
        maxWidth: '100%',
      },
    };

    this.copyright = {
      variant: 'text.sm',
      fontSize: 1,
      mr: [0, 13],
    };
  })()
);
