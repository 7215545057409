import { section } from '@settings/common/section';
import { color } from '@settings/common/color';

export function Schema({ product }) {
  if (!product) return null;

  return {
    label: 'Product reviews',
    key: 'product-reviews',
    fields: [
      {
        name: 'heading',
        label: 'Heading',
        component: 'text',
        defaultValue: 'We Want to Impress You',
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'content',
            mt: 'none',
            py: 'm',
          },
          mobile: {
            container: 'content',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
}
