import { Spinner } from 'theme-ui';

import { Link, Picture } from '@snippets';

import { themed } from './Image.theme';

export const Image = themed(({ theme, fullProduct }) => {
  const defaultImage = fullProduct
    ? fullProduct?.images?.length
      ? fullProduct?.images?.[0]
      : fullProduct?.variants?.image
    : null;

  const hoverImage = fullProduct?.images?.[1] || null;

  return (
    <Link
      data-comp={Image.displayName}
      // href={`/products/${product.handle}`}
      href={`https://wanderingbearcoffee.com/cart/add?id=${fullProduct?.variants[0]?.legacyResourceId}`}
      sx={theme.image}
    >
      <Picture
        alt={fullProduct?.title}
        images={[
          {
            src: defaultImage?.originalSrc,
            width: 350,
            ratio: defaultImage?.ratio || 2 / 3,
          },
        ]}
        hoverImages={[
          {
            src: hoverImage?.originalSrc,
            width: 350,
            ratio: hoverImage?.ratio || 2 / 3,
          },
        ]}
      >
        {!defaultImage?.originalSrc && <Spinner sx={theme.spinner} />}
      </Picture>
    </Link>
  );
});

Image.displayName = 'Image';
