import { section } from '@settings/common/section';
import { fonts } from '@settings/common/fonts';
import { color } from '@settings/common/color';

export const Schema = () => {
  return {
    label: 'Review Slider',
    key: 'reviewSlider',
    fields: [
      {
        ...section,
        defaultValue: {
          bgColor: color.value.yellow,
          desktop: {
            container: 'full-bleed',
            mt: 'none',
            py: 'xl',
          },
          mobile: {
            container: 'full-bleed',
            mt: 'none',
            py: 'xl',
          },
        },
      },
      {
        name: 'curveEnabled',
        label: 'Curve Enabled',
        component: 'toggle',
        toggleLabels: {
          true: 'Yes',
          false: 'No',
        },
        defaultValue: true,
      },
      {
        label: 'Heading Settings',
        name: 'headingSettings',
        description: 'Heading options',
        component: 'group',
        fields: [
          {
            name: 'heading',
            component: 'text',
            label: 'Heading',
          },
          {
            label: 'Font Family',
            name: 'fontFamily',
            component: 'select',
            options: fonts.options,
          },
          {
            name: 'image',
            label: 'Logo',
            component: 'image',
            parse: (image) => image,
          },
        ],
        defaultValue: {
          heading: 'OVER 7,000 5 STAR REVIEWS',
          fontFamily: fonts.value.tabletGothic,
          image:
            'https://cdn.shopify.com/s/files/1/1418/5086/files/amazon-logo.svg?v=1652819928',
        },
      },
      {
        name: 'reviews',
        label: 'Reviews',
        component: 'group-list',
        defaultItem: {
          label: '{{item.name}}',
          quote: 'This is great coffee',
          name: 'Dolores R',
          starRating: 4,
          verifiedPurchaser: true,
        },
        itemProps: {
          key: '{{item.id}}',
          label: '{{item.name}}',
        },
        fields: [
          {
            name: 'starRating',
            component: 'select',
            label: 'Star Rating',
            description: '1 - 5 only',
            options: [
              { label: 'One', value: 1 },
              { label: 'Two', value: 2 },
              { label: 'Three', value: 3 },
              { label: 'Four', value: 4 },
              { label: 'Five', value: 5 },
            ],
          },
          {
            label: 'Quote',
            name: 'quote',
            component: 'textarea',
            defaultValue: 'This is great coffee',
          },
          {
            label: 'Name',
            name: 'name',
            component: 'text',
            defaultValue: 'Dolores R',
          },
          {
            name: 'verifiedPurchaser',
            label: 'Verified Purchaser',
            component: 'toggle',
            toggleLabels: {
              true: 'Yes',
              false: 'No',
            },
            defaultValue: true,
          },
        ],
        defaultValue: [
          {
            quote: 'Everything you want in an at-home cold brew',
            name: 'Thatcher W',
            verifiedPurchaser: true,
            starRating: 4,
          },
          {
            quote: 'This is great coffee',
            name: 'Dolores R',
            verifiedPurchaser: true,
            starRating: 4,
          },
        ],
      },
    ],
  };
};
