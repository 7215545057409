import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.container = {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gridGap: ['1.125rem', '1.5rem'],
      width: '100%',
    };
    this.wrapper = {
      flexDirection: ['column', 'row'],
      width: ['100%', 'auto'],
    };

    this.box = ({ isSelected, showBadge, badge }) => ({
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      pb: [3, 4],
      mb: [13, 14],
      position: 'relative',
      width: ['100%', '18rem'],
      borderWidth: '3px',
      '& :after': showBadge && {
        width: '7rem',
        backgroundColor: `${colors.green}`,
        color: colors.yellow,
        fontWeight: 700,
        fontSize: '1rem',
        lineHeight: 1,
        letterSpacing: ' 0.01em',
        whiteSpace: 'nowrap',
        position: 'absolute',
        top: '-1.25rem',
        right: '1.25rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'rotate(4deg)',
        padding: ' 0.5337rem',
        content: `"${badge}"`,
      },
    });

    this.title = {
      variant: 'text.h4',
      lineHeight: 1,
      '@media only screen and (max-width: 479px)': {
        variant: 'text.h3',
      },
    };
  })()
);
