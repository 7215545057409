import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.media = {
      position: 'relative',
      // This math sets the width of the video to be the width of the
      // screen, and sets the height to match the 16:9 HD video aspect
      // ratio. But it won't let the height go below 50rem for smaller
      // screens and instead cuts off the video on either side, centering
      // it.

      // Set the height to be the width of the screen * 9/16 (the HD video
      // aspect ratio), but never go below 50rem
      height: 'calc(max(100vw * 9/16, 50rem))',

      // Make the video be at least as wide as the screen. Go wider
      // when the screen is less than 50rem * 16/9
      width: 'calc(max(100vw, 50rem * 16/9))',

      /* Center the video when it's too wide for the screen. Set 'left'
       * to the screen width, minus the actual width of the video
       * divided by two. The screen width minus the actual width will be a
       * negative number when it's too wide. Divide by two to center it.
       *
       *                              width copied from above
       *                              __________|__________
       *                             /                      \        */
      left: 'calc(min(0px, ((100vw - max(100vw, 50rem * 1.777)) / 2)))',
    };

    this.content = {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      justifyContent: 'center',
      alignItems: 'center',
      top: '-100%',
      height: '100%',
      width: '100vw',
      maxWidth: '720px',
      margin: 'auto',
      px: '2rem',
      py: '10rem',
      textAlign: 'center',
      fontWeight: 'bold',
      color: 'white',
    };
    this.heading = {
      textAlign: 'center',
      fontFamily: 'gothicCondensed',
      fontSize: ['51px', null, '85px'],
      fontWeight: 'bold',
    };
    this.subheading = {
      textAlign: 'center',
      fontFamily: 'gtPressura',
      fontSize: ['22px', null, '32px'],
      fontWeight: 'bold',
    };
    this.cta = {
      mt: '16px',
      width: 'calc(min(296px, 100% - 32px))',
    };
  })()
);
