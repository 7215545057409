import { Svg } from '@snippets/Svg';
import { colors } from '@theme/theme.colors';
import { Box, Text } from 'theme-ui';

import { themed } from './Swatch.theme';

export const Swatch = themed(
  ({
    theme,
    color,
    colorName,
    handleClick,
    size = 2,
    isWhite = false,
    showName = false,
    isSelected = false,
  }) => (
    <Box sx={theme.wrapper}>
      {isSelected ? (
        <Box
          onClick={() => handleClick(color, colorName)}
          sx={{
            ...theme.selected,
            background: color,
            width: `${size - 0.5}rem`,
            height: `${size - 0.5}rem`,
            mb: 8,
            '& :after': {
              content: '""',
              width: `${size + 0.375}rem`,
              height: `${size + 0.375}rem`,
              border: `3px solid ${colors.brown}`,
              borderRadius: '50%',
              display: 'block',
              position: 'absolute',
              transform: 'translate(-18%, -82%)',
            },
          }}
        >
          <Svg
            src="/svgs/checked.svg#checked"
            viewBox="-3 -7 25 25"
            sx={{ m: 0 }}
          />
        </Box>
      ) : (
        <Box
          as="svg"
          onClick={() => handleClick(color, colorName)}
          width={`${size}rem`}
          height={`${size}rem`}
          viewBox="0 0 28 28"
          fill="none"
          sx={theme.value({ isSelected })}
        >
          <Box
            id="outer"
            as="circle"
            cx="14"
            cy="14"
            r="13.5"
            stroke={isWhite ? '#B4B4B4' : color}
          />
          <Box
            id="inner"
            as="circle"
            cx="14"
            cy="14"
            r={isSelected ? '11' : '13.5'}
            fill={color}
            stroke={isWhite ? '#B4B4B4' : color}
            sx={{
              ':hover': {
                r: 10,
              },
            }}
          />
        </Box>
      )}

      {showName && (
        <Text variant="text.base" sx={theme.colorName}>
          {colorName}
        </Text>
      )}
    </Box>
  )
);

Swatch.displayName = 'Swatch';
