import { useCallback } from 'react';
import { Heading, Flex } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { useSidebar, useSearchResults } from '@hooks';

import { Product } from './Product';
import { Collection } from './Collection/Collection';
import { Empty } from './Empty';

import { themed } from './Results.theme';

export const Results = themed(({ theme, ...props }) => {
  const settings = useSettings();
  const [{}, { closeSidebar }] = useSidebar();
  const [{ foundProducts, foundCollections }, {}] = useSearchResults();

  const goToResultAndToggle = useCallback((link) => {
    if (!link) return;
    closeSidebar({ navigateTo: link });
  }, []);

  return (
    <Flex
      data-comp={Results.displayName}
      sx={{
        ...props.sx,
        ...(foundProducts ? theme.found : theme.notFound),
      }}
    >
      {/* Product Results */}
      {settings?.search?.showProducts && foundProducts?.length ? (
        <Flex sx={theme.products}>
          <Heading sx={theme.heading}>PRODUCTS</Heading>

          <Flex sx={theme.list}>
            {foundProducts.map((product) => (
              <Product
                key={product.id}
                product={product}
                goToResultAndToggle={goToResultAndToggle}
              />
            ))}
          </Flex>
        </Flex>
      ) : null}

      {/* Collection Results */}
      {settings?.search?.showCollections && foundCollections?.length ? (
        <Flex sx={theme.collections}>
          <Heading sx={theme.heading}>COLLECTIONS</Heading>

          <Flex sx={theme.list}>
            {foundCollections.map((collection) => (
              <Collection
                key={collection.id}
                collection={collection}
                goToResultAndToggle={goToResultAndToggle}
              />
            ))}
          </Flex>
        </Flex>
      ) : null}

      {/* No results */}
      <Empty />
    </Flex>
  );
});

Results.displayName = 'Results';
