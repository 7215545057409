import { Box, Flex, Paragraph, Image } from 'theme-ui';

import { StarsIcon, Svg } from '@snippets';

import { themed } from './Content.theme';

export const Content = themed(({ theme, review }) => {
  const { starRating, quote, name, verifiedPurchaser } = review;

  return (
    <Flex
      data-comp={Content.displayName}
      variant="flex.column.center"
      as="blockquote"
      sx={{
        pb: 13,
        textAlign: 'center',
      }}
    >
      <Flex variant="flex.row.center">
        {[...new Array(5)].map((_, index) => (
          <StarsIcon
            averageScore={starRating || 4}
            emptyColor="black"
            hoveredIndex={0}
            index={index}
            key={4 + index}
          />
        ))}
      </Flex>

      {quote && (
        <Paragraph
          sx={{
            textTransform: 'uppercase',
            fontSize: 9,
            mt: 10,
            mb: 7,
            fontFamily: 'gtPressuraBold',
          }}
        >
          “{quote}”
        </Paragraph>
      )}

      {name && (
        <Box
          as="figcaption"
          sx={{
            fontSize: 7,
            fontFamily: 'gtPressuraBold',
          }}
        >
          - {name}
        </Box>
      )}

      {verifiedPurchaser && (
        <Svg
          src="/svgs/verified-purchase.svg#verified-purchase"
          alt="Verified Purchase"
          viewBox="0 0 256 24"
          sx={{
            mt: 3,
            width: '256px',
          }}
        />
      )}
    </Flex>
  );
});

Content.displayName = 'Content';
