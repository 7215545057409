import { Container, Heading } from 'theme-ui';

import { convertAlignToFlex } from '@utils';

import { Markdown } from '@snippets';
import { themed } from './Header.theme';

export const Header = themed(({ theme, header }) => {
  const {
    heading,
    subheading,
    textAlign,
    headingColor,
    subheadingColor,
    maxWidth,
  } = header;
  if (!heading && !subheading) return null;
  return (
    <Container
      data-comp={Header.displayName}
      sx={{
        ...theme.header,
        alignItems: convertAlignToFlex(textAlign),
        textAlign: { textAlign },
        maxWidth,
      }}
    >
      {heading && (
        <Markdown
          variant="text.h1"
          text={heading}
          textAlign={textAlign}
          sx={{
            color: headingColor,
          }}
        />
      )}
      {subheading && (
        <Heading
          sx={{
            ...theme.heading,
            textAlign,
            color: subheadingColor,
          }}
        >
          {subheading}
        </Heading>
      )}
    </Container>
  );
});

Header.displayName = 'Header';
