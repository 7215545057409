import { useRef } from 'react';
import { Text } from 'theme-ui';

import { Link } from '@snippets';

import { useMenuDrawer } from '../MenuDrawer';
import { themed } from './Menu.theme';

export const NavItem = themed(({ theme, item, index, isFixed, ...props }) => {
  const ref = useRef();
  const [{ hovered }, { hoverItem, unHoverItem }] = useMenuDrawer();
  const isHovered = hovered?.link?.text === item?.link?.text;
  return (
    // Level 1 Menu nav item
    <Link
      ref={ref}
      data-comp={NavItem.displayName}
      href={item.link.url}
      onMouseLeave={unHoverItem}
      onMouseEnter={() => hoverItem(item)}
      sx={theme.item}
    >
      {item.link.text}

      <Text
        sx={{
          ...theme.item.hover,
          ...theme.item.hover(isFixed, isHovered),
        }}
      />
    </Link>
  );
});

NavItem.displayName = 'NavItem';
