import PropTypes from 'prop-types';
import { Grid, Paragraph, Flex } from 'theme-ui';
import { useCartRemoveItem } from '@backpackjs/storefront';

import { Svg, Picture, Locale } from '@snippets';
import { useSidebar } from '@hooks';

import { Quantity } from './Quantity';
import { Price } from './Price';
import { themed } from './LineItem.theme';

export const LineItem = themed(
  ({ theme: themes, inSidebar, isLast, item, ...props }) => {
    const { cartRemoveItem, ...cartRemoveStatus } = useCartRemoveItem();
    const [{}, { closeSidebar }] = useSidebar();
    const productUrl = `/products/${item.variant.product.handle}?variant=${item.variant.id}`;

    // apply the sidebar or cart page context
    const theme = inSidebar ? themes.sidebar : themes.page;

    return (
      <Grid
        data-comp={LineItem.displayName}
        {...props}
        sx={{
          ...props.sx,
          ...theme.item,
          ...(isLast ? theme.item.last : theme.item.between),
        }}
      >
        {/* left column — picture */}
        <Flex sx={theme.column.left}>
          <Picture
            alt={item.variant?.altText}
            images={[
              {
                src: item.variant?.image?.src || '/svgs/default-image.svg',
                ratio: item.variant.image.width / item.variant.image.height,
                width: 70,
              },
            ]}
            onClick={() => {
              if (!inSidebar) return;
              closeSidebar({ navigateTo: productUrl });
            }}
            sx={theme.picture}
          />
        </Flex>

        {/* center column  — title, quantity etc.. */}
        <Flex sx={theme.column.center}>
          {/* product title */}
          <Paragraph
            sx={theme.title.product}
            onClick={() => {
              if (!inSidebar) return;
              closeSidebar({ navigateTo: productUrl });
            }}
          >
            {item.variant.product.title}
          </Paragraph>

          {/* variant option title */}
          <Paragraph sx={theme.title.variant}>{item.variant.title}</Paragraph>

          {/* Show onetime/subscription option */}
          <Paragraph sx={theme.title.plan}>
            {item?.sellingPlanAllocation?.sellingPlan?.name ||
              'One time purchase'}
          </Paragraph>

          <Quantity
            id={item.id}
            inSidebar={inSidebar}
            quantity={item.quantity}
          />
        </Flex>

        {/* right column — price, remove */}
        <Flex sx={theme.column.right}>
          {/*  Price  */}
          <Locale.CartLine line={item}>
            <Locale.Price>
              {(props) => <Price {...props} inSidebar={inSidebar} />}
            </Locale.Price>
          </Locale.CartLine>

          <Svg
            alt="Remove item from cart"
            src="/svgs/remove.svg#remove"
            viewBox="0 0 48 48"
            sx={theme.remove}
            onClick={async () => {
              await cartRemoveItem({ lineId: item.id });
            }}
          />
        </Flex>
      </Grid>
    );
  }
);

LineItem.displayName = 'LineItem';
LineItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  isLast: PropTypes.bool,
  inSidebar: PropTypes.bool,
};
