import { Box, Grid, Paragraph } from 'theme-ui';

import { Picture } from '@snippets';

import { useMenuDrawer } from '../MenuDrawer';
import { useMenuSidebar } from '../MenuSidebar';
import { themed } from './SubNav.theme';

export const SubNavMedias = themed(({ theme, medias, index, ...props }) => {
  const [{}, { closeDrawerAndNavigate }] = useMenuDrawer();
  const [{}, { closeSideBarAndNavigate }] = useMenuSidebar();

  return (
    <Grid
      data-comp={SubNavMedias.displayName}
      {...props}
      sx={{
        ...props.sx,
        ...theme.medias,
      }}
    >
      {/* Each media link item */}
      {medias?.length &&
        medias.slice(0, 3).map((item, index) => {
          return (
            <Box
              key={`${item.link.text}SubNavMedia${index}`}
              onClick={() => {
                closeDrawerAndNavigate(item);
                closeSideBarAndNavigate(item);
              }}
              sx={theme.media}
            >
              <Picture
                alt={item.link.text}
                images={[{ src: item.image.src, ratio: 1.5, width: 306 }]}
                sx={theme.media.picture}
              />

              <Paragraph sx={theme.media.title}>{item.link.text}</Paragraph>
            </Box>
          );
        })}
    </Grid>
  );
});

SubNavMedias.displayName = 'SubNavMedias';
