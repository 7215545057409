import { useEffect } from 'react';
import { Box } from 'theme-ui';

import animation from '@animations';

import { SubNav } from '../SubNav';
import { AccordionNav } from '../AccordionNav';
import { useMenu } from '../Menu/useMenu';
import { themed } from './MenuSidebar.theme';

/**
 * Renders the mobile nav and links
 */
export const MenuSidebar = themed(({ theme, ...props }) => {
  const { mobile } = useMenu();

  useEffect(() => {
    animation.sidebarContentIn();
  }, []);

  return (
    <Box
      data-comp={MenuSidebar.displayName}
      {...props}
      sx={{
        ...theme.sidebar,
        ...props.sx,
      }}
    >
      {/* Menu items with sub links (accordion) */}
      {mobile?.menu?.nested ? (
        <AccordionNav items={mobile.menu.nested} />
      ) : null}

      {/* Menu items without sub links */}
      {mobile?.menu?.direct ? (
        <SubNav links={mobile.menu.direct} layout="vertical" />
      ) : null}

      {/* best sellers */}
      {mobile?.bestSellers?.products?.length ? ( // links list (about etc..)
        <SubNav products={mobile.bestSellers.products} layout="vertical" />
      ) : null}

      {/* Links list */}
      {mobile?.links ? ( // links list (about etc..)
        <SubNav links={mobile.links} layout="vertical" />
      ) : null}
    </Box>
  );
});

MenuSidebar.displayName = 'MenuSidebar';
