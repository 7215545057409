import { Container } from 'theme-ui';

import { Section } from '@snippets';
import { withInView } from '@utils';
import { colors } from '@theme/theme.colors';

import { Schema } from './AtcBlockQuantified.schema';
import { themed } from './AtcBlockQuantified.theme';
import { Content } from './Content';

export const AtcBlockQuantified = withInView(
  themed(({ theme, cms, inView }) => {
    const { section, content } = cms;
    if (!content) return null;

    return (
      <Section section={section} sx={{ backgroundColor: colors.cream }}>
        <Container
          id={AtcBlockQuantified.displayName}
          data-comp={AtcBlockQuantified.displayName}
        >
          <Content content={content} />
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

AtcBlockQuantified.displayName = 'AtcBlockQuantified';
AtcBlockQuantified.Schema = Schema;
