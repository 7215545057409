import { section } from '@settings/common/section';
import { align } from '@settings/common/align';
import { color } from '@settings/common/color';
import { button } from '@settings/common/button';

export function Schema({ product }) {
  // Enable only on product pages
  if (!product) return null;

  return {
    label: 'Product recs custom',
    key: 'product-recs-custom',
    fields: [
      {
        name: 'heading',
        label: 'Heading',
        component: 'text',
        defaultValue: "We've heard humans like variety",
      },
      {
        name: 'subheading',
        label: 'subheading',
        component: 'text',
        defaultValue: 'explore our other products',
      },
      {
        name: 'textAlign',
        label: 'Text Align',
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        options: align.options,
        defaultValue: align.value.center,
      },
      {
        name: 'products',
        label: 'Products',
        component: 'group-list',
        description: 'Max of 3 products',
        // itemProps: {
        //   return {
        //     label: item.cta.text,
        //   };
        // },
        // validate(list) {
        //   if (list.length > 3) {
        //     window.__bpCMS.alerts.error(`Max of 3 products.`);
        //   }
        // },
        // defaultItem: {
        //   cta: {
        //     text: 'Shop now',
        //     url: '/products/cold-brew-on-tap-96-oz-mocha',
        //   },
        //   style: button.value.primary,
        // }),
        fields: [
          {
            name: 'cta',
            component: 'link',
            label: 'Button',
          },
          {
            name: 'style',
            label: 'Button Style',
            component: 'select',
            options: button.options,
          },
          {
            label: 'product',
            description: 'Select product',
            name: 'product',
            component: 'productSearch',
          },
        ],
        defaultValue: [
          {
            cta: {
              text: 'Cold Brew On Tap (96 oz) - Mocha',
              url: '/products/cold-brew-on-tap-96-oz-mocha',
              newTab: false,
            },
            style: button.value.primary,
            product: {
              handle: 'cold-brew-on-tap-96-oz-mocha',
              title: 'Cold Brew On Tap (96 oz) - Mocha',
            },
          },
        ],
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'content',
            mt: 'none',
            py: 'm',
          },
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
}
