import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'flex.row.start.center',
      bg: 'background',
      color: 'text',
      px: 10,
      py: 8,
    };

    (this.icon = {
      cursor: 'pointer',
      height: 'auto',
      width: 20,
      zIndex: 1,

      left: {
        mr: 9,
      },

      right: {
        ml: 'auto',
      },
    }),
      (this.heading = {
        variant: 'text.base',
        display: 'flex',
        alignItems: 'center',

        count: {
          variant: 'text.sm',
          display: 'inline-block',
          ml: 4,
        },
      });
  })()
);
