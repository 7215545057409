import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.container = {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      mb: 10,
    };
    this.heading = {
      variant: 'text.base',
      fontFamily: 'gtPressura',
      textTransform: 'uppercase',
      my: 6,
    };
    this.superHeading = {
      variant: 'text.h4',
    };
  })()
);
