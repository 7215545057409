import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      mb: [13, 14],
      width: '100%',
    };
  })()
);
