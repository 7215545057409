import { promoBar } from './promoBar';
import { menu } from './menu';
import { bestSellers } from './bestSellers';
import { linksList } from './linksList';

// header
export default {
  label: 'Header Settings',
  name: 'header',
  component: 'group',
  fields: [promoBar, menu, bestSellers, linksList],
  defaultValue: {
    promoBar: {
      enabled: true,
      closable: true,
      duration: 3,
      speed: 0.75,
      messages: [
        {
          color: '#FFFEF9',
          background: '#113034',
          copy: '<p><b>SPECIAL SALE: 20% off all bikes. No code needed.</b> | Free shipping on orders over $300<p>',
          link: {
            text: '',
            url: '',
          },
        },
      ],
    },
  },
};
