import { Box } from 'theme-ui';

import { Svg } from '@snippets';
import { useSearch, useSidebar } from '@hooks';

import { themed } from '../Menu.theme';

export const CtaSearch = themed(({ theme, ...props }) => {
  const [{ isSearching }] = useSearch();
  const [{}, { openSearchSidebar }] = useSidebar();

  return (
    <Box
      data-comp={CtaSearch.displayName}
      onClick={openSearchSidebar}
      {...props}
      sx={{
        ...props.sx,
        ...theme.cta,
      }}
    >
      {isSearching ? (
        <Svg
          alt="Close search"
          src="/svgs/cta/searching.svg#searching"
          sx={theme.cta.icon}
          viewBox="0 0 48 48"
        />
      ) : (
        <Svg
          alt="Open search"
          viewBox="0 0 48 48"
          src="/svgs/cta/search.svg#search"
          sx={theme.cta.icon}
        />
      )}
    </Box>
  );
});

CtaSearch.displayName = 'CtaSearch';
