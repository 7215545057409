import { useSidebar } from '@hooks';
import { SidebarShell } from '@snippets';

import { CartSearchSidebar } from './CartSearchSidebar';

// Desktop menu drawer — hovered item sub items
export function CartSearchSidebarShell(props) {
  const [{ sidebar }] = useSidebar();

  return (
    <SidebarShell open={sidebar}>
      {sidebar ? <CartSearchSidebar /> : null}
    </SidebarShell>
  );
}

CartSearchSidebarShell.displayName = 'CartSearchSidebarShell';
