import { Box, Container, ThemeProvider } from 'theme-ui';
import {
  useSettings,
  useRouter,
  useStorefrontInit,
  useCartAddDiscountUrl,
} from '@backpackjs/storefront';

import {
  Header,
  Footer,
  Overlay,
  // CartSearchSidebarShell,
  Modal,
} from '@snippets';

import { useTrackSettings, useSetViewportHeightCssVar } from '@hooks';

import * as Head from '../head';
import StorefrontTheme from '../theme';

function StorefrontLayout({ ContentForLayout, ...props }) {
  const settings = useSettings();
  const { asPath } = useRouter();
  const isProduct = asPath.startsWith('/products');
  // initialize customer, cart, shop and markets
  useStorefrontInit();

  // adds --viewport-height css var to the :root
  useSetViewportHeightCssVar();

  // auto applies cart discount found on the landing url
  useCartAddDiscountUrl();

  // track siteSetting changes in recoil
  useTrackSettings();

  return (
    <ThemeProvider theme={StorefrontTheme}>
      {/* <head> links, scripts... */}
      <Head.Fonts />
      <Head.Libs />
      <Head.Preconnects />
      <Head.Metas />

      {/* {settings?.header ? <Header /> : null} //?? do we want the navigation for all pages? */}
      {isProduct && settings?.header ? <Header /> : null}

      {/* main content */}
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'hidden',
          minHeight:
            'calc(var(--viewport-height) - var(--header-height) - var(--promoBar-height))',
          alignItems: 'stretch',
        }}
      >
        <Container
          as="main"
          role="main"
          sx={{
            flexGrow: 1,
            flexShrink: 0,
          }}
        >
          <ContentForLayout {...props} key={asPath} />
        </Container>

        <Box sx={{ flexShrink: 0 }}>{settings?.footer ? <Footer /> : null}</Box>
      </Box>

      <Overlay />

      {/* <CartSearchSidebarShell /> */}

      <Modal />
    </ThemeProvider>
  );
}

// eslint-disable-next-line import/no-default-export
export default StorefrontLayout;
