import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Flex } from 'theme-ui';

import { themed } from './Price.theme';

export const Price = themed(
  ({
    theme: themes,
    discountAllocations,
    estimatedCost,
    inSidebar,
    isDiscounted,
    isOnSale,
    isSubscription,
    sellingPlanAllocation,
  }) => {
    // apply the sidebar or cart page context
    const theme = inSidebar ? themes.sidebar : themes.page;

    return (
      <Flex data-comp={Price.displayName} sx={theme.price}>
        {isSubscription ? (
          <>
            {estimatedCost?.compareAtPriceAmount ? (
              <Paragraph sx={theme.price.strike}>
                {/* The full price before any savings or discounts */}
                {estimatedCost.compareAtPriceAmount.localeTotal}
              </Paragraph>
            ) : null}

            {/* the savings amount applied by the subscription if any */}
            {estimatedCost.savingsAmount ? (
              <Paragraph sx={theme.price.regular}>
                -{estimatedCost.savingsAmount.localeTotal}
              </Paragraph>
            ) : null}

            {/* the total including any applied discounts */}
            {estimatedCost.savingsAmount ? (
              <Paragraph sx={theme.price.regular}>
                {estimatedCost.subtotalAmount.locale}
              </Paragraph>
            ) : null}

            {/* the discounted amount from any applicable discount codes */}
            {isDiscounted ? (
              <Paragraph sx={theme.price.regular}>
                -{estimatedCost.discountAmount.localeTotal}
              </Paragraph>
            ) : null}

            <Paragraph sx={theme.price.regular}>
              {estimatedCost.totalAmount.locale}
            </Paragraph>
          </>
        ) : (
          /*
          One time cart line item
        */
          <>
            {/* The full price before any savings or discounts */}
            {isOnSale ? (
              <Paragraph sx={theme.price.strike}>
                {estimatedCost.compareAtPriceAmount.localeTotal}
              </Paragraph>
            ) : null}

            {/* the savings amount if on Sale */}
            {isOnSale ? (
              <Paragraph sx={theme.price.regular}>
                -{estimatedCost.savingsAmount.localeTotal}
              </Paragraph>
            ) : null}

            {/* Total before discount, but after savings */}
            {isDiscounted ? (
              <Paragraph sx={theme.price.strike}>
                {estimatedCost.subtotalAmount.locale}
              </Paragraph>
            ) : null}

            {/* the discounted amount applied if any */}
            {isDiscounted ? (
              <Paragraph sx={theme.price.regular}>
                -{estimatedCost.discountAmount.localeTotal}
              </Paragraph>
            ) : null}

            {/* the total including savings and any applied discounts */}
            <Paragraph sx={theme.price.regular}>
              {estimatedCost.totalAmount.locale}
            </Paragraph>
          </>
        )}
      </Flex>
    );
  }
);

Price.displayName = 'Price';
Price.propTypes = {
  discountAllocations: PropTypes.array,
  estimatedCost: PropTypes.object,
  inSidebar: PropTypes.bool,
  isOnSale: PropTypes.bool,
  isSubscription: PropTypes.bool,
  locale: PropTypes.object,
  sellingPlanAllocation: PropTypes.object,
};
