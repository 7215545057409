import { Container, Box } from 'theme-ui';

import { themed } from './Displays.theme';
import { Tile } from '../Tile';
import 'swiper/css/bundle';

export const GridDisplay = themed(({ theme, tiles, tile }) => {
  return (
    <Container data-comp={GridDisplay.displayName} sx={theme.grid}>
      {tiles?.slice(0, 4).map((item, index) => {
        return (
          <Box
            key={index}
            // Adds minimum aspect ratio before ThreeileItem js loads
            sx={{
              ...(tile?.enableIconDisplay
                ? theme.icon
                : tile?.imageRatio !== 'square'
                ? theme.square
                : theme.portrait),
            }}
          >
            <Tile item={item} tile={tile} />
          </Box>
        );
      })}
    </Container>
  );
});

GridDisplay.displayName = 'GridDisplay';
