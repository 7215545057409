import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.grid = {
      position: 'relative',
      display: 'grid',
      gridGap: 0,
      gridTemplateColumns: ['1fr', '1fr 1fr'],
    };

    this.content = {
      gridArea: 'content',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      width: '100%',
      height: '100%',
    };
  })()
);
