import { Box } from 'theme-ui';
import { useInView } from 'react-intersection-observer';
import { useProductByHandle } from '@backpackjs/storefront';

import { Image } from './Image';
import { Details } from './Details';
import { themed } from './ProductItem.theme';

export const ProductItem = themed(({ theme, product, ...props }) => {
  if (!product) return null;

  const { ref, inView } = useInView({
    rootMargin: '400px',
    triggerOnce: true,
  });

  const { product: fullProduct } = useProductByHandle({
    handle: product.handle,
    fetchOnMount: inView,
  });

  return (
    <Box
      data-comp={ProductItem.displayName}
      ref={ref}
      {...props}
      sx={{
        ...theme.wrapper,
        ...props.sx,
      }}
    >
      <Image product={product} fullProduct={fullProduct} />

      <Details product={product} fullProduct={fullProduct} />
    </Box>
  );
});

ProductItem.displayName = 'ProductItem';
