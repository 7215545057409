import { Container } from 'theme-ui';

import { Link } from '@snippets';
import { convertAlignToFlex, pxWithFullBleed } from '@utils/common';

import { themed } from './Content.theme';

export const Footer = themed(({ theme, header, section }) => {
  return header?.ctas?.[0]?.cta?.text ? (
    <Container
      sx={{
        ...theme.footer,
        ...pxWithFullBleed(section),
        justifyContent: convertAlignToFlex(header.textAlign),
      }}
    >
      <Link
        variant={`buttons.${header.ctas[0].style || 'primary'}`}
        href={header.ctas[0].cta.url}
      >
        {header.ctas[0].cta.text}
      </Link>
    </Container>
  ) : null;
});

Footer.displayName = 'Footer';
