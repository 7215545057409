import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.shell = {
      bg: 'background',
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      display: ['none', null, 'block'],
      left: 0,
      opacity: 0,
      position: 'absolute',
      top: '100%',
      width: '100%',
      zIndex: -1,
      visibility: 'hidden',

      closed: {
        borderTopColor: 'transparent',
        boxShadow: 'none',
      },

      // revealed via animation
      open: {
        borderTopColor: 'lightGray',
        boxShadow: 'bottom',
      },
    };

    this.drawer = {
      variant: 'layout.drawer',
      bg: 'background',
      py: [10, 11, 12],
    };
  })()
);
