import Head from 'next/head';

function HeadPreconnects() {
  return (
    <Head>
      <link
        key="cdn.shopify.com-prefetch"
        rel="dns-prefetch"
        href="https://cdn.shopify.com/"
      />
      <link
        key="cdn.shopify-prefetch"
        rel="preconnect"
        href="https://cdn.shopify.com/"
        crossOrigin="anonymous"
      />

      <link
        key="monorail-edge.shopifysvc.com-prefetch"
        rel="dns-prefetch"
        href="https://monorail-edge.shopifysvc.com/"
      />
      <link
        key="monorail-edge.shopifysvc.com-preconnect"
        rel="preconnect"
        href="https://monorail-edge.shopifysvc.com/"
        crossOrigin="anonymous"
      />

      <link
        key="googletagmanager-prefetch"
        rel="dns-prefetch"
        href="https://www.googletagmanager.com/"
      />
      <link
        key="googletagmanager-preconnect"
        rel="preconnect"
        href="https://www.googletagmanager.com/"
        crossOrigin="anonymous"
      />

      <link
        key="googleads-prefetch"
        rel="dns-prefetch"
        href="https://googleads.g.doubleclick.net/"
      />
      <link
        key="googleads-preconnect"
        rel="preconnect"
        href="https://googleads.g.doubleclick.net/"
        crossOrigin="anonymous"
      />

      <link
        key="googleadservices-prefetch"
        rel="dns-prefetch"
        href="https://www.googleadservices.com/"
      />
      <link
        key="googleadservices-preconnect"
        rel="preconnect"
        href="https://www.googleadservices.com/"
        crossOrigin="anonymous"
      />

      <link
        key="google-analytics-prefetch"
        rel="dns-prefetch"
        href="https://www.google-analytics.com/"
      />
      <link
        key="google-analytics-preconnect"
        rel="preconnect"
        href="https://www.google-analytics.com/"
        crossOrigin="anonymous"
      />

      <link
        key="fonts-googleapis-prefetch"
        rel="dns-prefetch"
        href="https://fonts.googleapis.com/"
      />
      <link
        key="fonts-googleapis-preconnect"
        rel="preconnect"
        href="https://fonts.googleapis.com/"
        crossOrigin="anonymous"
      />

      <link
        key="gstatic-prefetch"
        rel="dns-prefetch"
        href="https://fonts.gstatic.com/"
      />
      <link
        key="gstatic-preconnect"
        rel="preconnect"
        href="https://fonts.gstatic.com/"
        crossOrigin="anonymous"
      />

      <link
        key="ajax-googleapis-prefetch"
        rel="dns-prefetch"
        href="https://ajax.googleapis.com/"
      />
      <link
        key="ajax-googleapis-preconnect"
        rel="preconnect"
        href="https://ajax.googleapis.com/"
        crossOrigin="anonymous"
      />

      <link
        key="analytics-tiktok-prefetch"
        rel="dns-prefetch"
        href="https://analytics.tiktok.com/"
      />
      <link
        key="analytics-tiktok-preconnect"
        rel="preconnect"
        href="https://analytics.tiktok.com/"
        crossOrigin="anonymous"
      />

      <link
        key="snapchat-prefetch"
        rel="dns-prefetch"
        href="https://tr.snapchat.com/"
      />
      <link
        key="snapchat-preconnect"
        rel="preconnect"
        href="https://tr.snapchat.com/"
        crossOrigin="anonymous"
      />

      <link
        key="facebook-prefetch"
        rel="dns-prefetch"
        href="https://www.facebook.com/"
      />
      <link
        key="facebook-preconnect"
        rel="preconnect"
        href="https://www.facebook.com/"
        crossOrigin="anonymous"
      />

      <link
        key="facebook-prefetch"
        rel="dns-prefetch"
        href="https://www.facebook.com/"
      />
      <link
        key="facebook-preconnect"
        rel="preconnect"
        href="https://www.facebook.com/"
        crossOrigin="anonymous"
      />

      <link
        key="connect.facebook-prefetch"
        rel="dns-prefetch"
        href="https://connect.facebook.net/"
      />
      <link
        key="connect.facebook-preconnect"
        rel="preconnect"
        href="https://connect.facebook.net/"
        crossOrigin="anonymous"
      />

      <link
        key="connect.facebook-prefetch"
        rel="dns-prefetch"
        href="https://connect.facebook.net/"
      />
      <link
        key="connect.facebook-preconnect"
        rel="preconnect"
        href="https://connect.facebook.net/"
        crossOrigin="anonymous"
      />

      <link
        key="polyfill-prefetch"
        rel="dns-prefetch"
        href="https://cdn.polyfill.io/"
      />
      <link
        key="polyfill-preconnect"
        rel="preconnect"
        href="https://cdn.polyfill.io/"
        crossOrigin="anonymous"
      />

      <link
        key="cloudflare-prefetch"
        rel="dns-prefetch"
        href="https://cdnjs.cloudflare.com/"
      />
      <link
        key="cloudflare-preconnect"
        rel="preconnect"
        href="https://cdnjs.cloudflare.com/"
        crossOrigin="anonymous"
      />

      {/* Project specific */}
      <link
        key="storefront-prefetch"
        rel="dns-prefetch"
        href="https://backpack-storefront.myshopify.com/"
      />
      <link
        key="storefront-preconnect"
        rel="preconnect"
        href="https://backpack-storefront.myshopify.com/"
        crossOrigin="anonymous"
      />

      {/* Todo: add yotpo and other third-party domains */}
    </Head>
  );
}

export default HeadPreconnects;
