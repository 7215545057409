import { useEffect, useRef, useCallback } from 'react';
import { Flex, Box } from 'theme-ui';

import { gsap } from 'gsap';

import { Link } from '@snippets';

import { PromoBarMessageCopy } from './PromoBarMessageCopy';
import { themed } from './Promo.theme';

export const PromoBarMessage = themed(
  ({
    theme,
    link,
    copy,
    color,
    background,
    align,
    isActive,
    index,
    ...props
  }) => {
    const copyRef = useRef();

    const resetAnimIn = useCallback(() => {
      gsap
        .timeline()
        .to(copyRef.current, {
          alpha: 0,
          duration: 0.4,
          ease: 'expo',
        })
        .to(copyRef.current, {
          y: '20%',
        });
    }, []);

    const animateIn = useCallback(() => {
      gsap.to(copyRef.current, {
        y: '0%',
        alpha: 1,
        delay: 0.4,
        duration: 1,
        ease: 'expo',
      });
    }, []);

    useEffect(() => {
      if (!isActive) resetAnimIn();
      else animateIn();
    }, [isActive]);

    return (
      <Flex data-comp={PromoBarMessage.displayName} sx={theme.message}>
        <Box
          data-comp="PromoBarMessageCopyWrapper"
          ref={copyRef}
          sx={theme.message.copy}
        >
          {link?.url ? (
            <Link href={link.url} prefetch={false}>
              <PromoBarMessageCopy copy={copy} align={align} />
            </Link>
          ) : (
            <PromoBarMessageCopy copy={copy} align={align} />
          )}
        </Box>
      </Flex>
    );
  }
);

PromoBarMessage.displayName = 'PromoBarMessage';
