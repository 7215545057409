import { icons } from '@settings/common';

export const whySubscribe = {
  label: 'subscription settings',
  name: 'subscription',
  component: 'group',
  fields: [
    {
      label: 'Main Label',
      name: 'label',
      component: 'text',
      defaultValue: '10% OFF WITH MONTHLY SUBSCRIPTION',
    },
    {
      label: 'Secondary Label',
      name: 'subLabel',
      component: 'text',
      defaultValue: '*SKIP OR CANCEL ANYTIME.',
    },
    {
      label: 'Badge toggle',
      name: 'showBadge',
      component: 'toggle',
      description: 'you can select the default subscription toggle state',
      toggleLabels: {
        true: 'Checked',
        false: 'Unchecked',
      },
      defaultValue: true,
    },
    {
      label: 'Badge Text',
      name: 'badge',
      component: 'text',
      description: 'set the text for the discount badge',
      defaultValue: 'save 10%',
    },
    {
      label: 'Supplementary information',
      name: 'additional',
      description: 'you can add additional information',
      component: 'group',
      fields: [
        {
          label: 'Hide / show additional information',
          name: 'showSupplementary',
          component: 'toggle',
          description: 'you can show or hide the additional information',
          toggleLabels: {
            true: 'Show',
            false: 'Hide',
          },
          defaultValue: true,
        },
        {
          label: 'Additional information label',
          name: 'label',
          component: 'text',
          defaultValue: 'WHY SUBSCRIBE?',
        },
        {
          label: 'Text settings',
          name: 'text',
          description: 'Set heading and subheading',
          component: 'group',
          fields: [
            {
              label: 'title',
              name: 'title',
              component: 'markdown',
              description:
                'You can use markdown e.g. <u>**bold**</u> or # for heading',
              defaultValue: 'WHY SUBSCRIBE?',
            },
            {
              label: 'subtitle',
              name: 'subtitle',
              component: 'markdown',
              description:
                'You can use markdown e.g. <u>**bold**</u> or ## for subheading',
              defaultValue:
                'SAVE MONEY AND NEVER WORRY ABOUT RUNNING OUT OF COFFEE WITH FRESH BREW SHIPPED ON YOUR SCHEDULE.',
            },
          ],
        },
        {
          label: 'Items',
          name: 'items',
          description: 'You can add title, description and icon',
          component: 'group-list',
          itemProps: {
            label: '{{item.title}}' || 'new item',
          },
          validate: {
            maxItems: 4,
          },
          defaultItem: {
            title: 'SAVE 10% ON EVERY ORDER',
            description:
              'We’ll reward your commitment to caffeination with a 10% discount on every order.',
            icon: icons.value.star,
          },
          fields: [
            {
              label: 'Title',
              name: 'title',
              component: 'text',
            },
            {
              label: 'Description',
              name: 'description',
              component: 'textarea',
            },
            {
              label: 'Icon',
              name: 'icon',
              component: 'select',
              description: 'Select the icon',
              options: icons.options,
              defaultValue: icons.value.star,
            },
          ],
          defaultValue: [
            {
              title: 'SAVE 10% ON EVERY ORDER',
              description:
                'We’ll reward your commitment to caffeination with a 10% discount on every order.',
              icon: icons.value.star,
            },
          ],
        },
      ],
      defaultValue: {
        label: 'WHY SUBSCRIBE?',
        showSupplementary: true,
        text: {
          title: '# WHY SUBSCRIBE?',
          subtitle:
            'SAVE MONEY AND NEVER WORRY ABOUT RUNNING OUT OF COFFEE WITH FRESH BREW SHIPPED ON YOUR SCHEDULE.',
        },
        items: [
          {
            title: 'SAVE 10% ON EVERY ORDER',
            description:
              'We’ll reward your commitment to caffeination with a 10% discount on every order.',
            icon: icons.value.star,
          },
          {
            title: 'DELIVERED ON YOUR SCHEDULE',
            description:
              'Total flexibility. Cancel, skip, reschedule or change your products anytime you want in your account.',
            icon: icons.value.strong,
          },
          {
            title: 'FAST, FRESH, AND NO COMMITMENTS',
            description:
              'Free and fast shipping, concierge customer service, and satisfaction guaranteed!',
            icon: icons.value.leaves,
          },
        ],
      },
    },
  ],
};
