import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      position: 'relative',
      mb: 10,
      height: 54,
    };

    this.field = {
      variant: 'forms.input.search',
    };

    this.icon = {
      position: 'absolute',
      top: '18px',
      width: '20px',
      height: '20px',
      cursor: 'pointer',
    };

    this.searching = {
      ...this.icon,
      cursor: 'none',
      color: 'text',
      left: 8,
      zIndex: 1,
    };

    this.clear = {
      hidden: {
        ...this.icon,
        right: 8,
        display: 'none',
      },
      visible: {
        ...this.icon,
        right: 8,
        display: 'block',
      },
    };
  })()
);
