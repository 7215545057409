import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.subtitle = {
      variant: 'text.base',
      '@media only screen and (max-width: 479px)': {
        variant: 'text.sm',
      },
    };
  })()
);
