import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    (this.container = ({ cms }) => {
      const bgColor2 = (cms.columns || [])[1]?.bgColor || 'green';
      return {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        transition: 'all 0.3s ease-in-out',
        backgroundColor: bgColor2,
      };
    }),
      (this.columnContainer = ({ cms }) => {
        const bgColor1 = (cms.columns || [])[0]?.bgColor || 'yellow';
        const bgColor2 = (cms.columns || [])[1]?.bgColor || 'green';
        return {
          display: 'flex',
          flexDirection: ['column', 'row'],
          flexWrap: 'wrap',
          gap: '10%',
          width: '100%',
          px: ['2rem', '5rem'],
          justifyContent: 'center',
          alignItems: 'center',
          background: [
            `linear-gradient(-20deg, ${bgColor2} 0% 50.9%, ${bgColor1} 51% 100%)`,
            `linear-gradient(-12deg, ${bgColor2} 0% 49.9%, ${bgColor1} 50% 100%)`,
            null,
            `linear-gradient(-5deg, ${bgColor2} 0% 49.9%, ${bgColor1} 50% 100%)`,
          ],
        };
      });
    this.column = ({ column, index }) => ({
      position: 'relative',
      width: ['75%', '30%'],
      minWidth: '250px',
      display: 'flex',
      flexDirection: index ? 'column-reverse' : 'column',
      alignItems: index ? 'flex-end' : 'flex-start',
      textAlign: index ? ['left', 'right'] : ['right', 'left'],
      color: column.color,
      pt: '4rem',
    });
    this.copy = ({ index }) => ({
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: index
        ? ['flex-start', 'flex-end']
        : ['flex-end', 'flex-start'],
      '& > svg': {
        position: 'absolute',
        left: '-2.75rem',
        top: '12rem',
      },
    });
    this.text = {
      position: 'relative',
      fontSize: ['45px', '56px'],
      fontWeight: 'bold',
      // The second arrow
      '& > svg': {
        position: 'absolute',
        top: ['-8rem', '-3.5rem'],
        right: ['auto', '-4rem'],
        left: ['-1rem', 'auto'],
        transform: ['rotateX(180deg)', 'rotate(200deg)'],
      },
    };
    this.subtext = {
      position: 'relative',
      fontSize: '20px',
      maxWidth: '27rem',
    };
    this.image = ({ index }) => {
      const rot = [-6.37, 6.81];
      const rotMob = [-6.37, 6.81];
      const trans = ['9', '8'];
      const transMob = ['-20', '20'];

      return {
        position: 'relative',
        width: '100%',
        // The first arrow
        '& > svg': {
          position: 'absolute',
          left: ['', '-3rem'],
          right: ['-1rem', ''],
          top: ['1rem', ''],
          transform: ['rotateY(180deg)', 'rotateY(0deg)'],
        },
        '& > img': {
          transform: [
            `translateX(${transMob[index]}%) rotate(${rotMob[index]}deg)`,
            `translateX(${trans[index]}%) rotate(${rot[index]}deg)`,
          ],
          width: '100%',
          minWidth: '250px',
          pt: index ? '' : '2rem',
          pb: index ? '3rem' : '',
        },
      };
    };
    this.cta = {
      fontSize: '20px',
      fontWeight: 'bold',
      width: '296px',
      background: colors.white,
      my: ['56px'],
      '& > span': {
        py: '19px',
      },
    };
  })()
);
