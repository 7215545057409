import { Container, Heading } from 'theme-ui';

import { convertAlignToFlex } from '@utils';

import { Markdown } from '@snippets';
import { themed } from './Header.theme';

export const Header = themed(({ theme, header }) => {
  const {
    heading,
    subheading,
    textAlign,
    headingColor,
    subheadingColor,
    maxWidth,
  } = header;
  return (
    header?.heading && (
      <Container
        data-comp={Header.displayName}
        sx={{
          ...theme.header,
          alignItems: convertAlignToFlex(textAlign),
          textAlign: { textAlign },
          maxWidth,
        }}
      >
        <Markdown
          variant="text.h2"
          text={heading}
          textAlign={textAlign}
          sx={{
            color: headingColor,
          }}
        />
        <Heading
          sx={{
            ...theme.heading,
            textAlign,
            color: subheadingColor,
          }}
        >
          {subheading}
        </Heading>
      </Container>
    )
  );
});

Header.displayName = 'Header';
