import { Flex } from 'theme-ui';
import { Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Svg } from '@snippets';

import { PromoBarMessage } from './PromoBarMessage';
import { usePromobar } from './usePromobar';
import { themed } from './Promo.theme';

export const PromoBar = themed(({ theme, ...props }) => {
  const [
    {
      promoBarRef,
      swiperRef,
      currentColor,
      bgColor,
      isDisabled,
      singleMessage,
      promoBar,
    },
    { animateClose, updateActiveIndex, setSwiper },
  ] = usePromobar();

  return isDisabled ? null : (
    <Flex
      data-comp={PromoBar.displayName}
      ref={promoBarRef}
      sx={{
        ...theme.wrapper,
        color: currentColor,
        bg: bgColor,
      }}
    >
      {/* Close X */}
      <Svg
        data-comp="PromoBarClose"
        alt="Close promotional bar"
        src="/svgs/close.svg#close"
        viewBox="0 0 48 48"
        onClick={animateClose}
        sx={theme.close}
      />

      {/* Single message (no swiper), multiple swiper */}
      {singleMessage ? (
        <PromoBarMessage {...promoBar.messages[0]} isActive />
      ) : (
        <Swiper
          data-comp="PromoBarMessagesSlider"
          init
          autoplay={{
            enabled: true,
            delay: promoBar.duration * 1000,
          }}
          ref={swiperRef}
          watchSlidesProgress
          fadeEffect={{ crossFade: true }}
          speed={promoBar.speed * 1000}
          effect="fade"
          onSwiper={setSwiper}
          onSlideChange={updateActiveIndex}
          sx={theme.swiper}
          modules={[EffectFade, Autoplay]}
        >
          {promoBar.messages.map((message, index) => (
            <SwiperSlide key={index + message?.copy?.slice(0, 10)}>
              {({ isActive }) => {
                return <PromoBarMessage {...message} isActive={isActive} />;
              }}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </Flex>
  );
});

PromoBar.displayName = 'PromoBar';
