import { Picture } from '@snippets';

export function ShopifyImage({
  alt,
  src,
  ratio = 0.75,
  id,
  aboveTheFold,
  ...props
}) {
  return (
    <Picture
      alt={alt || 'Product image'}
      key={id}
      aboveTheFold={aboveTheFold}
      images={[
        { src, ratio, width: 768 },
        { src, ratio, width: 1024 },
        { src, ratio, width: 768 },
      ]}
    />
  );
}
