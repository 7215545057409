import { useCallback } from 'react';
import { Box, Flex, Spinner, Paragraph } from 'theme-ui';
import { useCountry, useCountries } from '@backpackjs/storefront';

import { Svg, Dropdown } from '@snippets';

import { themed } from './VariantsSelector.theme';
import { swatches } from '@settings/product/swatches';

export const VariantsSelector = themed(({ theme, ...props }) => {

  return (
    <Dropdown
      variant="swatches"
      initial={props.selectedSwatch}
      onSelected={props.handleSwatchSelector}
      {...props}
      sx={{
        ...props.sx,
        ...theme.dropdown,
      }}
    >
      <Dropdown.Selected sx={{ height: 'auto' }}>
        {({ selecting, selected }) => (
          <Flex
            sx={{
              ...theme.item,
              justifyContent: 'space-between',
            }}
          >
            <Flex
              sx={{
                alignItems: 'center',
                columnGap: '8px'
              }}
            >
              <Flex sx={{
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: selected.color,
              }}>
                <Svg
                  alt="Swatch Color"
                  viewBox="0 0 48 48"
                  src='/svgs/checked.svg'
                  sx={{
                    width: '18',
                  }}
                />
              </Flex>
              <Paragraph sx={theme.item.name}>{selected.title}</Paragraph>
            </Flex>

            <Svg
              alt="Select a swatch"
              viewBox="0 0 48 48"
              src='/svgs/chevron/dropdown.svg'
              sx={{
                width: '15px',
                transform: selecting ? `rotate(180deg)` : `rotate(0)`,
                backgroundColor: 'transparent',
              }}
            />
          </Flex>
        )}
      </Dropdown.Selected>

      {props?.swatches?.map((swatch) => (
        <Dropdown.Option key={swatch.title} option={swatch}>
          {/* todo: theme values do not auto refresh */}
          {({ selecting, isSelected, option }) =>
            isSelected ? null : (
              <Flex
                sx={{
                  ...theme.item,
                  bg: isSelected ? 'lightGray' : 'background',
                  columnGap: '8px',
                }}
              >
                <Flex sx={{
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: option.color,
                }}>
                  {props.selectedSwatch.title == swatch.title &&
                    <Svg
                      alt="Swatch Color"
                      viewBox="0 0 48 48"
                      src='/svgs/checked.svg'
                      sx={{
                        width: 18,
                      }}
                    />
                  }
                </Flex>
                <Paragraph sx={theme.item.name}>{option.title}</Paragraph>
              </Flex>
            )
          }
        </Dropdown.Option>
      ))}
    </Dropdown>
  );
});

VariantsSelector.displayName = 'VariantsSelector';
