import { section } from '@settings/common/section';
import { align } from '@settings/common/align';
import { color } from '@settings/common/color';
import { icons } from '@settings/common/icons';

export function Schema({ article, blog, collection }) {
  if (article || blog || collection) return null;

  return {
    label: 'ATC Block Subscribe',
    key: 'atc-block-subscribe',
    fields: [
      {
        label: 'Text settings',
        name: 'text',
        description: 'Heading,  subheading,',
        component: 'group',
        fields: [
          {
            label: 'Heading',
            name: 'heading',
            description:
              'You can use markdown e.g. <u>**bold**</u> or # for heading',
            component: 'markdown',
          },
          {
            label: 'Heading Color',
            name: 'headingColor',
            component: 'select',
            options: color.options,
          },
          {
            label: 'Subheading',
            name: 'subheading',
            component: 'text',
          },
          {
            label: 'Subheading Color',
            name: 'subheadingColor',
            component: 'select',
            options: color.options,
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'textAlign',
            description: "Doesn't apply on desktop when group list is inline",
            label: 'Heading alignment',
            options: align.options,
          },
          {
            label: 'Max Width',
            name: 'maxWidth',
            component: 'number',
            description: 'Set max width for heading and subheading',
            defaultValue: 600,
          },
        ],
        defaultValue: {
          heading: '## LOOKING FOR SMOOTH, STRONG ORGANIC COFFEE ON TAP?',
          headingColor: color.value.black,
          subheading: 'CHOOSE YOUR FLAVOR',
          subheadingColor: color.value.orange,
          textAlign: align.value.center,
          maxWidth: 600,
        },
      },
      {
        label: 'Content settings',
        name: 'content',
        description:
          'Select variants, customize box, subscribe button, modal content',
        component: 'group',
        fields: [
          {
            label: 'Variant settings',
            name: 'variants',
            description: 'Set variant options',
            component: 'group',
            fields: [
              {
                label: 'Heading',
                name: 'heading',
                component: 'text',
              },
              {
                label: 'Select Variants',
                name: 'variants',
                description: 'Select variants, Name, color product',
                component: 'group',
                fields: [
                  {
                    label: 'Variants',
                    name: 'variants',
                    component: 'group-list',
                    itemProps: {
                      label: '{{item.title}}',
                    },
                    defaultItem: {
                      title: 'Mocha',
                      color: color.value.red,
                      limited: false,
                      product: {
                        handle: 'cold-brew-on-tap-96-oz-mocha',
                        title: 'Cold Brew On Tap (96 oz) - Mocha',
                      },
                    },
                    fields: [
                      {
                        label: 'Title',
                        name: 'title',
                        component: 'text',
                      },
                      {
                        label: 'Color',
                        name: 'color',
                        component: 'select',
                        options: color.options,
                      },
                      {
                        label: 'product',
                        description: 'Select product',
                        name: 'product',
                        component: 'productSearch',
                      },
                      {
                        label: 'Limited edition',
                        name: 'limited',
                        component: 'toggle',
                        description: 'Set this variant as limited edition',
                        toggleLabels: {
                          true: 'On',
                          false: 'Off',
                        },
                        defaultValue: false,
                      },
                    ],
                    defaultValue: [
                      {
                        title: 'Mocha',
                        color: color.value.red,
                        limited: false,
                        product: {
                          handle: 'cold-brew-on-tap-96-oz-mocha',
                          title: 'Cold Brew On Tap (96 oz) - Mocha',
                        },
                      },
                      {
                        title: 'Caramel',
                        color: color.value.yellow,
                        limited: false,
                        product: {
                          handle: 'cold-brew-on-tap-96-oz-caramel',
                          title: 'Cold Brew On Tap (96 oz) - Caramel',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                label: 'Box settings',
                name: 'boxes',
                component: 'group-list',
                itemProps: {
                  label: '{{item.title}}',
                },
                validate(list) {
                  if (list.length > 2) {
                    window.__bpCMS.alerts.error(`Max of 2 tiles only.`);
                  }
                },
                defaultItem: {
                  title: '1 Box',
                  subtitle: '16 glasses | 2.02/glass',
                  totalGlasses: 16,
                  badge: 'you save 17%',
                  showBadge: true,
                  default: false,
                },
                fields: [
                  {
                    label: 'Default',
                    name: 'default',
                    component: 'toggle',
                    description: 'Set this variant as default selected',
                    toggleLabels: {
                      true: 'On',
                      false: 'Off',
                    },
                    defaultValue: false,
                  },
                  {
                    label: 'Title',
                    name: 'title',
                    component: 'text',
                  },
                  {
                    label: 'subtitle',
                    name: 'subtitle',
                    component: 'text',
                  },
                  {
                    label: 'Total glasses per box',
                    name: 'totalGlasses',
                    description:
                      'put the total glasses per box to calculate the price per glass',
                    component: 'number',
                    validate(glasses) {
                      if (glasses < 0) {
                        window.__bpCMS.alerts.error(`Value can't minus than 0`);
                      }
                    },
                  },
                  {
                    label: 'Savings badge',
                    description: 'set a savings badge text',
                    name: 'badge',
                    component: 'text',
                  },
                  {
                    label: 'Show savings badge',
                    name: 'showBadge',
                    component: 'toggle',
                    description: 'show /hide savings badge',
                    toggleLabels: {
                      true: 'On',
                      false: 'Off',
                    },
                    defaultValue: true,
                  },
                ],
                defaultValue: [
                  {
                    title: '1 Box',
                    subtitle: '16 glasses | 2.02/glass',
                    totalGlasses: 16,
                    badge: 'you save 17%',
                    default: false,
                    showBadge: true,
                  },
                  {
                    title: '2 Boxes',
                    subtitle: '32 glasses | 1.69/glass',
                    totalGlasses: 32,
                    badge: 'you save 17%',
                    default: true,
                    showBadge: true,
                  },
                ],
              },
            ],
            defaultValue: {
              heading: 'HOW MUCH COFFEE DO YOU WANT?',
              variants: {
                variants: [
                  {
                    title: 'Mocha',
                    color: color.value.red,
                    limited: false,
                    product: {
                      handle: 'cold-brew-on-tap-96-oz-mocha',
                      title: 'Cold Brew On Tap (96 oz) - Mocha',
                    },
                  },
                  {
                    title: 'Caramel',
                    color: color.value.yellow,
                    limited: false,
                    product: {
                      handle: 'cold-brew-on-tap-96-oz-caramel',
                      title: 'Cold Brew On Tap (96 oz) - Caramel',
                    },
                  },
                ],
              },
              boxes: [
                {
                  default: false,
                  title: '1 Box',
                  subtitle: '16 glasses | 2.02/glass',
                  totalGlasses: 16,
                  badge: 'you save 17%',
                  showBadge: false,
                },
                {
                  default: true,
                  title: '2 Boxes',
                  subtitle: '32 glasses | 1.69/glass',
                  totalGlasses: 32,
                  badge: 'you save 17%',
                  showBadge: true,
                },
              ],
            },
          },
          {
            label: 'subscription settings',
            name: 'subscription',
            component: 'group',
            fields: [
              {
                label: 'Main Label',
                name: 'label',
                component: 'text',
                defaultValue: '10% OFF WITH MONTHLY SUBSCRIPTION',
              },
              {
                label: 'Secondary Label',
                name: 'subLabel',
                component: 'text',
                defaultValue: '*SKIP OR CANCEL ANYTIME.',
              },
              {
                label: 'Subscription toggle',
                name: 'default',
                component: 'toggle',
                description:
                  'you can select the default subscription toggle state',
                toggleLabels: {
                  true: 'Checked',
                  false: 'Unchecked',
                },
                defaultValue: true,
              },
              {
                label: 'Supplementary information',
                name: 'additional',
                description: 'you can add additional information',
                component: 'group',
                fields: [
                  {
                    label: 'Hide / show additional information',
                    name: 'showSupplementary',
                    component: 'toggle',
                    description:
                      'you can show or hide the additional information',
                    toggleLabels: {
                      true: 'Show',
                      false: 'Hide',
                    },
                    defaultValue: true,
                  },
                  {
                    label: 'Additional information label',
                    name: 'label',
                    component: 'text',
                    defaultValue: 'WHY SUBSCRIBE?',
                  },
                  {
                    label: 'Text settings',
                    name: 'text',
                    description: 'Set heading and subheading',
                    component: 'group',
                    fields: [
                      {
                        label: 'title',
                        name: 'title',
                        component: 'markdown',
                        description:
                          'You can use markdown e.g. <u>**bold**</u> or # for heading',
                        defaultValue: 'WHY SUBSCRIBE?',
                      },
                      {
                        label: 'subtitle',
                        name: 'subtitle',
                        component: 'markdown',
                        description:
                          'You can use markdown e.g. <u>**bold**</u> or ## for subheading',
                        defaultValue:
                          'SAVE MONEY AND NEVER WORRY ABOUT RUNNING OUT OF COFFEE WITH FRESH BREW SHIPPED ON YOUR SCHEDULE.',
                      },
                    ],
                  },
                  {
                    label: 'Items',
                    name: 'items',
                    description: 'You can add title, description and icon',
                    component: 'group-list',
                    itemProps: {
                      label: '{{item.title}}',
                    },
                    validate(list) {
                      if (list.length > 4) {
                        window.__bpCMS.alerts.error(`Max of 4 tiles only.`);
                      }
                    },
                    defaultItem: {
                      title: 'SAVE 10% ON EVERY ORDER',
                      description:
                        'We’ll reward your commitment to caffeination with a 10% discount on every order.',
                      icon: icons.value.star,
                    },
                    fields: [
                      {
                        label: 'Title',
                        name: 'title',
                        component: 'text',
                      },
                      {
                        label: 'Description',
                        name: 'description',
                        component: 'textarea',
                      },
                      {
                        label: 'Icon',
                        name: 'icon',
                        component: 'select',
                        description: 'Select the icon',
                        options: icons.options,
                        defaultValue: icons.value.star,
                      },
                    ],
                    defaultValue: [
                      {
                        title: 'SAVE 10% ON EVERY ORDER',
                        description:
                          'We’ll reward your commitment to caffeination with a 10% discount on every order.',
                        icon: icons.value.star,
                      },
                    ],
                  },
                ],
                defaultValue: {
                  label: 'WHY SUBSCRIBE?',
                  showSupplementary: true,
                  text: {
                    title: '# WHY SUBSCRIBE?',
                    subtitle:
                      'SAVE MONEY AND NEVER WORRY ABOUT RUNNING OUT OF COFFEE WITH FRESH BREW SHIPPED ON YOUR SCHEDULE.',
                  },
                  items: [
                    {
                      title: 'SAVE 10% ON EVERY ORDER',
                      description:
                        'We’ll reward your commitment to caffeination with a 10% discount on every order.',
                      icon: icons.value.star,
                    },
                    {
                      title: 'DELIVERED ON YOUR SCHEDULE',
                      description:
                        'Total flexibility. Cancel, skip, reschedule or change your products anytime you want in your account.',
                      icon: icons.value.strong,
                    },
                    {
                      title: 'FAST, FRESH, AND NO COMMITMENTS',
                      description:
                        'Free and fast shipping, concierge customer service, and satisfaction guaranteed!',
                      icon: icons.value.leaves,
                    },
                  ],
                },
              },
            ],
            defaultValue: {
              label: '10% OFF WITH MONTHLY SUBSCRIPTION',
              subLabel: '*SKIP OR CANCEL ANYTIME.',
              default: true,
              additional: {
                label: 'WHY SUBSCRIBE?',
                showSupplementary: true,
                text: {
                  title: '# WHY SUBSCRIBE?',
                  subtitle:
                    'SAVE MONEY AND NEVER WORRY ABOUT RUNNING OUT OF COFFEE WITH FRESH BREW SHIPPED ON YOUR SCHEDULE.',
                },
              },
            },
          },
        ],
      },
      {
        ...section,
        defaultValue: {
          mobile: {
            container: 'container',
            mt: 'none',
            py: 's',
          },
          desktop: {
            container: 'contentMedium',
            mt: 'none',
            py: 's',
          },
        },
      },
    ],
  };
}
