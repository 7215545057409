import { Paragraph, Flex } from 'theme-ui';
import PropTypes from 'prop-types';
import { useCartClear } from '@backpackjs/storefront';

import { useSidebar } from '@hooks';
import { Link } from '@snippets';

import { themed } from './Ctas.theme';

export const Ctas = themed(
  ({ theme: themes, inSidebar = false, inputRef, ...props }) => {
    const { cartClear } = useCartClear();
    const [{}, { closeSidebar }] = useSidebar();

    const theme = inSidebar ? themes.sidebar : themes.page;

    return (
      <Flex data-comp={Ctas.displayName} sx={theme.ctas} ref={inputRef}>
        <Link
          href="/cart"
          onClick={() => {
            if (!inSidebar) return;
            closeSidebar();
          }}
          sx={theme.link}
        >
          View Cart
        </Link>

        <Paragraph
          onClick={async () => {
            await cartClear();
          }}
          sx={theme.link}
        >
          Clear Cart
        </Paragraph>
      </Flex>
    );
  }
);

Ctas.displayName = 'Ctas';
Ctas.propTypes = {
  inSidebar: PropTypes.bool,
};
