import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      width: '100%',
      justifyContent: ['center', 'flex-start'],
      alignItems: ['center', 'flex-start'],
      px: [4, '5rem'],
      pb: 0,
      py: [12, 12, 0],
    };

    this.list = {
      flexBasis: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: '2.5rem',
      px: ['0rem', 0],
      mx: ['15%', '25%', 0],
      listStyle: 'none',
    };

    this.listElement = {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.625rem',
      paddingLeft: '2.25rem',
      position: 'relative',
      '&:before ': {
        content:
          'url(//cdn.shopify.com/s/files/1/1418/5086/t/248/assets/list-icon-pdp.svg?v=3151532…)',
        width: '1.25rem',
        height: 'auto',
        position: 'absolute',
        left: 0,
        top: '0.625rem',
      },
    };

    this.title = {
      position: 'relative',
      fontSize: ['1.5rem', '32px'],
      lineHeight: '37.5px',
      fontWeight: 600,
      fontFamily: 'gtPressuraBold',
    };
  })()
);
