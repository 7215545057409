import { Heading, Grid, Box } from 'theme-ui';

import { SubNavLinks } from './SubNavLinks';
import { SubNavProductsRow } from './SubNavProductsRow';
import { SubNavProductsSwiper } from './SubNavProductsSwiper';
import { SubNavMedias } from './SubNavMedias';
import { themed } from './SubNav.theme';

export const SubNav = themed(
  ({
    theme,
    links = [],
    medias = [],
    products = [],
    layout = 'horizontal', // horizontal: desktop/menuDrawer, vertical: mobile/menuSidebar
    id,
    ...props
  }) => {
    const subItemsCount = [...links, ...products, ...medias].length;
    const isHorizontal = layout === 'horizontal';

    return !subItemsCount ? null : (
      <Grid
        data-comp={SubNav.displayName}
        {...props}
        sx={{
          ...props.sx,
          ...(isHorizontal ? theme.grid.horizontal : theme.grid.vertical),
        }}
      >
        <SubNavLinks links={links} />

        {products.length ? (
          <Box sx={theme.bestSeller}>
            <Heading as="h4" sx={theme.bestSeller.text}>
              BEST SELLERS
            </Heading>

            {isHorizontal ? (
              <SubNavProductsRow products={products} />
            ) : (
              <SubNavProductsSwiper products={products} />
            )}
          </Box>
        ) : null}

        {/* Render link medias */}
        {medias.length ? <SubNavMedias medias={medias} /> : null}
      </Grid>
    );
  }
);

SubNav.displayName = 'SubNav';
