import { Box } from 'theme-ui';

import { SubNavSidebar } from '../SubNavSidebar';
import { AccordionNavItem } from './AccordionNavItem';
import { useMenuSidebar } from '../MenuSidebar';
import { themed } from './AccordionNav.theme';

export const AccordionNav = themed(({ theme, items, ...props }) => {
  const [{ selectedItem }] = useMenuSidebar();

  // anim
  return (
    <Box
      data-comp={AccordionNav.displayName}
      {...props}
      sx={{
        ...props.sx,
        ...theme.nav,
        ...(selectedItem ? theme.nav.expanded : theme.nav.collapsed),
      }}
    >
      {/* Subitems drawer of currently selected item */}
      <SubNavSidebar selectedItem={selectedItem} />

      {/* Top-level view */}
      <Box
        data-comp={`${AccordionNav.displayName}Items`}
        as="nav"
        role="navigation"
      >
        <Box as="ul" sx={{ p: 0 }}>
          {items.map((item, index) => (
            <AccordionNavItem key={index} index={index} item={item} />
          ))}
        </Box>
      </Box>
    </Box>
  );
});

AccordionNav.displayName = 'AccordionNav';
