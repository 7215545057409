import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      my: [3, 4],
    };

    this.colorName = {
      fontFamily: 'text.heading',
    };
    this.selected = {
      cursor: 'pointer',
      borderRadius: '50%',
    };

    this.value = ({ isSelected }) => ({
      cursor: 'pointer',
      transform: 'translateY(-18%)',
      border: (t) =>
        isSelected
          ? ` 0.5px solid ${t.colors.text}`
          : `1px solid ${t.colors.gray}`,
      borderRadius: '50%',
      bg: 'background',
      color: 'text',
      mb: 4,
    });
  })()
);
