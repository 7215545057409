import { useCallback } from 'react';
import { Flex, Box, Text } from 'theme-ui';

import { Svg } from '@snippets';
import { useModal } from '@hooks';
import { colors } from '@theme/theme.colors';

import { themed } from './Subscribe.theme';
import { Supplementary } from './Supplementary';

export const Subscribe = themed(
  ({ theme, subscriptionDetails, sellingPlanId, handleSelect, isSelected, selectedVariant, selectedPlanDiscount }) => {
    const [{ modal }, { openModal, closeModal }] = useModal();
    const currentPlan = sellingPlanId;
    const toggleSizeGuideModal = useCallback(() => {
      if (modal) {
        closeModal();
        return;
      }

      openModal(<Supplementary supplementary={subscriptionDetails?.additional} />);
    }, [modal]);

    const totalBoxes = selectedVariant?.position;
    const discount = selectedPlanDiscount
    const showSubscriptionPrice =
      selectedVariant?.price

    return (
      <Flex data-comp={Subscribe.displayName} sx={theme.container}>
        <Text sx={{ fontSize: '18px', textTransform: 'uppercase', fontWeight: 700, mb: '12px' }}>{ subscriptionDetails?.title }</Text>
        <Box
          sx={{
            border: !isSelected ? `2px solid ${colors.black}` : `none`,
            bg: !isSelected ? colors.white : 'transparent',
            px: ['8px', '16px'],
            py: ['10px', '16px'],
            mb: '8px',
            cursor: 'pointer'
         }} onClick={() => handleSelect(false)}>
          <Flex
            sx={{ justifyContent: 'space-between' }}
          >
            <Flex sx={{ alignItems: 'center', columnGap: '8px' }}>
              {!isSelected ? (
                <Flex sx={{
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: colors.black,
                }}>
                  <Svg
                    alt="Swatch Color"
                    viewBox="0 0 48 48"
                    src='/svgs/checked.svg'
                    sx={{
                      width: '18',
                    }}
                  />
                </Flex>
              ):(
                <Box sx={{
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  border: `2px solid ${colors.grayTwo}`
                }}></Box>
              )}

              <Text
                sx={{ fontSize: ['15px', '16px'], fontWeight: 700 }}
              >
                {subscriptionDetails?.oneTimeLabel}
              </Text>
              <Text
                sx={{ fontSize: ['15px', '16px'], fontWeight: 700, color: colors.grayTwo }}
              >
                ({parseInt(selectedVariant?.title)} {parseInt(selectedVariant?.title) > 1 ? 'Boxes':'Box'})
              </Text>
            </Flex>
            <Box>
              <Text
                variant="text.h4"
                sx={{ fontSize: ['14px', '16px'], letterSpacing: '0.48px' }}
              >
                {`$${Number(
                  selectedVariant?.price
                ) * totalBoxes
                  } `}
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          sx={{
            border: isSelected ? `2px solid ${colors.black}` : `none`,
            bg: isSelected ? colors.white : 'transparent',
            px: ['8px', '16px'],
            py: ['10px', '16px'],
            cursor: 'pointer'
         }} onClick={() => handleSelect(true)}>
          <Flex sx={{
            justifyContent: 'space-between',
            alignItems: 'start'
          }}>
            <Flex sx={{ flexDirection: 'column', maxWidth: '250px' }}>
              <Flex sx={{ alignItems: 'center', columnGap: '8px' }}>
                {isSelected ? (
                    <Flex sx={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '50%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: colors.black,
                    }}>
                      <Svg
                        alt="Swatch Color"
                        viewBox="0 0 48 48"
                        src='/svgs/checked.svg'
                        sx={{
                          width: '18',
                        }}
                      />
                    </Flex>
                  ):(
                    <Box sx={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '50%',
                      border: `2px solid ${colors.grayTwo}`
                    }}></Box>
                  )}

                <Text
                  sx={{ fontSize: ['15px', '16px'], fontWeight: 700 }}
                >
                  {subscriptionDetails?.subscriptionLabel}
                </Text>
                <Text sx={{ fontSize: ['15px', '16px'], fontWeight: 700, color: colors.grayTwo }}>
                  ({parseInt(selectedVariant?.title)} {parseInt(selectedVariant?.title) > 1 ? 'Boxes':'Box'})
                </Text>
              </Flex>
              <Flex sx={{ flexDirection: 'column', ml: '32px' }}>
                <Text sx={{ fontSize: '14px', letterSpacing: '0.42px', fontWeight: 400, color: colors.grayTwo }}>{subscriptionDetails?.subscriptionDesc}</Text>
                {subscriptionDetails?.additional?.showSupplementary && (
                  <Text
                    sx={{
                      fontSize: '14px',
                      letterSpacing: '0.42px',
                      fontWeight: 400,
                      color: colors.grayTwo,
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    onClick={toggleSizeGuideModal}
                  >
                    {subscriptionDetails?.additional?.label}
                  </Text>
                )}
              </Flex>
            </Flex>
            <Flex sx={{ flexDirection: ['column', 'row'],  alignItems: 'center', columnGap: 3 }}>
              <Text variant="text.h4" sx={{ fontSize: ['14px', '16px'], color: colors.secondRed, letterSpacing: '0.48px' }}>
                {`$${Number((selectedVariant?.price) - discount).toFixed(2) * totalBoxes} `}
              </Text>
              <Text
                sx={{ textDecoration: 'line-through', fontSize: ['14px', '16px'], fontWeight: 400, color: colors.grayTwo, letterSpacing: '0.48px' }}
              >
                {`$${Number(
                  selectedVariant?.price
                ) * totalBoxes
                  } `}
              </Text>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    );
  }
);

Subscribe.displayName = 'Subscribe';
