import { colors } from '@theme/theme.colors';
import { Flex, Text } from 'theme-ui';

import { themed } from './Price.theme';

export const Price = themed(
  ({
    theme,
    selectedVariant,
    isSelected,
    subscribePrice,
    totalGlasses,
    subtitle,
  }) => {
    const showSubscriptionPrice =
      selectedVariant?.subscriptions?.discount_variant_price;
    const totalBoxes = selectedVariant?.position;
    const totalPrice =
      subscribePrice && showSubscriptionPrice
        ? Number(selectedVariant?.subscriptions?.discount_variant_price)
        : Number(selectedVariant?.price);

    return (
      <Flex
        data-comp={Price.displayName}
        sx={{ alignItems: 'center', flexDirection: 'column' }}
      >
        {totalGlasses ? (
          <Text sx={theme.subtitle}>{`${totalGlasses}glasses | $${(
            (totalPrice * totalBoxes) /
            totalGlasses
          ).toFixed(2)}/glass `}</Text>
        ) : (
          <Text sx={theme.subtitle}>{subtitle}</Text>
        )}
        {subscribePrice && showSubscriptionPrice ? (
          <>
            <Text
              variant="text.base"
              sx={{
                textDecoration: 'line-through',
                mr: 5,
                textDecorationColor: isSelected ? colors.white : colors.black,
              }}
            >
              ${Number(selectedVariant?.price) * totalBoxes}
            </Text>
            <Text
              sx={{
                fontFamily: 'gtPressuraBold',
              }}
            >
              {`$${totalPrice * totalBoxes} / ${totalBoxes} ${
                totalBoxes > 1 ? 'Boxes' : 'Box'
              }`}
            </Text>
          </>
        ) : (
          <Text
            variant="text.base"
            sx={{
              mr: 5,
              textDecorationColor: isSelected ? colors.white : colors.black,
            }}
          >
            ${totalPrice * totalBoxes}
          </Text>
        )}
      </Flex>
    );
  }
);

Price.displayName = 'Price';
