import { useMemo } from 'react';
import { useSettings } from '@backpackjs/storefront';

/**
  A utility to sort the siteSettings.header.menu data structure
  to match what's required UX wise by MenuDrawer and MenuSideDrawer
* */
export const useMenu = () => {
  const settings = useSettings();
  const { header } = settings;
  const menu = header?.menu || [];

  const refresh =
    menu?.length + menu?.map((link) => JSON.stringify(link)).join('-');

  return useMemo(() => {
    const output = {
      desktop: {
        menu: [],
      },
      mobile: {
        links: header.links,
        bestSellers: header.bestSellers,
        menu: {
          nested: [], // with sub links
          direct: [], // no sub links
        },
      },
    };

    if (!menu.length) return output;

    output.desktop.menu = menu;

    /**
      mobile menu: sort links with sub links and those that don't have
    * */
    output.mobile.menu = {
      nested: menu.filter((link) => link?.links?.length), // will be render as "accordions"
      direct: menu.filter((link) => !link?.links?.length), // rendered as direct links
    };

    return output;
  }, [refresh]);
};

/**
  settings.header.menu format:

  const menu = [
    {
      link: {
        text: 'SHOP ALL',
        url: '/collections/shop-all',
      },
      links: [
        { category: 'By Category', link: { text: 'Supplements', url: '/collections/shop-cbd-supplements/' } },
        { category: 'By Category', link: { text: 'Bodycare', url: '/collections/shop-cbd-bodycare/' } },
        { category: 'By Category', link: { text: 'Skincare', url: '/collections/shop-skincare/' } },
        { category: 'By Category', link: { text: 'Gifts & Bundles', url: '/collections/shop-collections/' } },
        { category: 'By Category', link: { text: 'Tools & Accessories', url: '/collections/shop-tools/' } },
        // needs redirects
        { category: 'By Category', link: { text: 'Subscribe & Save', url: '/pages/subscription-and-save/' } },
        { category: 'By Category', link: { text: 'Gift Card', url: '/products/rise-ai-giftcard/' } },
        //
        { category: 'By Benefit', link: { text: 'Stress', url: '/collections/shop-stress/' } },
        { category: 'By Benefit', link: { text: 'Sleep', url: '/collections/shop-sleep/' } },
        { category: 'By Benefit', link: { text: 'Recovery', url: '/collections/shop-recovery/' } },
        { category: 'By Benefit', link: { text: 'Mood', url: '/collections/shop-mood/' } },
        { category: 'By Benefit', link: { text: 'Skin Health', url: '/collections/shop-skin-health/' } },
      ],
      medias: [
        { src: '/images/shopall_01.jpg', link: { text: '50% Off CBD Powders', url: '/pages/faq' }},
        { src: '/images/shopall_02.jpg', link: { text: 'Better Rewards', url: '/pages/sustainability' }},
      ]
    },
    {
      link: {
        text: 'Supplements',
        url: '/collections/shop-cbd-supplements/',
      },
      links: [
        { category: '', link: { text: 'All Supplements', url: '/collections/shop-cbd-supplements/'} },
        { category: '', link: { text: 'Stress', url: '/collections/shop-stress/' } },
        { category: '', link: { text: 'Sleep', url: '/collections/shop-sleep/' } },
        { category: '', link: { text: 'Recovery', url: '/collections/shop-recovery/' } },
        { category: '', link: { text: 'Mood', url: '/collections/shop-mood/' } },
      ],
      medias: [
        { src: '/images/shopall_01.jpg', link: { ext: '50% Off CBD Powders', url: '/pages/faq' }},
        { src: '/images/supplements.jpg',link: { text: 'How To Take CBD: What to Know', url: '/pages/sustainability' }},
      ]
    },
    { link: { text: 'Bodycare', url: '/collections/shop-cbd-bodycare/' } },
    { link: { text: 'Skincare', url: '/collections/shop-skincare/' } },
    // should chunk right on desktop
    { link: { text: 'Subscribe & Save', url: '/pages/subscription-and-save/' } },
    { link: { text: 'Earn Cash Back', url: '/pages/rewards/' } },
    {
      link: {
        text: 'About',
        url: '/pages/about-us',
      },
      links: [
        { category: 'about', link: { text: 'About Us', url: '/pages/about-us'} },
        { category: 'about', link: { text: 'Our Values', url: '/pages/our-values'} },
        { category: 'about', link: { text: 'Open Letter', url: '/pages/open-letter'} },
        { category: 'about', link: { text: 'FAQ', url: '/pages/faq'} },
        { category: 'standards', link: { text: 'The Prima Way', url: '/pages/prima-way' }},
        { category: 'standards', link: { text: 'Science', url: '/pages/science' }},
        { category: 'standards', link: { text: 'Source', url: '/pages/source' }},
        { category: 'standards', link: { text: 'Sustainability', url: '/pages/sustainability' }},
        { category: 'standards', link: { text: '100% Clean', url: '/pages/100-clean' }},
        { category: 'standards', link: { text: 'Testing & Transparency', url: '/pages/batch' }},
        { category: 'beyond', link: { text: 'Betterment', url: '/pages/betterment' }},
        { category: 'beyond', link: { text: 'Giving', url: '/pages/giving' }},
      ],
      medias: [
        { src: '/images/about_01.jpg', link: { text: 'Frequently asked questions', url: '/pages/faq' }},
        { src: '/images/about_02.jpg', link: { text: 'Sustainability', url: '/pages/sustainability' }},
      ]
    },
  ]
 */

/**
  is converted to the following UI format by useMenu

{
  "desktopMenu": {
    "left": [
        {
            "link": {
                "text": "SHOP ALL",
                "url": "/collections/shop-all"
            },
            "links": [
                [
                    {
                        "category": "By Category",
                        "link": {
                            "text": "Supplements",
                            "url": "/collections/shop-cbd-supplements/"
                        }
                    },
                    {
                        "category": "By Category",
                        "link": {
                            "text": "Bodycare",
                            "url": "/collections/shop-cbd-bodycare/"
                        }
                    },
                    ...
                ],
                [
                    {
                        "category": "By Benefit",
                        "link": {
                            "text": "Stress",
                            "url": "/collections/shop-stress/"
                        }
                    },
                    {
                        "category": "By Benefit",
                        "link": {
                            "text": "Sleep",
                            "url": "/collections/shop-sleep/"
                        }
                    },
                    ....
                ],
                {
                    "src": "/images/shopall_01.jpg",
                    "link": {
                        "text": "50% Off CBD Powders",
                        "url": "/pages/faq"
                    }
                },
                {
                    "src": "/images/shopall_02.jpg",
                    "link": {
                        "text": "Better Rewards",
                        "url": "/pages/sustainability"
                    }
                }
            ],
            "medias": [
                {
                    "src": "/images/shopall_01.jpg",
                    "link": {
                        "text": "50% Off CBD Powders",
                        "url": "/pages/faq"
                    }
                },
                {
                    "src": "/images/shopall_02.jpg",
                    "link": {
                        "text": "Better Rewards",
                        "url": "/pages/sustainability"
                    }
                }
            ]
        },
        {
            "link": {
                "text": "Supplements",
                "url": "/collections/shop-cbd-supplements/"
            },
            "links": [
                [
                    {
                        "category": "",
                        "link": {
                            "text": "All Supplements",
                            "url": "/collections/shop-cbd-supplements/"
                        }
                    },
                    {
                        "category": "",
                        "link": {
                            "text": "Stress",
                            "url": "/collections/shop-stress/"
                        }
                    },
                    ...
                ],
                {
                    "src": "/images/shopall_01.jpg",
                    "link": {
                        "text": "50% Off CBD Powders",
                        "url": "/pages/faq"
                    }
                },
                {
                    "src": "/images/supplements.jpg",
                    "link": {
                        "text": "How To Take CBD: What to Know",
                        "url": "/pages/sustainability"
                    }
                }
            ],
            "medias": [
                {
                    "src": "/images/shopall_01.jpg",
                    "link": {
                        "text": "50% Off CBD Powders",
                        "url": "/pages/faq"
                    }
                },
                {
                    "src": "/images/supplements.jpg",
                    "link": {
                        "text": "How To Take CBD: What to Know",
                        "url": "/pages/sustainability"
                    }
                }
            ]
        },
        {
            "link": {
                "text": "Bodycare",
                "url": "/collections/shop-cbd-bodycare/"
            }
        },
        {
            "link": {
                "text": "Skincare",
                "url": "/collections/shop-skincare/"
            }
        }
    ],
    "right": [
        {
            "link": {
                "text": "Subscribe & Save",
                "url": "/pages/subscription-and-save/"
            }
        },
        {
            "link": {
                "text": "Earn Cash Back",
                "url": "/pages/rewards/"
            }
        },
        {
            "link": {
                "text": "About",
                "url": "/pages/about-us"
            },
            "links": [
                [
                    {
                        "category": "about",
                        "link": {
                            "text": "About Us",
                            "url": "/pages/about-us"
                        }
                    },
                    {
                        "category": "about",
                        "link": {
                            "text": "Our Values",
                            "url": "/pages/our-values"
                        }
                    },
                    ...
                ],
                [
                    {
                        "category": "standards",
                        "link": {
                            "text": "The Prima Way",
                            "url": "/pages/prima-way"
                        }
                    },
                    {
                        "category": "standards",
                        "link": {
                            "text": "Science",
                            "url": "/pages/science"
                        }
                    },
                    ...
                ],
                [
                    {
                        "category": "beyond",
                        "link": {
                            "text": "Betterment",
                            "url": "/pages/betterment"
                        }
                    },
                    {
                        "category": "beyond",
                        "link": {
                            "text": "Giving",
                            "url": "/pages/giving"
                        }
                    }
                ],
                {
                    "src": "/images/about_01.jpg",
                    "link": {
                        "text": "Frequently asked questions",
                        "url": "/pages/faq"
                    }
                },
                {
                    "src": "/images/about_02.jpg",
                    "link": {
                        "text": "Sustainability",
                        "url": "/pages/sustainability"
                    }
                }
            ],
            "medias": [
                {
                    "src": "/images/about_01.jpg",
                    "link": {
                        "text": "Frequently asked questions",
                        "url": "/pages/faq"
                    }
                },
                {
                    "src": "/images/about_02.jpg",
                    "link": {
                        "text": "Sustainability",
                        "url": "/pages/sustainability"
                    }
                }
            ]
        }
    ]
},
"mobileMenu": {
    "nested": [
        {
            "link": {
                "text": "SHOP ALL",
                "url": "/collections/shop-all"
            },
            "links": [
                [
                    {
                        "category": "By Category",
                        "link": {
                            "text": "Supplements",
                            "url": "/collections/shop-cbd-supplements/"
                        }
                    },
                    {
                        "category": "By Category",
                        "link": {
                            "text": "Bodycare",
                            "url": "/collections/shop-cbd-bodycare/"
                        }
                    },
                    ...
                ],
                [
                    {
                        "category": "By Benefit",
                        "link": {
                            "text": "Stress",
                            "url": "/collections/shop-stress/"
                        }
                    },
                    {
                        "category": "By Benefit",
                        "link": {
                            "text": "Sleep",
                            "url": "/collections/shop-sleep/"
                        }
                    },
                    ...
                ],
                {
                    "src": "/images/shopall_01.jpg",
                    "link": {
                        "text": "50% Off CBD Powders",
                        "url": "/pages/faq"
                    }
                },
                {
                    "src": "/images/shopall_02.jpg",
                    "link": {
                        "text": "Better Rewards",
                        "url": "/pages/sustainability"
                    }
                }
            ],
            "medias": [
                {
                    "src": "/images/shopall_01.jpg",
                    "link": {
                        "text": "50% Off CBD Powders",
                        "url": "/pages/faq"
                    }
                },
                {
                    "src": "/images/shopall_02.jpg",
                    "link": {
                        "text": "Better Rewards",
                        "url": "/pages/sustainability"
                    }
                }
            ]
        },
        {
            "link": {
                "text": "Supplements",
                "url": "/collections/shop-cbd-supplements/"
            },
            "links": [
                [
                    {
                        "category": "",
                        "link": {
                            "text": "All Supplements",
                            "url": "/collections/shop-cbd-supplements/"
                        }
                    },
                    {
                        "category": "",
                        "link": {
                            "text": "Stress",
                            "url": "/collections/shop-stress/"
                        }
                    },
                    ...
                ],
                {
                    "src": "/images/shopall_01.jpg",
                    "link": {
                        "text": "50% Off CBD Powders",
                        "url": "/pages/faq"
                    }
                },
                {
                    "src": "/images/supplements.jpg",
                    "link": {
                        "text": "How To Take CBD: What to Know",
                        "url": "/pages/sustainability"
                    }
                }
            ],
            "medias": [
                {
                    "src": "/images/shopall_01.jpg",
                    "link": {
                        "text": "50% Off CBD Powders",
                        "url": "/pages/faq"
                    }
                },
                {
                    "src": "/images/supplements.jpg",
                    "link": {
                        "text": "How To Take CBD: What to Know",
                        "url": "/pages/sustainability"
                    }
                }
            ]
        },
        {
            "link": {
                "text": "About",
                "url": "/pages/about-us"
            },
            "links": [
                [
                    {
                        "category": "about",
                        "link": {
                            "text": "About Us",
                            "url": "/pages/about-us"
                        }
                    },
                    {
                        "category": "about",
                        "link": {
                            "text": "Our Values",
                            "url": "/pages/our-values"
                        }
                    },
                    ...
                ],
                [
                    {
                        "category": "standards",
                        "link": {
                            "text": "The Prima Way",
                            "url": "/pages/prima-way"
                        }
                    },
                    {
                        "category": "standards",
                        "link": {
                            "text": "Science",
                            "url": "/pages/science"
                        }
                    },
                    ...
                ],
                [
                    {
                        "category": "beyond",
                        "link": {
                            "text": "Betterment",
                            "url": "/pages/betterment"
                        }
                    },
                    {
                        "category": "beyond",
                        "link": {
                            "text": "Giving",
                            "url": "/pages/giving"
                        }
                    }
                ],
                {
                    "src": "/images/about_01.jpg",
                    "link": {
                        "text": "Frequently asked questions",
                        "url": "/pages/faq"
                    }
                },
                {
                    "src": "/images/about_02.jpg",
                    "link": {
                        "text": "Sustainability",
                        "url": "/pages/sustainability"
                    }
                }
            ],
            "medias": [
                {
                    "src": "/images/about_01.jpg",
                    "link": {
                        "text": "Frequently asked questions",
                        "url": "/pages/faq"
                    }
                },
                {
                    "src": "/images/about_02.jpg",
                    "link": {
                        "text": "Sustainability",
                        "url": "/pages/sustainability"
                    }
                }
            ]
        }
    ],
    "direct": [
        {
            "link": {
                "text": "Bodycare",
                "url": "/collections/shop-cbd-bodycare/"
            }
        },
        {
            "link": {
                "text": "Skincare",
                "url": "/collections/shop-skincare/"
            }
        },
        {
            "link": {
                "text": "Subscribe & Save",
                "url": "/pages/subscription-and-save/"
            }
        },
        {
            "link": {
                "text": "Earn Cash Back",
                "url": "/pages/rewards/"
            }
        }
    ]
  }
}
*/
